import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { GET_SERVICES_INDEX } from "../../endpoints";
import { ServicesIndexResponseInterface } from "../../interfaces/ServicesIndexResponseInterface";

interface useRequestServicesIndexInterface<T>
  extends useRequestServiceImplementation<T> {
  Services: T | null;
  getServicesIndex: () => void;
}

export const useRequestServicesIndex = <
  T extends ServicesIndexResponseInterface
>(): useRequestServicesIndexInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getServicesIndex = useCallback(
    () =>
      sendGetRequest(GET_SERVICES_INDEX, (data) => setData(data), {
        bearer: getAccessToken() as string,
      }),
    [getAccessToken, sendGetRequest]
  );

  return {
    Services: Data,
    IsLoading,
    RequestError,
    getServicesIndex,
  };
};
