import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import CronLogsTable from "../../components/CronLogsTable/CronLogsTable";
import { useRequestCronLogsIndex } from "../../services/useRequestCronIndex/useRequestCronIndex";

const CronIndex = () => {
  const { CronLogs, RequestError, IsLoading, getCronLogsIndex } =
    useRequestCronLogsIndex();

  useMountEffect(() => getCronLogsIndex());

  return (
    <Page
      pageTitle="Cron Logs"
      hasError={RequestError}
      isLoadingContent={IsLoading}
      wide
    >
      {CronLogs && (
        <>
          <div className="p-2">
            <p className="text-sm font-medium">{CronLogs.length} logs</p>
          </div>
          <CronLogsTable logs={CronLogs} />
        </>
      )}
    </Page>
  );
};

export default CronIndex;
