export enum AmlCheckStatusEnum {
  TO_DO = "to-do",
  AWAIT_RESULT = "await-result",
  AWAIT_CLIENT = "await-client",
  ABORT_REQUESTED = "abort-requested",
  DECISION_REQUIRED = "decision-required",
  CONFIRM_CHECK = "confirm-check",
  ABORTED = "aborted",
  COMPLETE = "complete",
  DECLINED = "declined",
  NOT_APPLICABLE = "not-applicable",
  REVIEW = "review",
  ID_EXPIRED = "id-expired",
}

/** Thirdfort Specific Status */
export enum AmlCheckStatusEnumTFOnly {
  OPEN = "open",
  CLOSED = "closed",
  EXPIRED = "expired",
  UNOBTAINABLE = "unobtainable",
}

export type AMLStatuses = AmlCheckStatusEnum | AmlCheckStatusEnumTFOnly;

export const AmlCheckStatusString: { [key in AMLStatuses]: string } = {
  [AmlCheckStatusEnum.TO_DO]: "To do",
  [AmlCheckStatusEnum.AWAIT_RESULT]: "Await result",
  [AmlCheckStatusEnum.AWAIT_CLIENT]: "Await client",
  [AmlCheckStatusEnum.ABORT_REQUESTED]: "Abort requested",
  [AmlCheckStatusEnum.DECISION_REQUIRED]: "Decision required",
  [AmlCheckStatusEnum.CONFIRM_CHECK]: "Confirm check",
  [AmlCheckStatusEnum.ABORTED]: "Aborted",
  [AmlCheckStatusEnum.COMPLETE]: "Complete",
  [AmlCheckStatusEnum.DECLINED]: "Declined",
  [AmlCheckStatusEnum.NOT_APPLICABLE]: "Not applicable",
  [AmlCheckStatusEnum.REVIEW]: "Review",
  [AmlCheckStatusEnum.ID_EXPIRED]: "Id expired",

  [AmlCheckStatusEnumTFOnly.OPEN]: "Open",
  [AmlCheckStatusEnumTFOnly.CLOSED]: "Closed",
  [AmlCheckStatusEnumTFOnly.EXPIRED]: "Expired",
  [AmlCheckStatusEnumTFOnly.UNOBTAINABLE]: "Unobtainable",
};

export const AmlCheckStatusBg: { [key in AMLStatuses]: string } = {
  [AmlCheckStatusEnum.TO_DO]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.AWAIT_RESULT]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.AWAIT_CLIENT]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.ABORT_REQUESTED]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.DECISION_REQUIRED]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.CONFIRM_CHECK]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.ABORTED]: "bg-red-500 text-white",
  [AmlCheckStatusEnum.COMPLETE]: "bg-green-500 text-white",
  [AmlCheckStatusEnum.DECLINED]: "bg-red-500 text-white",
  [AmlCheckStatusEnum.NOT_APPLICABLE]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.REVIEW]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnum.ID_EXPIRED]: "bg-red-500 text-white",

  /** Thirdfort Specific Status */
  [AmlCheckStatusEnumTFOnly.OPEN]: "bg-yellow-500 text-black",
  [AmlCheckStatusEnumTFOnly.CLOSED]: "bg-green-500 text-white",
  [AmlCheckStatusEnumTFOnly.EXPIRED]: "bg-red-500 text-white",
  [AmlCheckStatusEnumTFOnly.UNOBTAINABLE]: "bg-red-500 text-white",
};
