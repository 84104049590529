type IVAResult = "clear" | "consider";

interface IVADetail {
  readonly result: IVAResult;
}

export interface IVisualAuthenticity {
  readonly digital_tampering: IVADetail;
  readonly face_detection: IVADetail;
  readonly fonts: IVADetail;
  readonly original_document_present: IVADetail;
  readonly other: IVADetail;
  readonly picture_face_integrity: IVADetail;
  readonly security_features: IVADetail;
  readonly template: IVADetail;
}

export const VAString: { [key in keyof IVisualAuthenticity]: string } = {
  digital_tampering: "Digital Tampering",
  face_detection: "Face detection",
  fonts: "Fonts",
  original_document_present: "Original Document Present",
  other: "Other",
  picture_face_integrity: "Picture Face Integrity",
  security_features: "Security Features",
  template: "Template",
};
