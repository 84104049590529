import { useContext } from "react";
import FreeTypeDetails from "./Details/FreeTypeDetails";
import OptionableDetails from "./Details/OptionableDetails";
import TextAreaDetails from "./Details/TextAreaDetails";
import { AMLAnswerOptionInterface } from "../../../interfaces/AMLAnswerOptionInterface";
import { AnswerOptionTypesContext } from "../../EditQuestionModal/EditQuestionModal";

type AnswerOptionDetailsProps = {
  answerOption: AMLAnswerOptionInterface;
};

const AnswerOptionDetails = (props: AnswerOptionDetailsProps) => {
  const { answerOptionTypes } = useContext(AnswerOptionTypesContext);

  const currentAnswerOptionType = answerOptionTypes.find(
    (answerOptionType) => answerOptionType.id === props.answerOption.answer_option_type_id,
  );

  if (!currentAnswerOptionType) {
    return <p className="text-center text-sm my-2 font-medium">No Answer Option yet defined.</p>;
  }

  switch (currentAnswerOptionType.key) {
    case "freetype": {
      return <FreeTypeDetails answerOption={props.answerOption} />;
    }
    case "radio": {
      return <OptionableDetails answerOption={props.answerOption} content={"Selected Answer Type: Radio"} />;
    }
    case "checkbox": {
      return <OptionableDetails answerOption={props.answerOption} content={"Selected Answer Type: Checkbox"} />;
    }
    case "multiple-preset": {
      return <OptionableDetails answerOption={props.answerOption} content={"Selected Answer Type: Multiple Preset"} />;
    }
    case "single-preset": {
      return <OptionableDetails answerOption={props.answerOption} content={"Selected Answer Type: Single Preset"} />;
    }
    case "textarea": {
      return <TextAreaDetails />;
    }
    default: {
      return <p className="text-center text-sm my-2 font-medium">No Answer Option yet defined.</p>;
    }
  }
};

export default AnswerOptionDetails;
