import { ChevronLeftIcon } from "@heroicons/react/outline";
import { AxiosError } from "axios";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { LinkWithSearchQuery } from "../LinkWithSearchQuery/LinkWithSearchQuery";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import NegativeMessage from "../Message/NegativeMessage/NegativeMessage";

type PageProps = {
  wide?: boolean;
  isLoadingContent?: boolean;
  hasError?: AxiosError | null;
  pageTitle: string | React.ReactNode;
  children?: React.ReactNode;
  breadcrumbs?: Array<{
    url: string;
    content: string;
    preserveQuery?: boolean;
  }>;
};

const Page = (props: PageProps) => {
  const { Theme } = useTheme();

  const Breadcrumbs = props.breadcrumbs
    ? props.breadcrumbs.map((breadcrumb) =>
        breadcrumb["preserveQuery"] ? (
          <LinkWithSearchQuery key={breadcrumb.url} to={breadcrumb.url} className={Theme["breadcrumb:link"]}>
            <ChevronLeftIcon className={Theme["icon"]} />
            <span className="ml-1 align-text-top">{breadcrumb.content}</span>
          </LinkWithSearchQuery>
        ) : (
          <Link key={breadcrumb.url} to={breadcrumb.url} className={Theme["breadcrumb:link"]}>
            <ChevronLeftIcon className={Theme["icon"]} />
            <span className="ml-1 align-text-top">{breadcrumb.content}</span>
          </Link>
        ),
      )
    : null;

  return (
    <div className={twMerge(Theme["page"], props.wide ? "max-w-5xl" : "max-w-3xl")}>
      {props.isLoadingContent && <LoadingOverlay />}
      {props.hasError && <NegativeMessage title="Error" content={props.hasError.message} />}
      {props.breadcrumbs && <div className="mb-2">{Breadcrumbs}</div>}
      <h1 className={Theme["page:title"]}>{props.pageTitle}</h1>
      <div>{props.children && props.children}</div>
    </div>
  );
};

export default Page;
