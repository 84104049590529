import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface TableDataProps extends React.ComponentPropsWithoutRef<"td"> {
  className?: string;
  children?: React.ReactNode;
}

const TableData = (props: TableDataProps) => {
  const { Theme } = useTheme();

  return (
    <td {...props} className={twMerge(Theme["table:data"], props.className)}>
      {props.children && props.children}
    </td>
  );
};

export default TableData;
