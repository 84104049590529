import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

type CodeBlockProps = {
  children?: string | React.ReactNode;
  restrictHeight?: boolean;
};

const CodeBlock = (props: CodeBlockProps) => {
  const { Theme } = useTheme();

  return (
    <div
      className={twMerge(
        Theme["codeblock:container"],
        props.restrictHeight ? "max-h-96" : ""
      )}
    >
      <pre className={Theme["codeblock:pre"]}>
        {props.children && props.children}
      </pre>
    </div>
  );
};

export default CodeBlock;
