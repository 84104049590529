import { AxiosError } from "axios";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLTenantsEndpoints } from "../../endpoints";
import { IGetAMLTenantsIndexParams } from "../../interfaces/IGetAMLTenantsIndexParams";
import { ITenants } from "../../interfaces/ITenant";

export interface IPaginationMeta {
  readonly total: number;
  readonly last_page: number;
  readonly current_page: number;
}

interface ITenantsResponse {
  readonly data: ITenants;
  readonly meta: IPaginationMeta;
}

interface IUseRequestTenantsIndex {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly getTenantsIndex: (
    config: IGetAMLTenantsIndexParams,
    cb: (data: ITenants, meta: IPaginationMeta) => void,
  ) => void;
}

export const useRequestTenantsIndex = (): IUseRequestTenantsIndex => {
  const { IsLoading, RequestError, sendGetRequest } = useRequestAMLService<ITenantsResponse>();

  const getTenantsIndex = useCallback(
    (config: IGetAMLTenantsIndexParams, cb: (data: ITenants, meta: IPaginationMeta) => void) =>
      sendGetRequest(
        generateCompleteURL(
          AMLTenantsEndpoints.index.get,
          {},
          {
            page: config.page ?? 1,
            per_page: config.per_page ?? 25,
            q: config.q ?? "",
          },
        ),
        (data) => cb(data.data, data.meta),
      ),
    [sendGetRequest],
  );

  return {
    IsLoading,
    RequestError,
    getTenantsIndex,
  };
};
