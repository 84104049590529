import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { ServiceLogsIndexInterface } from "../../interfaces/ServiceLogsIndexInterface";
import ServiceLogsTableRow from "../ServiceLogsTableRow/ServiceLogsTableRow";

type ServiceLogsTableProps = {
  logs: Array<ServiceLogsIndexInterface>;
};

const ServiceLogsTable = (props: ServiceLogsTableProps) => (
  <Table>
    <thead>
      <TableRow>
        <TableHeading className="w-24">Level</TableHeading>
        <TableHeading>Message</TableHeading>
        <TableHeading className="w-56 text-center">Occured At</TableHeading>
        <TableHeading className="w-24"></TableHeading>
      </TableRow>
    </thead>
    <tbody>
      {props.logs.map((log) => (
        <ServiceLogsTableRow log={log} key={log.id} />
      ))}
    </tbody>
  </Table>
);

export default ServiceLogsTable;
