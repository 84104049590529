import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { LOGS_SERVICE_INDEX } from "modules/Logs/Services/routes";
import { useParams } from "react-router-dom";
import ServiceLogContent from "../../components/ServiceLogContent/ServiceLogContent";
import ServiceLogDetailTable from "../../components/ServiceLogDetailTable/ServiceLogDetailTable";
import { useRequestServiceLogShow } from "../../services/useRequestServiceLogShow/useRequestServiceLogShow";

const ServiceLogShow = () => {
  const { ServiceLog, RequestError, IsLoading, getServiceLogShow } =
    useRequestServiceLogShow();

  const { id: service, logId } = useParams<{
    id: string;
    logId: string;
  }>();

  useMountEffect(() =>
    getServiceLogShow({
      id: logId as string,
    })
  );

  return (
    <Page
      pageTitle="Service log"
      isLoadingContent={IsLoading}
      hasError={RequestError}
      wide
      breadcrumbs={[
        {
          url: LOGS_SERVICE_INDEX.replace(/:id/, service as string),
          content: `Back to All ${(service as string).replace(/-/g, " ")}`,
        },
      ]}
    >
      {ServiceLog && (
        <>
          <ServiceLogDetailTable
            logId={logId as string}
            createdAt={ServiceLog.created_at}
            levelName={ServiceLog.level_name}
            level={ServiceLog.level}
            channel={ServiceLog.channel}
          />
          {ServiceLog.message && (
            <ServiceLogContent title="Log Message">
              {ServiceLog.message}
            </ServiceLogContent>
          )}
          {ServiceLog.context && (
            <ServiceLogContent title="Log Context">
              {ServiceLog.context}
            </ServiceLogContent>
          )}
          {ServiceLog.extra && (
            <ServiceLogContent title="Log Extra">
              {ServiceLog.extra}
            </ServiceLogContent>
          )}
        </>
      )}
    </Page>
  );
};

export default ServiceLogShow;
