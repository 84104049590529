import { NavigationTree } from "modules/NavigationTree";
import AppNavigationBranch from "../AppNavigationBranch/AppNavigationBranch";

type AppNavigationTreeProps = {
  condensed?: boolean;
  toggleCondense: () => void;
};

const AppNavigationTree = (props: AppNavigationTreeProps) => {
  /**
   * * Prepare the nav tree
   */
  const tree: ReadonlyArray<React.ReactNode> = Object.values(NavigationTree).map((branch) => {
    return (
      <AppNavigationBranch
        key={branch.name}
        condensed={props.condensed}
        navigationItem={branch}
        toggleCondense={props.toggleCondense}
      />
    );
  });

  return <div className="p-4 overflow-y-auto h-[calc(100vh-40px)]">{tree}</div>;
};

export default AppNavigationTree;
