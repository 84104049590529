import {AxiosError} from "axios";
import {useRequestAMLService} from "modules/Projects/shared/services/useRequestAMLService";
import {useCallback} from "react";
import {generateCompleteURL} from "utilities/generateCompleteURL/generateCompleteURL";
import {AMLTenantsAssessmentGroupsEndpoints} from "../endpoints";
import {IAssessmentGroups} from "../interfaces/IAssessmentGroup";

interface IUseRequestAssessmentGroups {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly getAssessmentGroups: (tenant_id: string, cb: (assessmentGroups: IAssessmentGroups) => void) => void;
  readonly getAssessmentGroupsIndex: (tenant_id: string, cb: (options: IAssessmentGroups) => void) => void;
}


export const useRequestAssessmentGroups = (): IUseRequestAssessmentGroups => {
  const {IsLoading, RequestError, sendGetRequest} = useRequestAMLService();

  const getAssessmentGroups = useCallback(
    (tenant_id: string, cb: (assessmentGroups: IAssessmentGroups) => void) =>
      sendGetRequest(generateCompleteURL(AMLTenantsAssessmentGroupsEndpoints.index.get, {}, {withtrashed: "true"}), (data) => cb(data as IAssessmentGroups), {
        headers: {
          "tenant-id": tenant_id,
        },
      }),
    [sendGetRequest],
  );

  const getAssessmentGroupsIndex = useCallback(
    (tenant_id: string, cb: (assessmentGroups: IAssessmentGroups) => void) =>
      sendGetRequest(
        generateCompleteURL(
          AMLTenantsAssessmentGroupsEndpoints.index.get,
          {},
          {},
        ),
        (data) => cb(data as IAssessmentGroups),
        {
          headers: {
            "tenant-id": tenant_id,
          }
        }
      ),
    [sendGetRequest]
  )

  return {
    IsLoading,
    RequestError,
    getAssessmentGroups,
    getAssessmentGroupsIndex
  };
};
