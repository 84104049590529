import Layout from "components/UI/Layout/Layout";
import LoadingOverlay from "components/UI/LoadingOverlay/LoadingOverlay";
import RouteNotFound from "containers/RouteNotFound/RouteNotFound";
import { ModulesRouter } from "modules/ModulesRouter";
import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { ROOT } from "routes/routes";

const Router = () => (
  <Suspense fallback={<LoadingOverlay />}>
    <Routes>
      {/* * All routes use the Layout component */}
      <Route path={ROOT} element={<Layout />}>
        {/* <Route index element={<ModulesRouter />} /> */}
        {ModulesRouter}

        {/* * Not found routes */}
        <Route path="*" element={<RouteNotFound />} />
      </Route>
    </Routes>
  </Suspense>
);

export default Router;
