import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLChecksEndpoints } from "../../endpoints";
import { IVisualAuthenticity } from "../../interfaces/IVisualAuthenticity";

export const useRequestVisualAuthenticity = () => {
  const { IsLoading, RequestError, sendGetRequest } = useRequestAMLService<IVisualAuthenticity>();

  const getVisualAuthenticity = useCallback(
    (checkId: string, cb: (vaDetails: IVisualAuthenticity) => void) =>
      sendGetRequest(
        generateCompleteURL(
          AMLChecksEndpoints.visualAuthenticity.get,
          {
            check_id: checkId,
          },
          {},
        ),
        (data) => cb(data),
      ),
    [sendGetRequest],
  );

  return {
    IsLoading,
    RequestError,
    getVisualAuthenticity,
  };
};
