import Page from "components/UI/Page/Page";
import {useMountEffect} from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import {useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import {generateCompleteURL} from "utilities/generateCompleteURL/generateCompleteURL";
import Loader from "../../../../../components/UI/Loader/Loader";
import {IAssessmentGroups} from "../../AssessmentGroups/interfaces/IAssessmentGroup";
import {useRequestAssessmentGroups} from "../../AssessmentGroups/services/useRequestAssessmentGroups";
import {RiskAssessmentRoutes} from "../../routes";
import {Assessment} from "../components/Assessment";
import {IAssessment, IAssessments} from "../interfaces/IAssessment";
import {useRequestAssessments} from "../services/useRequestAssessments";
import {useUpdateAssessmentService} from "../services/useUpdateAssessmentService";

export const Assessments = () => {
  const [assessments, setAssessments] = useState<IAssessments>([]);

  const {getAssessments, RequestError, IsLoading} = useRequestAssessments();

  const {getAMLEndpointTitle, clearAMLEndpoint} = useAMLEndpoint();

  const [assessmentGroups, setAssessmentGroups] = useState<IAssessmentGroups>([])

  const {getAssessmentGroupsIndex} = useRequestAssessmentGroups();

  const {restoreAssessment, UpdateError, IsUpdating} = useUpdateAssessmentService();

  const restore = (assessment: IAssessment) => {
    restoreAssessment(assessment.id, (restoredAssessment) => {
      const newAssessments = [...assessments];
      const idx = newAssessments.findIndex(i => i.id === assessment.id);
      if (idx > -1) {
        newAssessments[idx] = {
          ...newAssessments[idx],
          deleted_at: restoredAssessment.deleted_at
        }
      }
      setAssessments(newAssessments);
    })
  }

  useMountEffect(
    () => tenant_id && getAssessmentGroupsIndex(tenant_id, (data) => setAssessmentGroups(data))
  );

  const {tenant_id, assessment_group_id} = useParams<{
    tenant_id: string,
    assessment_group_id: string,
  }>();

  const [searchParams] = useSearchParams();
  const trashed = searchParams.get("trashed") as "true" | "false"

  useMountEffect(
    () => tenant_id && assessment_group_id && trashed && getAssessments(tenant_id, assessment_group_id, trashed, (data) => setAssessments(data))
  );

  return (
    <Page pageTitle={
      <span>
        Assessments{" "}
        <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
          ({getAMLEndpointTitle()})
        </span>
      </span>
    }
          isLoadingContent={IsLoading}
          hasError={RequestError}
          breadcrumbs={[
            {
              content: "View all tenants",
              url: generateCompleteURL(RiskAssessmentRoutes.tenants.index, {}, {}),
              preserveQuery: true,
            },
            {
              content: "Assessment Groups",
              url: generateCompleteURL(
                RiskAssessmentRoutes.assessment_groups.index,
                {
                  tenant_id: tenant_id as string
                },
                {}
              ),
              preserveQuery: false
            }
          ]}
    >
      <div className={`ml-5`}>
        {IsUpdating ? <span className="inline-block w-5 h-5">
          <Loader/>
        </span> :
          assessments.map((assessment) => (
              <Assessment
                handleRestore={() => restore(assessment)}
                assessment={assessment} key={assessment.id}
                assessmentGroups={assessmentGroups}/>
            )
          )}</div>
      {assessments.length === 0 && (
        <>
          <h2>Nothing to see here</h2>
        </>
      )}
    </Page>
  )
}
