import { DatabaseIcon } from "@heroicons/react/outline";
import { NavigationItemType } from "modules/NavigationTree";
import { CRON, CRON_INDEX } from "./routes";

export const CronNavigationTree: Array<NavigationItemType> = [
  {
    name: "Cron Jobs",
    urlPath: CRON,
    icon: <DatabaseIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [
      {
        name: "View All",
        urlPath: CRON_INDEX,
        isLink: true,
      },
    ],
  },
];
