import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Loader from "components/UI/Loader/Loader";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useEffect, useState } from "react";
import { AMLAnswerOptionInterface } from "../../../interfaces/AMLAnswerOptionInterface";
import { useRequestAMLAnswerOptions } from "../../../services/useRequestAnswerOption/useRequestAnswerOption";
import AnswerOptionDetails from "../AnswerOptionDetails/AnswerOptionDetails";
import AnswerOptionEdit from "../AnswerOptionEdit/AnswerOptionEdit";

type AnswerOptionItemProps = {
  answer_option_id: string | null;
  handleOnAnswerOptionCreate: (answer_option_id: string) => void;
};

const AnswerOptionItem = (props: AnswerOptionItemProps) => {
  const { Theme } = useTheme();

  const [ShowAnswerConfigurationSection, setShowAnswerConfigurationSection] = useState<boolean>(false);

  useMountEffect(() => {
    if (props.answer_option_id) {
      getAnswerOptionsShow(props.answer_option_id);
    }
  });

  const { AnswerOptions, IsLoading, RequestError, getAnswerOptionsShow, postAnswerOption, patchAnswerOption } =
    useRequestAMLAnswerOptions();

  const handleCreate = (answerOption: AMLAnswerOptionInterface) => {
    postAnswerOption({
      answer_option_type_id: answerOption.answer_option_type_id,
      default_answer: answerOption.default_answer,
      options: answerOption.options,
      rules: answerOption.rules,
    });
    setShowAnswerConfigurationSection(false);
  };

  useEffect(() => {
    if (!props.answer_option_id && AnswerOptions.length) {
      props.handleOnAnswerOptionCreate(AnswerOptions[0].id);
    }
  }, [AnswerOptions, props]);

  const handleEdit = (answerOption: AMLAnswerOptionInterface) => {
    patchAnswerOption(answerOption.id, {
      answer_option_type_id: answerOption.answer_option_type_id,
      default_answer: answerOption.default_answer,
      options: answerOption.options,
      rules: answerOption.rules,
    });
    setShowAnswerConfigurationSection(false);
  };

  return (
    <div>
      {IsLoading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center p-10">
          <div className={`${Theme["bg-primary"]} opacity-50 absolute top-0 bottom-0 left-0 right-0`}></div>
          <span className="inline-block w-6 h-6">
            <Loader />
          </span>
        </div>
      ) : null}
      {RequestError && <p className="text-center text-base mb-2">{RequestError.message}</p>}
      {AnswerOptions.length > 0 && <AnswerOptionDetails answerOption={AnswerOptions[0]} />}
      <div className="flex justify-center items-center">
        <PrimaryButton onClick={() => setShowAnswerConfigurationSection(!ShowAnswerConfigurationSection)}>
          {ShowAnswerConfigurationSection ? "Close" : "Configure Answer"}
        </PrimaryButton>
      </div>
      {ShowAnswerConfigurationSection && (
        <AnswerOptionEdit
          answerOption={AnswerOptions.length > 0 ? AnswerOptions[0] : null}
          handleCreate={handleCreate}
          handleEdit={handleEdit}
        />
      )}
    </div>
  );
};

export default AnswerOptionItem;
