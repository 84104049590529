import CautionMessage from "components/UI/Message/CautionMessage/CautionMessage";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { AMLCheckRoutes } from "modules/Projects/AMLChecks/routes";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { AssessmentGroupsTable } from "../../components/AssessmentGroupsTable/AssessmentGroupsTable";
import { IAssessmentGroups } from "../../interfaces/IAssessmentGroup";
import { useRequestAssessmentGroups } from "../../services/useRequestAssessmentGroups";

export const AssessmentGroupsIndex = () => {
  const [assessmentGroups, setAssessmentGroups] = useState<IAssessmentGroups>([]);

  const { getAssessmentGroups, RequestError, IsLoading } = useRequestAssessmentGroups();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  const { tenant_id } = useParams<{ tenant_id: string }>();

  useMountEffect(() => tenant_id && getAssessmentGroups(tenant_id, (data) => setAssessmentGroups(data)));

  return (
    <Page
      pageTitle={
        <span>
          Assessment Groups{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
      breadcrumbs={[
        {
          content: "Return to all tenants",
          url: AMLCheckRoutes.tenants.index,
          preserveQuery: true,
        },
      ]}
    >
      {assessmentGroups.length > 0 ? (
        <AssessmentGroupsTable assessmentGroups={assessmentGroups} />
      ) : (
        !IsLoading && <CautionMessage content="No Assessment Groups with AML Checks have been found for this tenant" />
      )}
    </Page>
  );
};
