import { produce } from "immer";
import { Draft } from "immer/dist/internal";
import { AnswerOptionRuleSetInterface } from "../../../interfaces/AMLAnswerOptionInterface";

export const createEmptyRuleset = (): AnswerOptionRuleSetInterface => ({
  leads_to_enhanced: [],
  level_2: {},
  display_order: {},
});

/**
 * Duplicate the provided ruleset or return an empty rule if null
 * @param {AnswerOptionRuleSetInterface | null} rules
 * @returns {AnswerOptionRuleSetInterface}
 */
export const duplicateRuleset = (rules: AnswerOptionRuleSetInterface | null): AnswerOptionRuleSetInterface =>
  rules && !Array.isArray(rules)
    ? (JSON.parse(JSON.stringify(rules)) as AnswerOptionRuleSetInterface)
    : createEmptyRuleset();

/**
 * Update the provided ruleset with an updater fn, or receive an empty ruleset if null
 * @param {AnswerOptionRuleSetInterface | null} rules *
 * @param {(draft: Draft<AnswerOptionRuleSetInterface>) => AnswerOptionRuleSetInterface} updaterFn
 * @returns {AnswerOptionRuleSetInterface}
 */
export const updateRuleset = (
  rules: AnswerOptionRuleSetInterface | null,
  updaterFn: (draft: Draft<AnswerOptionRuleSetInterface>) => void,
): AnswerOptionRuleSetInterface => (rules ? produce(rules, updaterFn) : createEmptyRuleset());

/**
 * Remove a given key from the ruleset
 * @param {AnswerOptionRuleSetInterface | null} rules
 * @param {string} key
 * @returns {AnswerOptionRuleSetInterface}
 */
export const removeKeyFromRuleset = (
  rules: AnswerOptionRuleSetInterface | null,
  id: string,
): AnswerOptionRuleSetInterface =>
  updateRuleset(rules, (draft) => {
    draft.leads_to_enhanced = draft.leads_to_enhanced.filter((o) => o !== id);
    // * remove from level 2
    if (draft.level_2 && id in draft.level_2) {
      const { level_2 } = draft;
      delete level_2[id];
    }
    // * Remove from display order
    if (draft.display_order && id in draft.display_order) {
      const { display_order } = draft;
      delete display_order[id];
    }
  });

/**
 * Some rulesets will have nullified values - new updates to the API requires all future
 * rulesets not to have nullified values. This removes them
 * @param {AnswerOptionRuleSetInterface | null} rules
 * @returns {AnswerOptionRuleSetInterface}
 */
export const setDefaultRulesetValues = (rules: AnswerOptionRuleSetInterface | null): AnswerOptionRuleSetInterface =>
rules && !Array.isArray(rules)
    ? produce(rules, (draft) => {
        if (!("level_2" in draft) || !draft.level_2) {
          draft.level_2 = {};
        } else if (Array.isArray(draft.level_2) && draft.level_2.length === 0) {
          draft.level_2 = {};
        }

        if (!("display_order" in draft) || !draft.display_order) {
          draft.display_order = {};
        } else if (Array.isArray(draft.display_order) && draft.display_order.length === 0) {
          draft.display_order = {};
        }
      })
    : createEmptyRuleset();
