import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import Modal from "components/UI/Modal/Modal";
import { Formik } from "formik";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { AMLAssessmentTypeInterface } from "../../interfaces/AMLAssessmentTypeInterface";

type EditAssessmentTypeModalProps = {
  assessmentType: AMLAssessmentTypeInterface;
  onSubmit: (values: { id: string; name: string; description: string }) => void;
  onClose: () => void;
};

const EditAssessmentTypeModal = (props: EditAssessmentTypeModalProps) => {
  const { Theme } = useTheme();

  const { getAMLEndpointTitle } = useAMLEndpoint();

  const onSubmit = (values: { name: string; description: string }) => {
    props.onSubmit({
      id: props.assessmentType.id,
      ...values,
    });
  };

  return (
    <Modal onClose={props.onClose}>
      <Formik
        initialValues={{
          name: props.assessmentType.name,
          description: props.assessmentType.description,
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className={`${Theme["bg-tertiary"]} px-4 py-2`}>
              <h2 className="font-medium text-lg">
                Edit Assessment Type: {props.assessmentType.name} ({getAMLEndpointTitle()})
              </h2>
            </div>
            <div className={`p-4`}>
              <div>
                <FieldSet error={formik.errors.name} label="Name" name="name" placeholder="Assessment Type name..." />
              </div>
              <div className="mt-4">
                <FieldSet
                  error={formik.errors.description}
                  label="Description"
                  name="description"
                  placeholder="Assessment Type description..."
                />
              </div>
            </div>
            <div className={`${Theme["bg-tertiary"]} p-4 flex items-center justify-between`}>
              <TertiaryButton type="button" onClick={() => props.onClose()}>
                Cancel
              </TertiaryButton>
              <PrimaryButton type="submit">Update</PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditAssessmentTypeModal;
