import {IOptions} from "../../../../../../components/UI/Inputs/SelectField";
import NegativeMessage from "../../../../../../components/UI/Message/NegativeMessage/NegativeMessage";
import TableData from "../../../../../../components/UI/Table/TableData/TableData";
import TableRow from "../../../../../../components/UI/Table/TableRow/TableRow";
import {EditableSelectField} from "../../../../AMLChecks/Checks/components/EditableCheckFields/EditableSelectField";
import {useUpdateAssessmentService} from "../../services/useUpdateAssessmentService";

interface IProps {
  readonly content?: React.ReactNode;
  readonly value: string;
  readonly name: string;
  readonly keyName: string;
  readonly assessmentId: string;
  readonly options: IOptions;
  readonly handleOnUpdate: (value: string) => void;
}

export const EditableSelectAssessmentRow = ({ content, value, keyName, name, assessmentId, options, handleOnUpdate }: IProps) => {
  const { IsUpdating, UpdateError, updateAssessment } = useUpdateAssessmentService();
  const onUpdate = (newValue: string) => {
    updateAssessment(
      {
        assessmentId,
        keyName,
        value: newValue
      },
      () => {
        handleOnUpdate(newValue)
      }
    );
  }

  return (
    <TableRow>
      <TableData>{name}:</TableData>
      <TableData>
        <EditableSelectField
          content={content}
          value={value}
          onConfirm={onUpdate}
          isLoading={IsUpdating}
          options={options}
        />
        {UpdateError && (
          <div className="mt-2">
            <NegativeMessage content={UpdateError.message} />
          </div>
        )}
      </TableData>
    </TableRow>
  );
}