import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLChecksEndpoints } from "../../endpoints";

interface IUpdateCheckConfig {
  readonly checkId: string;
  readonly keyName: string;
  readonly value: string;
}

export const useUpdateCheckService = () => {
  const { IsLoading, RequestError, sendPatchRequest } = useRequestAMLService();

  const updateCheck = (config: IUpdateCheckConfig, cb: () => void) => {
    let value: string | boolean = config.value;

    // * Probably not the best but if a value is a boolean received as a string, convert to boolean type;
    if (value === "true") {
      value = true;
    }
    if (value === "false") {
      value = false;
    }

    sendPatchRequest(
      generateCompleteURL(
        AMLChecksEndpoints.check.patch,
        {
          check_id: config.checkId,
        },
        {},
      ),
      {
        [config.keyName]: value,
      },
      cb,
    );
  };

  return {
    IsLoading,
    RequestError,
    updateCheck,
  };
};
