import { MoonIcon, SunIcon } from "@heroicons/react/outline";
import useTheme from "providers/ThemeStoreProvider/useTheme";

const ThemeSelector = () => {
  const { Theme, changeTheme } = useTheme();

  return (
    <div className={Theme["theme-selector:container"]}>
      <div
        onClick={() => changeTheme("Light")}
        className={Theme["theme-selector:button:light"]}
      >
        <SunIcon className="w-4 h-4 block text-white" />
      </div>
      <div
        onClick={() => changeTheme("Dark")}
        className={Theme["theme-selector:button:dark"]}
      >
        <MoonIcon className="w-4 h-4 block text-white" />
      </div>
    </div>
  );
};

export default ThemeSelector;
