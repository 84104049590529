import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Loader from "components/UI/Loader/Loader";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { produce } from "immer";
import { ResourceInterface } from "interfaces/ResourceInterface";
import { determineNextAvailableDisplayOrderValue } from "modules/Projects/AML/utilities/determineNextAvailableDisplayOrderValue/determineNextAvailableDisplayOrderValue";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useState } from "react";
import { findById } from "utilities/findById/findById";
import { sortByDisplayOrder } from "utilities/sortByDisplayOrder/sortByDisplayOrder";
import { v4 as uuidv4 } from "uuid";
import { useRequestAMLAnswerOptionFields } from "../../../services/useRequestAMLAnswerOptionFields/useRequestAMLAnswerOptionFields";
import AnswerOptionFieldsList from "../AnswerOptionFieldsList/AnswerOptionFieldsList";

export interface LocalAMLAnswerOptionFieldInterface extends ResourceInterface {
  local_id: string;
  answer_option_field_group_id: string;
  name: string;
  description: string;
  display_order: number;
}

type AnswerOptionFieldsFormProps = {
  answer_option_field_group_id: string;
};

const AnswerOptionFieldsForm = (props: AnswerOptionFieldsFormProps) => {
  const { Theme } = useTheme();

  const [LocalAnswerOptionFields, setLocalAnswerOptionFields] = useState<Array<LocalAMLAnswerOptionFieldInterface>>([]);

  const {
    AnswerOptionFields,
    IsLoading,
    RequestError,
    getAnswerOptionFieldsIndex,
    postAnswerOptionField,
    patchAnswerOptionField,
    deleteAnswerOptionField,
  } = useRequestAMLAnswerOptionFields();

  useMountEffect(() => getAnswerOptionFieldsIndex(props.answer_option_field_group_id));

  const handleCreateNew = useCallback(() => {
    const newId = uuidv4();

    const newAnswerOptionField: LocalAMLAnswerOptionFieldInterface = {
      id: newId,
      local_id: newId,
      answer_option_field_group_id: props.answer_option_field_group_id,
      name: "",
      description: "",
      display_order: determineNextAvailableDisplayOrderValue([...LocalAnswerOptionFields, ...AnswerOptionFields]),
    };

    setLocalAnswerOptionFields((prev) =>
      produce(prev, (draft) => {
        draft.push(newAnswerOptionField);
      }),
    );
  }, [AnswerOptionFields, LocalAnswerOptionFields, props.answer_option_field_group_id]);

  const handleCreate = useCallback(
    (id: string, answer_option_id: string) => {
      const AnswerOptionFieldToCreate = findById(LocalAnswerOptionFields, id);

      if (!AnswerOptionFieldToCreate) {
        console.error("Missing expected local answer option field");
        return;
      }

      postAnswerOptionField({
        answer_option_field_group_id: props.answer_option_field_group_id,
        answer_option_id: answer_option_id,
        name: AnswerOptionFieldToCreate.name,
        description: AnswerOptionFieldToCreate.description,
        display_order: AnswerOptionFieldToCreate.display_order,
      });

      setLocalAnswerOptionFields((prev) =>
        produce(prev, (draft) => {
          return draft.filter((a) => a.id !== id);
        }),
      );
    },
    [LocalAnswerOptionFields, postAnswerOptionField, props.answer_option_field_group_id],
  );

  const handleEdit = useCallback(
    (values: { id: string; local_id?: string; name: string; description: string; display_order: number }) => {
      if (values.local_id) {
        setLocalAnswerOptionFields((prev) =>
          produce(prev, (draft) => {
            const idxToUpdate = draft.findIndex((i) => i.local_id === values.local_id);
            if (idxToUpdate > -1) {
              draft[idxToUpdate] = {
                ...draft[idxToUpdate],
                name: values.name,
                description: values.description,
                display_order: values.display_order,
              };
            }
          }),
        );
      } else {
        patchAnswerOptionField(values.id, {
          name: values.name,
          description: values.description,
          display_order: values.display_order,
        });
      }
    },
    [patchAnswerOptionField],
  );

  const handleDelete = useCallback(
    (id: string, local_id?: string) => {
      if (local_id) {
        setLocalAnswerOptionFields((prev) =>
          produce(prev, (draft) => {
            const idxToUpdate = draft.findIndex((i) => i.local_id === local_id);

            draft.splice(idxToUpdate, 1);
          }),
        );
      } else {
        deleteAnswerOptionField(id);
      }
    },
    [deleteAnswerOptionField],
  );

  const AllAnswerOptionFields = [...AnswerOptionFields, ...LocalAnswerOptionFields].sort(sortByDisplayOrder);

  return (
    <div className="relative min-h-[150px]">
      {IsLoading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center p-10">
          <div className={`${Theme["bg-primary"]} opacity-50 absolute top-0 bottom-0 left-0 right-0`}></div>
          <span className="inline-block w-6 h-6">
            <Loader />
          </span>
        </div>
      ) : null}
      {RequestError && <p className="text-center text-base mb-2">{RequestError.message}</p>}
      <div>
        <div className="flex justify-end">
          <PrimaryButton onClick={() => handleCreateNew()} title="Create Answer Option Field" icon={<PlusCircleIcon />}>
            New Field
          </PrimaryButton>
        </div>
        {AllAnswerOptionFields.length === 0 ? (
          <div className="mt-4">
            <p className="text-base text-center">No Answer Option Fields are currently defined for this group.</p>
          </div>
        ) : (
          <AnswerOptionFieldsList
            answerOptionFields={AllAnswerOptionFields}
            handleCreate={handleCreate}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        )}
      </div>
    </div>
  );
};

export default AnswerOptionFieldsForm;
