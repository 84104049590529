import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { isLevel2QuestionByQuestion } from "modules/Projects/AML/Questions/utilities/determineQuestionLevel";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useContext, useState } from "react";
import { QuestionsContext } from "../../../../QuestionsList/QuestionsList";

type SubQuestionSelectorProps = {
  currentSelections: Array<string>;
  handleSubQuestionSelect: (q_id: string) => void;
};

/**
 * * Select sub question
 */
const SubQuestionSelector = (props: SubQuestionSelectorProps) => {
  const { Theme } = useTheme();

  // * Show select sub question select field
  const [ShowSubQuestionSelect, setShowSubQuestionSelect] =
    useState<boolean>(false);

  // * Get Questions context to use for referencing sub q's
  const { questions } = useContext(QuestionsContext);

  /**
   * Handle selecting a sub question option
   * @param {string} q_id The Question Id
   */
  const handleSelectingNewSubQuestion = useCallback(
    (q_id: string) => {
      // * Check that the q exists (sanity check)
      const question = questions.find((q) => q.id === q_id);

      if (!question) return;

      // * Pass the id up
      props.handleSubQuestionSelect(q_id);

      // * Remove the sub question select box
      setShowSubQuestionSelect(false);
    },
    [questions, props]
  );

  const Selections =
    props.currentSelections.length > 0
      ? props.currentSelections.map((q_id) => {
          const question = questions.find((q) => q.id === q_id);

          return (
            <div key={q_id} className="flex items-center">
              <span
                onClick={() => props.handleSubQuestionSelect(q_id)}
                className="p-4 cursor-pointer hover:opacity-80"
              >
                <MinusCircleIcon className="h-5 w-5 inline-block" />
              </span>
              <p className="text-sm font-medium mb-1">
                {question
                  ? `${question.ref}: ${question.value}`
                  : `Question not found. This may be because it has been removed. Please
          remove this reference.`}
              </p>
            </div>
          );
        })
      : null;

  const SelectBoxOptions = questions
    .filter(
      (q) =>
        isLevel2QuestionByQuestion(q) && !props.currentSelections.includes(q.id)
    )
    .map((q) => (
      <option key={q.id} value={q.id}>
        {q.ref}: {q.value.slice(0, 75)}
      </option>
    ));

  return (
    <div className="flex items-center justify-between">
      <div className="flex-1 mr-2">
        <div>{Selections}</div>
        {ShowSubQuestionSelect && (
          <div>
            <select
              className={`${Theme["input"]} mr-2 flex-1`}
              defaultValue={""}
              onChange={(e) =>
                handleSelectingNewSubQuestion(e.currentTarget.value)
              }
            >
              <option value="" disabled>
                Select a Question
              </option>
              {SelectBoxOptions}
            </select>
          </div>
        )}
      </div>
      <div className="border-l">
        <div
          onClick={() => setShowSubQuestionSelect(!ShowSubQuestionSelect)}
          className="p-2 cursor-pointer hover:opacity-80"
        >
          <PlusCircleIcon className="h-5 w-5 inline-block" />
        </div>
      </div>
    </div>
  );
};

export default SubQuestionSelector;
