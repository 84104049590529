import { useCallback, useEffect, useRef, useState } from "react";

type StateUpdate<T> = T | ((state: T) => T);

/**
 * * useState hook with a callback on update
 * * Use in place of useState as normal, then when updating state, provide a callback
 * * Adapted from https://stackoverflow.com/questions/54954091/how-to-use-callback-with-usestate-hook-in-react
 * @returns
 */

export const useStateWithCallback = <T>(
  initialState: T | (() => T),
): [T, (state: StateUpdate<T>, cb?: (state: T) => void) => void] => {
  const [state, setState] = useState(initialState);
  const cbRef = useRef<((state: T) => void) | undefined>(undefined); // init mutable ref container for callbacks

  const setStateCallback = useCallback((st: StateUpdate<T>, cb?: (state: T) => void) => {
    cbRef.current = cb; // store current, passed callback in ref
    if (st instanceof Function) {
      // * Use updater function and pass previous state through
      setState((prev) => st(prev));
    } else {
      // * Use non updater function
      setState(st);
    }
  }, []); // keep object reference stable, exactly like `useState`

  useEffect(() => {
    // cb.current is `undefined` on initial render,
    // so we only invoke callback on state *updates*
    if (cbRef.current) {
      cbRef.current(state);
      cbRef.current = undefined; // reset callback after execution
    }
  }, [state]);

  return [state, setStateCallback];
};
