import {useState} from "react";
import {IOption} from "../../../../../../components/UI/Inputs/SelectField";
import {useMountEffect} from "../../../../../../hooks/useMountEffect/useMountEffect";
import {Identifiable} from "../../../../../../interfaces/ResourceInterface";
import {IAssessmentGroup, IAssessmentGroups} from "../../../AssessmentGroups/interfaces/IAssessmentGroup";
import {EditableSelectAssessmentRow} from "../EditableSelectAssessmentRow/EditableSelectAssessmentRow";

interface IProps extends Identifiable {
  readonly assessment_group_id: string,
  readonly assessmentGroups: IAssessmentGroups
}

export const EditableAssessmentRow = ({id, assessment_group_id, assessmentGroups}: IProps) => {
  const [selectedAssessmentGroup, setSelectedAssessmentGroup] = useState<IAssessmentGroup>()

  const handleOnUpdate = (value: string) => {
    const selected = assessmentGroups.find(i => i.id === value);
    setSelectedAssessmentGroup(selected)
  }

  const assessmentGroupsOptions = () => {
    return assessmentGroups.map(assessmentGroup => {
      return {
        key: assessmentGroup.id,
        value: assessmentGroup.id,
        displayValue: `${assessmentGroup.name} - ${assessmentGroup.id}`
      }
    }) as ReadonlyArray<IOption>;
  }

  useMountEffect(
    () => {
      const selected = assessmentGroups.find(i => i.id === assessment_group_id);
      setSelectedAssessmentGroup(selected)
    }
  );

  return (
    <EditableSelectAssessmentRow
      value={selectedAssessmentGroup?.name ?? assessment_group_id}
      name="Assessment Group ID"
      keyName="assessment_group_id"
      assessmentId={id}
      options={assessmentGroupsOptions()}
      handleOnUpdate={handleOnUpdate}
    />
  )
}