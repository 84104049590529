import { LogsRouter } from "./Logs/Router";
import { ProjectsRouter } from "./Projects/Router";
import { SettingsRouter } from "./Settings/Router";
import { TenantsRouter } from "./Tenants/Router";

export const ModulesRouter = (
  <>
    {LogsRouter} {ProjectsRouter} {SettingsRouter} {TenantsRouter}
  </>
);
