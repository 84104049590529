import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLQuestionInterface } from "../../interfaces/AMLQuestionInterface";

type QuestionTableDetailsProps = {
  question: AMLQuestionInterface;
};

const QuestionTableDetails = (props: QuestionTableDetailsProps) => (
  <tbody>
    <TableRow>
      <TableData>Question</TableData>
      <TableData>{props.question.value}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Note</TableData>
      <TableData>{props.question.note}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Guidance</TableData>
      <TableData>{props.question.guidance}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Ref</TableData>
      <TableData>{props.question.ref}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Level</TableData>
      <TableData>{props.question.level}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Display order position</TableData>
      <TableData>{props.question.display_order}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Allow Multiple?</TableData>
      <TableData>{props.question.allow_multiple ? "Yes" : "No"}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Created at</TableData>
      <TableData>
        {new Date(props.question.created_at).toLocaleString()}
      </TableData>
    </TableRow>
    <TableRow>
      <TableData>Updated at</TableData>
      <TableData>
        {new Date(props.question.updated_at).toLocaleString()}
      </TableData>
    </TableRow>
  </tbody>
);

export default QuestionTableDetails;
