import { lazy } from "react";
import { Outlet, Route } from "react-router-dom";
import { PromoCodes } from "./PromoCodes/routes";
import { PROMOTIONS } from "./Promotions/routes";
import { SETTINGS } from "./routes";
import { SettingsEnvProvider } from "./shared/providers/SettingsEndpointProvider/SettingsEnvProvider";

const Promotions = lazy(() => import("../Settings/Promotions/containers/Promotions"));
const PromoCodesView = lazy(() => import("../Settings/PromoCodes/containers/Promocodes"));

const SettingsRoutesWithProvider = () => (
  <SettingsEnvProvider>
    <Outlet />
  </SettingsEnvProvider>
);

export const SettingsRouter = (
  <Route path={SETTINGS} element={<SettingsRoutesWithProvider />}>
    <Route path={PROMOTIONS} element={<Promotions />} />
    <Route path={PromoCodes} element={<PromoCodesView />} />
  </Route>
);
