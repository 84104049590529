import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { LOGS_SERVICE_INDEX } from "modules/Logs/Services/routes";
import { Link } from "react-router-dom";
import { ServicesIndexInterface } from "../../interfaces/ServicesIndexInterface";

type ServicesIndexTableRowProps = {
  service: ServicesIndexInterface;
};

const ServicesIndexTableRow = (props: ServicesIndexTableRowProps) => (
  <TableRow>
    <TableData>
      <Link
        to={LOGS_SERVICE_INDEX.replace(/:id/, props.service.name)}
        className="block capitalize"
      >
        {props.service.name.replace(/-/g, " ")}
      </Link>
    </TableData>
    <TableData>
      <Link
        to={LOGS_SERVICE_INDEX.replace(/:id/, props.service.name)}
        className="block text-right"
      >
        <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">
          View Logs
        </span>
      </Link>
    </TableData>
  </TableRow>
);

export default ServicesIndexTableRow;
