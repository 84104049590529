import { AMLAnswerOptionFieldGroupInterface } from "../../../interfaces/AMLAnswerOptionFieldGroupInterface";
import AnswerOptionFieldGroupsListItem from "../AnswerOptionFieldGroupsListItem/AnswerOptionFieldGroupsListItem";

type AnswerOptionFieldGroupsListProps = {
  answerOptionFieldGroups: Array<AMLAnswerOptionFieldGroupInterface>;
  handleDelete: (id: string) => void;
  handleEdit: (values: {
    id: string;
    name: string;
    description: string;
    display_order: number;
  }) => void;
};

const AnswerOptionFieldGroupsList = (
  props: AnswerOptionFieldGroupsListProps
) => (
  <>
    {props.answerOptionFieldGroups.map((answerOptionFieldGroup) => (
      <AnswerOptionFieldGroupsListItem
        key={answerOptionFieldGroup.id}
        displayAdditionalConfig={props.answerOptionFieldGroups.length > 1}
        answerOptionFieldGroup={answerOptionFieldGroup}
        handleDelete={props.handleDelete}
        handleEdit={props.handleEdit}
      />
    ))}
  </>
);
export default AnswerOptionFieldGroupsList;
