import {AxiosError} from "axios";
import {useRequestAMLService} from "modules/Projects/shared/services/useRequestAMLService";
import {useCallback, useState} from "react";
import {generateCompleteURL} from "utilities/generateCompleteURL/generateCompleteURL";
import {IAssessments} from "../../Assessments/interfaces/IAssessment";
import {useRequestAssessments} from "../../Assessments/services/useRequestAssessments";
import {useUpdateAssessmentService} from "../../Assessments/services/useUpdateAssessmentService";
import {AMLTenantsAssessmentGroupsEndpoints} from "../endpoints";
import {IAssessmentGroup} from "../interfaces/IAssessmentGroup";

interface IUseRestoreAssessmentGroups {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly IsUpdating: boolean,
  readonly restoreAssessmentGroup: (
    assessment_group_id: string,
    cb: (assessmentGroup: IAssessmentGroup) => void
  ) => void;
  readonly updateAssessmentGroup: (
    config: IUpdateAssessmentGroupConfig,
    cb: (assessmentGroup: IAssessmentGroup) => void
  ) => void;
}

interface IUpdateAssessmentGroupConfig {
  readonly assessmentGroupId: string;
  readonly keyName: string;
  readonly value: string;
  readonly tenantId: string;
}

interface IAssessmentGroupResponse {
  readonly data: IAssessmentGroup;
}

export const useUpdateAssessmentGroups = (): IUseRestoreAssessmentGroups => {
  const { IsLoading, RequestError, sendPatchRequest } = useRequestAMLService<IAssessmentGroupResponse>();
  const {getAssessments} = useRequestAssessments();
  const {IsUpdating, UpdateError, updateAssessment} = useUpdateAssessmentService();
  const [assessments, setAssessments] = useState<IAssessments>([]);

  const updateAssessmentGroup = (config: IUpdateAssessmentGroupConfig, cb: (assessmentGroup: IAssessmentGroup) => void) => {
    const value: string | boolean = config.value;

    sendPatchRequest(
      generateCompleteURL(
        AMLTenantsAssessmentGroupsEndpoints.assessment_groups.patch,
        {
          assessment_group_id: config.assessmentGroupId
        },
        {},
      ),
      {
        [config.keyName]: value
      },
      (assessmentGroup) => {
        if(config.keyName === 'entity_id'){
          getAssessments(config.tenantId, config.assessmentGroupId, "false", (data) => setAssessments(data));

          assessments.forEach((assessment) => {
            console.log('begin update')
            updateAssessment(
              {
                assessmentId: assessment.id,
                keyName: 'entity_id',
                value: config.value,
              },
              () => {
                console.log('Updated assessment ' + assessment.id + ' with entity_id: ' + config.value);
              });
          });
        }

        cb(assessmentGroup.data)
      },
    )

  }

  const restoreAssessmentGroup = useCallback(
    (assessment_group_id: string, cb: (assessmentGroup: IAssessmentGroup) => void) =>
      sendPatchRequest(
        generateCompleteURL(
          AMLTenantsAssessmentGroupsEndpoints.assessment_groups.restore,
          {
            assessment_group_id: assessment_group_id
          },
          {},
        ),
        {},
        (assessmentGroup) => cb(assessmentGroup.data),
        {}
      ),
    [sendPatchRequest]
  );

  return {
    IsLoading,
    RequestError,
    IsUpdating,
    restoreAssessmentGroup,
    updateAssessmentGroup,
  }
}