import axios, { AxiosError, AxiosRequestHeaders, AxiosResponse } from "axios";
import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { handleRequestError } from "services/serviceUtilities/handleRequestError";
import {
  useRequestServiceConfigInterface,
  useRequestServiceDataInterface,
  useRequestServiceInterface,
} from "./useRequestServiceInterface";

export const useRequestService = <T>(): useRequestServiceInterface<T> => {
  /**
   * * The current loading state of the service
   */
  const [IsLoading, setIsLoading] = useState<boolean>(false);

  /**
   * * Track any errors that occur
   */
  const [RequestError, setRequestError] = useState<AxiosError | null>(null);

  /**
   * * Get auth key
   */
  const { clearAccessTokens } = useAuth();

  /**
   * * Reset the service
   */
  const reset = () => {
    setIsLoading(false);
    setRequestError(null);
  };

  /**
   * * Prepare request headers
   */
  const getRequestHeaders = (config?: useRequestServiceConfigInterface) => {
    const headers: AxiosRequestHeaders = {
      "Content-Type": config && config.contentType ? config.contentType : "application/json",
    };

    if (config && "bearer" in config) {
      headers["Authorization"] = `Bearer ${config.bearer}`;
    }

    return {
      ...headers,
      ...(config?.headers ? config.headers : {}),
    };
  };

  /**
   * * Handle response
   */
  const handleResponse = <T>(response: AxiosResponse<T, any>, callback?: (data: T) => void) => {
    const { data } = response;
    let preparedData: T;
    /**
     * * If response is a string format, then it needs to be parsed
     */
    if (typeof data === "string") {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        preparedData = JSON.parse(data) as T;
      } catch (e) {
        if (typeof e === "string") {
          throw new Error(e);
        } else if (e instanceof Error) {
          throw new Error(e.message);
        }
      }
    } else {
      // * Assign data to preparedData
      // @ts-ignore
      preparedData = data;
    }

    // @ts-ignore
    if (preparedData) {
      //@ts-ignore
      if ("success" in preparedData) {
        // @ts-ignore
        if (!preparedData.success) {
          // @ts-ignore
          throw new Error(preparedData.message);
        }
      }

      if (callback) {
        if (Array.isArray(preparedData)) {
          // * Check if array
          // @ts-ignore
          callback([...preparedData]);
        } else if (typeof preparedData === "object") {
          // * Check if object
          callback({ ...preparedData });
        } else {
          // * otherwise
          callback(preparedData);
        }
      }
    } else {
      setRequestError(new AxiosError("Request failed. Please try again."));
    }
  };

  /**
   * * Handle response error
   */
  const handleResponseError = useCallback(
    (err: any) => {
      /*
      if ("response" in err) {
        // * Check error response code
        if (err.response.status === 401 || err.response.status === 403) {
          // * then clear tokens to force log out
          // * disabling
          //   clearAccessTokens();
        }
      }
      */

      // * Handle error
      handleRequestError(err, setRequestError);
    },
    [clearAccessTokens],
  );

  // * ----- API ENDPOINTS -----* //

  /**
   * * send get request
   */
  const sendGetRequest = useCallback(
    <T>(endpoint: string, callback: (data: T) => void, config?: useRequestServiceConfigInterface) => {
      // * Don't run if service is already running or an error has occured
      if (IsLoading) return;

      // * Clear any current errors
      setRequestError(null);

      // * All ready to go ahead so flag service is loading
      setIsLoading(true);

      axios
        .get<T>(endpoint, { headers: getRequestHeaders(config) })
        .then((response) => handleResponse(response, callback))
        .catch(handleResponseError)
        .finally(() => setIsLoading(false));
    },
    [IsLoading, handleResponseError],
  );

  /**
   * * Send post request
   */
  const sendPostRequest = useCallback(
    (
      endpoint: string,
      data: useRequestServiceDataInterface | ReadonlyArray<useRequestServiceDataInterface>,
      callback?: (data: T) => void,
      config?: useRequestServiceConfigInterface,
    ) => {
      // * Don't run if service is already running or an error has occured
      if (IsLoading) return;

      // * Clear any current errors
      setRequestError(null);

      // * All ready to go ahead so flag service is loading
      setIsLoading(true);

      axios
        .post<T>(endpoint, data, {
          headers: getRequestHeaders(config),
        })
        .then((response) => handleResponse(response, callback))
        .catch(handleResponseError)
        .finally(() => setIsLoading(false));
    },
    [IsLoading, handleResponseError],
  );

  /**
   * * Send patch request
   */
  const sendPatchRequest = useCallback(
    (
      endpoint: string,
      data: useRequestServiceDataInterface,
      callback?: (data: T) => void,
      config?: useRequestServiceConfigInterface,
    ) => {
      // * Don't run if service is already running or an error has occured
      if (IsLoading) return;

      // * Clear any current errors
      setRequestError(null);

      // * All ready to go ahead so flag service is loading
      setIsLoading(true);

      axios
        .patch<T>(endpoint, data, {
          headers: getRequestHeaders(config),
        })
        .then((response) => handleResponse(response, callback))
        .catch(handleResponseError)
        .finally(() => setIsLoading(false));
    },
    [IsLoading, handleResponseError],
  );

  /**
   * * Send delete request
   */
  const sendDeleteRequest = useCallback(
    (endpoint: string, callback: (data: T) => void, config?: useRequestServiceConfigInterface) => {
      // * Don't run if service is already running or an error has occured
      if (IsLoading) return;

      // * Clear any current errors
      setRequestError(null);

      // * All ready to go ahead so flag service is loading
      setIsLoading(true);

      axios
        .delete<T>(endpoint, {
          headers: getRequestHeaders(config),
        })
        .then((response) => handleResponse(response, callback))
        .catch(handleResponseError)
        .finally(() => setIsLoading(false));
    },
    [IsLoading, handleResponseError],
  );

  /**
   * * Clear error
   */
  const clearError = useCallback(() => setRequestError(null), []);

  return {
    // * Service Loading State
    IsLoading,
    // * Service Error State
    RequestError,
    // * Reset service
    reset,
    // * send get request
    sendGetRequest,
    // * Send post request
    sendPostRequest,
    // * Send patch request
    sendPatchRequest,
    // * Send delete request
    sendDeleteRequest,
    // * Clear error
    clearError,
  };
};
