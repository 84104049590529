import { AuthStoreProvider } from "providers/AuthStoreProvider/AuthStoreProvider";
import { ThemeStoreProvider } from "providers/ThemeStoreProvider/ThemeStoreProvider";
import ReactDOM from "react-dom/client";
import "@sage/design-tokens/assets/fonts/sageui.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  //   <React.StrictMode>
  <BrowserRouter>
    <AuthStoreProvider>
      <ThemeStoreProvider>
        <ToastContainer limit={1} autoClose={8000} draggablePercent={60} closeOnClick={false} icon={false} />
        <App />
      </ThemeStoreProvider>
    </AuthStoreProvider>
  </BrowserRouter>,
  //   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
