import { ResourceInterface } from "interfaces/ResourceInterface";

/**
 * * Utility to find by id on an array of objects with {id:string}
 * @param {Array<T extends ResourceInterface>} arr The Array to search
 * @param {string} id The Id to search for
 * @returns {T | undefined} the found element or undefined if not found
 */
export const findById = <T extends ResourceInterface>(
  arr: Array<T>,
  id: T["id"]
  // * using loose equivalence to leverage typecasting
  // eslint-disable-next-line eqeqeq
): T | undefined => arr.find((i) => i.id == id);
