import { ErrorMessage, Field } from "formik";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { memo } from "react";

type FieldSetProps = {
  name: string;
  label: string;
  placeholder?: string;
  type?: string;
  autoComplete?: string;
  layoutHorizontal?: boolean;
  showLabel?: boolean;
  additionalInformation?: string;
  error: string | undefined;
  options?: Array<{
    value: string;
    content: string;
    config?: { [key: string]: string | boolean };
  }>;
  inputConfig?: { [key: string]: string };
  currentValue?: unknown;
};

const FieldSet = (props: FieldSetProps) => {
  const { Theme } = useTheme();

  return (
    <>
      <div className={props.layoutHorizontal ? "flex items-start" : ""}>
        <div
          className={`text-left mb-2 ${
            props.layoutHorizontal ? "mb-0 mr-2 w-36 min-w-[9rem] max-w-[9rem] text-right pt-2" : ""
          } ${!props.showLabel ? "hidden" : ""}`}
        >
          <label htmlFor={props.name} className={props.showLabel ? Theme["label"] : "sr-only"}>
            {props.label}
          </label>
        </div>
        <Field
          as={props.type !== undefined && ["select", "textarea"].includes(props.type) ? props.type : "input"}
          name={props.name}
          type={props.type ?? "text"}
          autoComplete={props.autoComplete}
          placeholder={props.placeholder}
          //   onChange={props.onChange}
          className={`${
            props.type !== "checkbox" ? `${Theme["input"]} ${props.error ? Theme["input:error"] : ""}` : "mt-3"
          }`}
          {...props.inputConfig}
        >
          {props.type === "select" && props.options
            ? props.options.map((option) => (
                <option value={option.value} {...option.config} key={option.value}>
                  {option.content}
                </option>
              ))
            : null}
        </Field>
      </div>
      {props.additionalInformation ? (
        <p className={`${Theme["input:additional-information"]} ${props.layoutHorizontal ? "ml-36 pl-2" : ""}`}>
          {props.additionalInformation}
        </p>
      ) : null}
      <ErrorMessage name={props.name}>{(error) => <p className={Theme["form:error"]}>{error}</p>}</ErrorMessage>
    </>
  );
};

export default memo(FieldSet);
