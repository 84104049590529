import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import useTheme from "providers/ThemeStoreProvider/useTheme";

type PaginationNavProps = {
  currentPage: number;
  lastPage: number;
  handleGoToPage: (page: number) => void;
};

const PaginationNav = (props: PaginationNavProps) => {
  const { Theme } = useTheme();

  const handleGoToPage = (page: number) => {
    if (page > 0 && page <= props.lastPage || (props.lastPage === -1)) {
      props.handleGoToPage(page);
    }
  };

  return (
    <div className={Theme["pagination:container"]}>
      <div className={Theme["pagination:inner"]}>
        { props.lastPage !== -1 ?
          <TertiaryButton
            disabled={props.currentPage === 1}
            onClick={() => handleGoToPage(1)}
            className="mx-2"
          >
            First
          </TertiaryButton>
          : null
        }
        <TertiaryButton
          disabled={props.currentPage === 1}
          onClick={() => handleGoToPage(props.currentPage - 1)}
          className="mx-2"
        >
          Prev
        </TertiaryButton>
        <p className={Theme["pagination:current"]}>
          Page {props.currentPage} { props.lastPage !== -1 ? `of ${props.lastPage}` : null }
        </p>
        <TertiaryButton
          disabled={props.currentPage === props.lastPage}
          onClick={() => handleGoToPage(props.currentPage + 1)}
          className="mx-2"
        >
          Next
        </TertiaryButton>
        { props.lastPage !== -1 ?
          <TertiaryButton
            disabled={props.currentPage === props.lastPage}
            onClick={() => handleGoToPage(props.lastPage)}
            className="mx-2"
          >
            Last
          </TertiaryButton>
          : null
        }
      </div>
    </div>
  );
};

export default PaginationNav;
