import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAssessmentTypeInterface } from "modules/Projects/AML/AssessmentTypes/interfaces/AMLAssessmentTypeInterface";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";

type TemplateTableDetailsProps = {
  assessmentType: AMLAssessmentTypeInterface;
  template: AMLTemplateInterface | null;
};

const TemplateTableDetails = (props: TemplateTableDetailsProps) => (
  <tbody>
    <TableRow>
      <TableData>Assessment Type</TableData>
      <TableData>{props.assessmentType.name}</TableData>
    </TableRow>
    {props.template && (
      <>
        <TableRow>
          <TableData>Name</TableData>
          <TableData>{props.template.name}</TableData>
        </TableRow>
        <TableRow>
          <TableData>Description</TableData>
          <TableData>{props.template.description}</TableData>
        </TableRow>
        <TableRow>
          <TableData>Created at</TableData>
          <TableData>
            {new Date(props.template.created_at).toUTCString()}
          </TableData>
        </TableRow>
        <TableRow>
          <TableData>Updated at</TableData>
          <TableData>
            {new Date(props.template.updated_at).toUTCString()}
          </TableData>
        </TableRow>
      </>
    )}
  </tbody>
);

export default TemplateTableDetails;
