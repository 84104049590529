import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_APP_LOGS_SHOW } from "../../endpoints";
import { AppLogsIndexResponseInterface } from "../../interfaces/AppLogsIndexResponseInterface";

type GetAppLogsIndexConfig = {
  id: string;
};

interface useRequestAppLogsIndexInterface<T> extends useRequestServiceImplementation<T> {
  AppLogs: T | null;
  getAppLogsIndex: (config: GetAppLogsIndexConfig) => void;
}

export const useRequestAppLogsIndex = <
  T extends AppLogsIndexResponseInterface,
>(): useRequestAppLogsIndexInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getAppLogsIndex = useCallback(
    (config: GetAppLogsIndexConfig) =>
      sendGetRequest(generateCompleteURL(GET_APP_LOGS_SHOW, { id: config.id }, {}), (data) => setData(data), {
        bearer: getAccessToken() as string,
      }),
    [getAccessToken, sendGetRequest],
  );

  return {
    AppLogs: Data,
    IsLoading,
    RequestError,
    getAppLogsIndex,
  };
};
