import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useMemo, useState } from "react";
import AMLEndpointContext, { AMLEndpointContextInterface } from "./AMLEndpointContext";
import AMLEndpointModal from "./components/AMLEndpointModal";
import { AML_ENDPOINTS } from "../../../AML/endpoints";
import { AMLEndpointEnvironmentType } from "../../../AML/interfaces/AMLEndpointEnvironmentType";

const { Provider } = AMLEndpointContext;

type AMLEndpointProviderProps = {
  children: React.ReactNode;
};

export const API_URL_FRAGMENT = "/api/v1";

export const AMLEndpointProvider = (props: AMLEndpointProviderProps) => {
  const { setAMLAccessToken } = useAuth();

  /**
   * * State holding current selected endpoint
   */

  const [SelectedAMLEndpointDetail, setSelectedAMLEndpointDetail] = useState<null | {
    title: string;
    name: AMLEndpointEnvironmentType;
    endpoint: string;
  }>(null);

  /**
   * * Get the current selected AML Endpoint
   */
  const getAMLEndpoint = useCallback(
    () => (SelectedAMLEndpointDetail ? SelectedAMLEndpointDetail.endpoint : ""),
    [SelectedAMLEndpointDetail],
  );

  /**
   * * Get the current endpoint title
   */
  const getAMLEndpointTitle = useCallback(
    () => (SelectedAMLEndpointDetail ? SelectedAMLEndpointDetail.title : ""),
    [SelectedAMLEndpointDetail],
  );

  /**
   * * Get the current endpoint name
   */
  const getAMLEndpointName = useCallback(
    () => (SelectedAMLEndpointDetail ? SelectedAMLEndpointDetail.name : null),
    [SelectedAMLEndpointDetail],
  );

  /**
   * * Clear the current endpoint
   */
  const clearAMLEndpoint = () => setSelectedAMLEndpointDetail(null);

  /**
   * * Select an AML Endpoint to use
   */
  const setAMLEndpoint = useCallback(
    (endpointEnv: AMLEndpointEnvironmentType, customURL?: string, customBearer?: string) => {
      const selectedEndpoint = AML_ENDPOINTS.find((i) => i.name === endpointEnv);

      if (selectedEndpoint) {
        if (endpointEnv === "localhost" && customURL) {
          if (customBearer) {
            setAMLAccessToken("localhost", customBearer);
          }
          setSelectedAMLEndpointDetail({
            ...selectedEndpoint,
            endpoint: customURL.concat(API_URL_FRAGMENT),
          });
        } else {
          setSelectedAMLEndpointDetail({
            ...selectedEndpoint,
            endpoint: selectedEndpoint.endpoint.concat(API_URL_FRAGMENT),
          });
        }
      }
    },
    [setAMLAccessToken],
  );

  const contextValue: AMLEndpointContextInterface = useMemo(() => {
    return {
      getAMLEndpoint,
      getAMLEndpointTitle,
      getAMLEndpointName,
      clearAMLEndpoint,
      setAMLEndpoint,
    };
  }, [getAMLEndpoint, getAMLEndpointName, getAMLEndpointTitle, setAMLEndpoint]);

  return <Provider value={contextValue}>{!SelectedAMLEndpointDetail ? <AMLEndpointModal /> : props.children}</Provider>;
};
