import { ArrowCircleDownIcon, ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { LoadingNavigationItemType, NavigationItemType } from "modules/NavigationTree";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import AppNavigationSubList from "../AppNavigationSubList/AppNavigationSubList";

type AppNavigationSubListItemProps = {
  item: NavigationItemType | LoadingNavigationItemType;
};

const AppNavigationSubListItem = (props: AppNavigationSubListItemProps) => {
  const { item } = props;

  const [CollapseSubList, setCollapseSubList] = useState<boolean>(false);

  const { Theme } = useTheme();

  if ("isLoader" in item) {
    return item.el;
  } else {
    return (
      <li className="text-inherit text-lg select-none">
        {item.isLink && item.urlPath ? (
          <NavLink
            to={item.urlPath}
            className={({ isActive }) =>
              isActive ? Theme["appnavigation:sublist:link:active"] : Theme["appnavigation:sublist:link"]
            }
          >
            <span className="capitalize">
              {item.icon ? <>{item.icon} </> : null}
              {item.name}
            </span>
          </NavLink>
        ) : (
          <div
            className={Theme["appnavigation:sublist:title"]}
            onClick={() => !item.isLink && setCollapseSubList(!CollapseSubList)}
          >
            <span>
              {item.icon ? <>{item.icon} </> : null}
              {item.name}
            </span>
            <span>
              {CollapseSubList ? (
                <ArrowCircleLeftIcon className={Theme["icon"]} />
              ) : (
                <ArrowCircleDownIcon className={Theme["icon"]} />
              )}
            </span>
          </div>
        )}

        {item.children && item.children.length > 0 ? (
          <AppNavigationSubList collapse={CollapseSubList} subList={item.children} />
        ) : null}
      </li>
    );
  }
};

export default AppNavigationSubListItem;
