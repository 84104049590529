import { LogoutIcon } from "@heroicons/react/outline";
import LogoRound from "assets/logo-round.png";
import ThemeSelector from "components/ThemeSelector/ThemeSelector";
import useAuth from "providers/AuthStoreProvider/useAuth";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { Link } from "react-router-dom";
import { ROOT } from "routes/routes";
import PrimaryButton from "../Button/PrimaryButton/PrimaryButton";

const AppHeader = () => {
  /**
   * * If logging out, clear the access tokens
   */
  const { clearAccessTokens } = useAuth();

  /**
   * * Handle logging out
   */
  const handleLogOut = () => clearAccessTokens();

  const { Theme } = useTheme();

  return (
    <header className={Theme["appheader:container"]}>
      {/* * Left */}
      <div className="flex items-center">
        <div className="w-6">
          <Link to={ROOT} className="flex items-center">
            <img src={LogoRound} className="block" alt="Watcher Logo" />
            <span className="ml-2 text-inherit text-sm font-semibold whitespace-nowrap">Watcher</span>
          </Link>
        </div>
      </div>

      {/* * Right */}
      <div className="flex items-center">
        <div className="mr-2">
          {/* * Theme selector */}
          <ThemeSelector />
        </div>
        <div>
          <PrimaryButton
            className="rounded-none"
            onClick={handleLogOut}
            icon={<LogoutIcon className="text-inherit" />}
            iconAlign="right"
          >
            Logout
          </PrimaryButton>
        </div>
      </div>
    </header>
  );
};

export default AppHeader;
