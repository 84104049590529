import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/outline";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";
import AppNavigationTree from "./AppNavigationTree/AppNavigationTree";

type AppNavigationProps = {
  condensed?: boolean;
  toggleCondense: () => void;
};

const AppNavigation = (props: AppNavigationProps) => {
  const { Theme } = useTheme();

  return (
    <nav
      className={twMerge(
        Theme["appnavigation:container"],
        props.condensed ? "w-16" : "w-60"
      )}
    >
      {/* * Tab for condensing view */}
      <div
        onClick={props.toggleCondense}
        className={`absolute top-2 left-[calc(100%-1px)] right-auto bottom-auto shadow-md rounded-r-md p-2 cursor-pointer ${Theme["bg-secondary"]}`}
      >
        {props.condensed ? (
          <ChevronDoubleRightIcon className={Theme["icon"]} />
        ) : (
          <ChevronDoubleLeftIcon className={Theme["icon"]} />
        )}
      </div>

      {/* * Navigation tree */}
      <AppNavigationTree
        condensed={props.condensed}
        toggleCondense={props.toggleCondense}
      />
    </nav>
  );
};

export default AppNavigation;
