/**
 * * Determine if level 1 question
 */
export const isLevel1QuestionByLevel = (level: string | number): boolean =>
  level === 1 || level === "1";

/**
 * * Determine if level 1 question by passed question object
 */
export const isLevel1QuestionByQuestion = <
  T extends { level: string | number }
>(
  question: T
): boolean => question.level === 1 || question.level === "1";

/**
 * * Determine if level 2 question
 */
export const isLevel2QuestionByLevel = (level: string | number): boolean =>
  level === 2 || level === "2";

/**
 * * Determine if level 2 question by passed question object
 */
export const isLevel2QuestionByQuestion = <
  T extends { level: string | number }
>(
  question: T
): boolean => question.level === 2 || question.level === "2";
