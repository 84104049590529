type ServiceLogLevelBadgeProps = { level_name: string };

const ServiceLogLevelBadge = (props: ServiceLogLevelBadgeProps) => (
  <span
    className={
      "align-middle text-xs font-semibold inline-block py-1 px-2 rounded last:mr-0 mr-1 truncate cursor-default " +
      (props.level_name === "EMERGENCY"
        ? "text-white bg-negative-600 border-2 border-negative-500"
        : props.level_name === "ALERT"
        ? "text-white bg-negative-600 border-2 border-negative-500"
        : props.level_name === "CRITICAL"
        ? "text-white bg-negative-500 border-2 border-negative-600"
        : props.level_name === "ERROR"
        ? "text-negative-500 bg-red-200"
        : props.level_name === "WARNING"
        ? "text-white bg-caution-600"
        : props.level_name === "NOTICE"
        ? "text-white bg-caution-500"
        : props.level_name === "INFO"
        ? "text-white bg-blue-500"
        : props.level_name === "DEBUG"
        ? "text-gray-600 bg-gray-200"
        : "")
    }
  >
    {props.level_name}
  </span>
);

export default ServiceLogLevelBadge;
