import useTheme from "providers/ThemeStoreProvider/useTheme";
import ReactDOM from "react-dom";
import { twMerge } from "tailwind-merge";

type ModalProps = {
  children?: React.ReactNode;
  onClose?: () => void;
  wide?: boolean;
};

const Modal = (props: ModalProps) => {
  const { Theme } = useTheme();

  return ReactDOM.createPortal(
    <>
      <div
        onClick={() => props.onClose && props.onClose()}
        className={Theme["modal:background"]}
      ></div>
      <div
        className={twMerge(
          Theme["modal:container"],
          props.wide ? "w-3/4" : "w-96"
        )}
      >
        <div className={Theme["modal:inner"]}>
          {props.children && props.children}
        </div>
      </div>
    </>,
    document.querySelector("#modal-portal") as Element
  );
};

export default Modal;
