import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface TableRowProps extends React.ComponentPropsWithoutRef<"tr"> {
  className?: string;
  children: React.ReactNode;
}

const TableRow = (props: TableRowProps) => {
  const { Theme } = useTheme();

  return (
    <tr {...props} className={twMerge(Theme["table:row"], props.className)}>
      {props.children}
    </tr>
  );
};

export default TableRow;
