import { Link, LinkProps, useLocation } from "react-router-dom";

export const LinkWithSearchQuery = ({ to, ...props }: LinkProps & React.RefAttributes<HTMLAnchorElement>) => {
  const { search } = useLocation();

  let target;

  if (typeof to === "string") {
    // * if search string is provided, add search to the end of the string
    const embeddedSearch = to.split("?");
    if (embeddedSearch.length > 1) {
      target = `${embeddedSearch[0]}${search}&${embeddedSearch[1]}`;
    } else {
      // * no embedded search provided
      target = `${to}${search}`;
    }
  } else if (to.pathname) {
    // * if pathname provided, add search to end of string
    target = `${to.pathname}${search}`;
  } else {
    // * otherwise, leave as default for hash and search fields
    target = to;
  }

  return <Link to={target} {...props} />;
};
