import { ExclamationIcon } from "@heroicons/react/outline";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";
import Message from "../Message/Message";

type NegativeMessageProps = {
  title?: string;
  content: string;
};

const NegativeMessage = (props: NegativeMessageProps) => {
  const { Theme } = useTheme();

  return (
    <Message
      theme="negative"
      icon={
        <ExclamationIcon className={twMerge(Theme["icon"], "text-white")} />
      }
      {...props}
    />
  );
};

export default NegativeMessage;
