/*
 * This service is to provide an interface between useRequestService and custom
 * services by applying the selected endpoint to the passed URL.
 * All other args will be passed as normal
 */

import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import {
  useRequestServiceConfigInterface,
  useRequestServiceDataInterface,
  useRequestServiceInterface,
} from "services/useRequestService/useRequestServiceInterface";
import useAMLEndpoint from "../Providers/AMLEndpointProvider/useAMLEndpointProvider";

export const useRequestAMLService = <T>(): useRequestServiceInterface<T> => {
  const { getAMLEndpoint, getAMLEndpointName } = useAMLEndpoint();

  const { getAMLAccessToken } = useAuth();

  // * Get AML bearer token
  const getHttpConfig = useCallback(() => {
    const amlEnvName = getAMLEndpointName();

    return {
      bearer: amlEnvName ? (getAMLAccessToken(amlEnvName) as string) : "",
    };
  }, [getAMLAccessToken, getAMLEndpointName]);

  const {
    IsLoading,
    RequestError,
    clearError,
    reset,
    sendDeleteRequest,
    sendGetRequest,
    sendPatchRequest,
    sendPostRequest,
  } = useRequestService<T>();

  const sendAMLGetRequest = (
    endpoint: string,
    callback: (data: T) => void,
    config?: useRequestServiceConfigInterface,
  ) => {
    const amlEndpoint = getAMLEndpoint();
    sendGetRequest(amlEndpoint.concat(endpoint), callback, {
      ...getHttpConfig(),
      ...config,
    });
  };

  const sendAMLPatchRequest = (
    endpoint: string,
    data: useRequestServiceDataInterface,
    callback?: (data: T) => void,
    config?: useRequestServiceConfigInterface,
  ) => {
    const amlEndpoint = getAMLEndpoint();
    sendPatchRequest(amlEndpoint.concat(endpoint), data, callback, {
      ...getHttpConfig(),
      ...config,
    });
  };

  const sendAMLPostRequest = (
    endpoint: string,
    data: useRequestServiceDataInterface | ReadonlyArray<useRequestServiceDataInterface>,
    callback?: (data: T) => void,
    config?: useRequestServiceConfigInterface,
  ) => {
    const amlEndpoint = getAMLEndpoint();
    sendPostRequest(amlEndpoint.concat(endpoint), data, callback, {
      ...getHttpConfig(),
      ...config,
    });
  };

  const sendAMLDeleteRequest = (
    endpoint: string,
    callback: (data: T) => void,
    config?: useRequestServiceConfigInterface,
  ) => {
    const amlEndpoint = getAMLEndpoint();
    sendDeleteRequest(amlEndpoint.concat(endpoint), callback, {
      ...getHttpConfig(),
      ...config,
    });
  };

  return {
    IsLoading,
    RequestError,
    clearError,
    reset,
    sendDeleteRequest: sendAMLDeleteRequest,
    sendGetRequest: sendAMLGetRequest,
    sendPatchRequest: sendAMLPatchRequest,
    sendPostRequest: sendAMLPostRequest,
  };
};
