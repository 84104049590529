/**
 * * Hook interface to Auth context
 * * Reduces need for rewriting context imports each time its required
 * * And includes checking that the component is within a AuthProvider
 */

import { useContext } from "react";
import AuthStoreContext, { AuthContextInterface } from "./AuthStoreContext";

const useAuth = () => {
  const context = useContext<AuthContextInterface>(AuthStoreContext);

  // * Add some error logging
  if (context === undefined) {
    console.error(
      "[useAuth] Context not provided. Ensure your component is a child of <AuthStoreProvider />"
    );
  }

  return context;
};

export default useAuth;
