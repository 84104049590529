type ServiceLogsTopNavProps = {
  total: number;
};

const ServiceLogsTopNav = (props: ServiceLogsTopNavProps) => (
  <div className="flex items-center justify-between">
    <div className="p-2 flex items-center justify-between">
      <p className="text-sm font-medium">{props.total} logs</p>
    </div>
  </div>
);

export default ServiceLogsTopNav;
