import { useState } from "react";
import { AmlCheckVendorEnum, AmlCheckVendorString } from "../../interfaces/AmlCheckVendorEnum";
import { EditableSelectCheckRow } from "../EditableSelectCheckRow/EditableSelectCheckRow";

interface IProps {
  readonly id: string;
  readonly vendor?: AmlCheckVendorEnum;
}

export const EditableCheckVendorRow = ({ id, vendor }: IProps) => {
  const [currentVendor, setCurrentVendor] = useState(vendor);

  const handleOnUpdate = (value: string) => {
    setCurrentVendor(value as AmlCheckVendorEnum);
  };

  return (
    <EditableSelectCheckRow
      content={currentVendor ? AmlCheckVendorString[currentVendor] : "Vendor missing"}
      value={vendor ?? ""}
      keyName="vendor"
      name="Vendor"
      options={Object.values(AmlCheckVendorEnum).map((v) => ({
        key: v,
        value: v,
        displayValue: AmlCheckVendorString[v],
      }))}
      checkId={id}
      handleOnUpdate={handleOnUpdate}
    />
  );
};
