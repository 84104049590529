import { twMerge } from "tailwind-merge";
import { AMLStatuses, AmlCheckStatusBg, AmlCheckStatusString } from "../../interfaces/AmlCheckStatusEnum";

interface IProps {
  readonly status: AMLStatuses;
}

export const CheckStatusBadge = ({ status }: IProps) => (
  <span className={twMerge(AmlCheckStatusBg[status], "px-1 inline-block rounded-sm")}>
    {AmlCheckStatusString[status]}
  </span>
);
