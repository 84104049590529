import { POST_LOGIN } from "fetch/endpoints";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { LoginResponseInterface } from "./useRequestLoginInterface";

type postLoginConfig = {
  username: string;
  password: string;
};

interface useRequestLoginInterface<T>
  extends useRequestServiceImplementation<T> {
  Tokens: T | null;
  postLogin: (config: postLoginConfig) => void;
}

/**
 * * Hook to request Auth TokenData
 */
export const useRequestLogin = <
  T extends LoginResponseInterface
>(): useRequestLoginInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { IsLoading, RequestError, sendPostRequest } = useRequestService<T>();

  const postLogin = useCallback(
    (config: postLoginConfig) =>
      sendPostRequest(POST_LOGIN, { ...config }, (data) => setData(data)),
    [sendPostRequest]
  );

  return {
    Tokens: Data,
    IsLoading,
    RequestError,
    postLogin,
  };
};
