import {
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAssessmentTypeInterface } from "modules/Projects/AML/AssessmentTypes/interfaces/AMLAssessmentTypeInterface";
import { AMLEntityTypeInterface } from "modules/Projects/AML/EntityTypes/interfaces/AMLEntityTypeInterface";
import { AML_TEMPLATE } from "modules/Projects/AML/routes";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";
import TemplateTableDetails from "../TemplateTableDetails/TemplateTableDetails";

type TemplateTableProps = {
  entitySubType: AMLEntityTypeInterface;
  assessmentType: AMLAssessmentTypeInterface;
  template: AMLTemplateInterface | null;
  handleCreate: (assessmentTypeId: string, entitySubTypeId: string) => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const TemplateTable = (props: TemplateTableProps) => {
  const navigate = useNavigate();

  const handleCreate = useCallback(() => {
    props.handleCreate(props.assessmentType.id, props.entitySubType.id);
  }, [props]);

  const handleEdit = useCallback(() => {
    if (props.template) {
      props.handleEdit(props.template.id);
    }
  }, [props]);

  const handleDelete = useCallback(() => {
    if (props.template) {
      props.handleDelete(props.template.id);
    }
  }, [props]);

  const handleView = useCallback(() => {
    props.template && navigate(AML_TEMPLATE.replace(/:id/, props.template.id));
  }, [navigate, props]);

  return (
    <div className="mt-4 first-of-type:mt-0">
      <Table>
        <thead>
          <TableRow>
            <TableHeading colSpan={2}>
              <div className="flex justify-between">
                {props.template
                  ? `${props.entitySubType.name}: ${props.assessmentType.name}`
                  : `The Template for ${props.entitySubType.name}: ${props.assessmentType.name} hasn't been set up yet`}
                <div className="flex items-center">
                  {!props.template ? (
                    <span
                      className="cursor-pointer hover:opacity-80"
                      onClick={handleCreate}
                      title="Create Template"
                    >
                      <PlusCircleIcon className="w-5 h-5 inline-block mr-1" />
                    </span>
                  ) : (
                    <>
                      <span
                        className="cursor-pointer mr-4 hover:opacity-80"
                        onClick={handleEdit}
                        title="Edit Template"
                      >
                        <PencilIcon className="h-5 w-5 inline-block" />
                      </span>
                      <span
                        className="cursor-pointer hover:opacity-80"
                        onClick={handleDelete}
                        title="Delete Template"
                      >
                        <MinusCircleIcon className="h-5 w-5 inline-block" />
                      </span>
                    </>
                  )}
                </div>
              </div>
            </TableHeading>
          </TableRow>
        </thead>
        <TemplateTableDetails
          assessmentType={props.assessmentType}
          template={props.template}
        />
        <tfoot>
          <TableRow>
            <TableHeading colSpan={2}>
              {props.template ? (
                <div className="flex justify-between items-center">
                  <div>
                    <PrimaryButton onClick={handleView} className="mr-2">
                      View
                    </PrimaryButton>
                  </div>
                  <div className="flex items-center">
                    <PrimaryButton onClick={handleEdit} className="mr-2">
                      Edit
                    </PrimaryButton>
                    <TertiaryButton onClick={handleDelete}>
                      Delete
                    </TertiaryButton>
                  </div>
                </div>
              ) : (
                <div className="flex justify-end items-center">
                  <PrimaryButton onClick={handleCreate}>Create</PrimaryButton>
                </div>
              )}
            </TableHeading>
          </TableRow>
        </tfoot>
      </Table>
    </div>
  );
};

export default TemplateTable;
