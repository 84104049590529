import { SearchIcon } from "@heroicons/react/outline";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import { Field, Formik } from "formik";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

type AppsTableTopNavProps = {
  total: number;
  currentQuery: string;
  handleQuerySearch: (query: string) => void;
};

const AppsTableTopNav = (props: AppsTableTopNavProps) => {
  const { Theme } = useTheme();

  const handleQuerySearch = (values: { query: string }) => {
    props.handleQuerySearch(values.query);
  };

  return (
    <div className="flex items-center justify-between">
      <div className="p-2 flex items-center justify-between">
        <p className="text-sm font-medium">
          {props.currentQuery ? (
            <>
              found for <span className="font-semibold text-positive-500">{props.currentQuery}</span>
            </>
          ) : (
            ""
          )}
        </p>
        {props.currentQuery && (
          <TertiaryButton title="Clear search query" className="ml-2" onClick={() => handleQuerySearch({ query: "" })}>
            Clear
          </TertiaryButton>
        )}
      </div>
      <div className="py-2">
        <Formik
          initialValues={{
            query: "",
          }}
          onSubmit={(values, { resetForm }) => {
            handleQuerySearch(values);
            resetForm({ values: { query: "" } });
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex items-stretch">
              <Field name="query" className={twMerge(Theme["input"], "w-56")} placeholder="Search apps..." id="query" />
              <TertiaryButton type="submit" title="Search" className="ml-2">
                <SearchIcon className={Theme["icon"]} />
              </TertiaryButton>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AppsTableTopNav;
