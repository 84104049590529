import { MinusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAssessmentTypeInterface } from "../../interfaces/AMLAssessmentTypeInterface";
import AssessmentTypesTableDetails from "../AssessmentTypesTableDetails/AssessmentTypesTableDetails";

type AssessmentTypesTableProps = {
  assessmentType: AMLAssessmentTypeInterface;
  handleEdit: (assessmentTypeId: string) => void;
  handleDelete: (assessmentTypeId: string) => void;
};

const AssessmentTypesTable = (props: AssessmentTypesTableProps) => {
  const handleEdit = () => props.handleEdit(props.assessmentType.id);

  const handleDelete = () => props.handleDelete(props.assessmentType.id);

  return (
    <div className="mt-4 first-of-type:mt-0">
      <Table>
        <thead>
          <TableRow>
            <TableHeading>{props.assessmentType.name}</TableHeading>
            <TableHeading>
              <div className="flex justify-end">
                <span
                  className="cursor-pointer mr-4 hover:opacity-80"
                  onClick={() => handleEdit()}
                  title="Edit Assessment type"
                >
                  <PencilIcon className="h-5 w-5 inline-block" />
                </span>
                <span
                  className="cursor-pointer hover:opacity-80"
                  onClick={() => handleDelete()}
                  title="Delete Assessment type"
                >
                  <MinusCircleIcon className="h-5 w-5 inline-block" />
                </span>
              </div>
            </TableHeading>
          </TableRow>
        </thead>
        <AssessmentTypesTableDetails assessmentType={props.assessmentType} />
      </Table>
    </div>
  );
};

export default AssessmentTypesTable;
