import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import Modal from "components/UI/Modal/Modal";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { AMLAnswerOptionTypeInterface } from "../../interfaces/AMLAnswerOptionTypeInterface";

type DeleteAnswerOptionTypeModalProps = {
  answerOptionType: AMLAnswerOptionTypeInterface;
  onDelete: (id: string) => void;
  onClose: () => void;
};

const DeleteAnswerOptionTypeModal = (
  props: DeleteAnswerOptionTypeModalProps
) => {
  const { Theme } = useTheme();

  return (
    <Modal onClose={props.onClose}>
      <div className={`${Theme["bg-tertiary"]} px-4 py-2`}>
        <h2 className="font-medium text-lg">
          Delete Answer Option Type: {props.answerOptionType.name}
        </h2>
      </div>
      <div className={`p-4`}>
        <p className="font-normal text-base">
          Are you sure you wish to delete this Answer Option Type?
        </p>
      </div>
      <div
        className={`${Theme["bg-tertiary"]} p-4 flex items-center justify-between`}
      >
        <TertiaryButton onClick={() => props.onClose()}>Cancel</TertiaryButton>
        <PrimaryButton
          onClick={() => props.onDelete(props.answerOptionType.id)}
        >
          Delete
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default DeleteAnswerOptionTypeModal;
