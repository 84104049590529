import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAssessmentTypeInterface } from "../../interfaces/AMLAssessmentTypeInterface";

type AssessmentTypesTableDetailsProps = {
  assessmentType: AMLAssessmentTypeInterface;
};

const AssessmentTypesTableDetails = (
  props: AssessmentTypesTableDetailsProps
) => (
  <tbody>
    <TableRow>
      <TableData>Description</TableData>
      <TableData>{props.assessmentType.description}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Assessment Type Key</TableData>
      <TableData>{props.assessmentType.key}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Created at</TableData>
      <TableData>
        {new Date(props.assessmentType.created_at).toLocaleString()}
      </TableData>
    </TableRow>
    <TableRow>
      <TableData>Updated at</TableData>
      <TableData>
        {new Date(props.assessmentType.updated_at).toLocaleString()}
      </TableData>
    </TableRow>
  </tbody>
);

export default AssessmentTypesTableDetails;
