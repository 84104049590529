/* eslint-disable no-loops/no-loops */ // TODO: Refactor this
import { AMLEntityTypeInterface } from "../interfaces/AMLEntityTypeInterface";

export const findEntityType = (
  entityTypes: Array<AMLEntityTypeInterface>,
  id: string,
): AMLEntityTypeInterface | null => {
  let foundEntity: AMLEntityTypeInterface | null = null;

  // Loop over entity types
  for (let e = 0; e < entityTypes.length; e++) {
    const entityType = entityTypes[e];

    if (entityType.id === id) {
      // If found, store and break
      foundEntity = entityType;
      break;
    }

    // * loop over any sub types
    if ("entity_sub_types" in entityType && entityType.entity_sub_types) {
      foundEntity = findEntityType(entityType.entity_sub_types, id);
      if (foundEntity) {
        // if found, break
        break;
      }
    }
  }

  return foundEntity;
};
