import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import {
  DELETE_ASSESSMENT_TYPE,
  GET_ASSESSMENT_TYPES,
  PATCH_ASSESSMENT_TYPE,
  POST_ASSESSMENT_TYPE,
} from "../../endpoints";
import { AMLAssessmentTypeInterface } from "../../interfaces/AMLAssessmentTypeInterface";

type SubmitAssessmentType = {
  name: string;
  description: string;
};

interface useRequestAMLAssessmentTypesInterface<T> extends useRequestServiceImplementation<T> {
  AssessmentTypes: Array<T>;
  getAssessmentTypesIndex: () => void;
  postAssessmentType: (config: SubmitAssessmentType) => void;
  patchAssessmentType: (id: string, config: SubmitAssessmentType) => void;
  deleteAssessmentType: (id: string) => void;
}

export const useRequestAMLAssessmentTypes = <
  T extends AMLAssessmentTypeInterface,
>(): useRequestAMLAssessmentTypesInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on successful get response
   */
  const handleOnGetResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      createResource(data);
    },
    [createResource],
  );

  const getAssessmentTypesIndex = useCallback(
    () => sendGetRequest(generateCompleteURL(GET_ASSESSMENT_TYPES, {}, {}), handleOnGetResponse, {}),
    [handleOnGetResponse, sendGetRequest],
  );

  /**
   * * Handle on successful post request
   */

  const handleOnPostResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * handle requesting post
   */
  const postAssessmentType = useCallback(
    (config: SubmitAssessmentType) =>
      sendPostRequest(
        generateCompleteURL(POST_ASSESSMENT_TYPE, {}, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
          key: config.name.toLowerCase().trim().replace(/ /, "-"),
        },
        (response) => handleOnPostResponse(response),
        {},
      ),
    [handleOnPostResponse, sendPostRequest],
  );

  /**
   * * Handle on patch response
   */
  const handleOnPatchResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * handle requesting patch
   */
  const patchAssessmentType = useCallback(
    (id: string, config: SubmitAssessmentType) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_ASSESSMENT_TYPE, { id: id }, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
        },
        handleOnPatchResponse,
        {},
      ),
    [handleOnPatchResponse, sendPatchRequest],
  );

  /**
   * * Handle on delete response
   */
  const handleOnDeleteResponse = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * handle requesting delete
   */
  const deleteAssessmentType = useCallback(
    (id: string) =>
      sendDeleteRequest(
        generateCompleteURL(DELETE_ASSESSMENT_TYPE, { id: id }, {}),
        () => handleOnDeleteResponse(id),
        {},
      ),
    [handleOnDeleteResponse, sendDeleteRequest],
  );

  return {
    AssessmentTypes: Resource,
    IsLoading,
    RequestError,
    getAssessmentTypesIndex,
    postAssessmentType,
    patchAssessmentType,
    deleteAssessmentType,
  };
};
