import {AxiosError} from "axios";
import {useCallback} from "react";
import {generateCompleteURL} from "../../../../../utilities/generateCompleteURL/generateCompleteURL";
import {useRequestAMLService} from "../../../shared/services/useRequestAMLService";
import {AssessmentEndpoints} from "../endpoints";
import {IAssessment} from "../interfaces/IAssessment";

interface IUpdateAssessmentConfig {
  readonly assessmentId: string;
  readonly keyName: string;
  readonly value: string;
}

interface IUseRequestUpdateAssessments {
  readonly IsUpdating: boolean;
  readonly UpdateError: AxiosError | null;
  readonly updateAssessment: (config: IUpdateAssessmentConfig, cb: () => void) => void;
  readonly restoreAssessment: (assessment_id: string, cb: (assessment: IAssessment) => void) => void;
}

export const useUpdateAssessmentService = (): IUseRequestUpdateAssessments => {
  const { IsLoading, RequestError, sendPatchRequest } = useRequestAMLService();

  const updateAssessment = (config: IUpdateAssessmentConfig, cb: () => void) => {
    let value: string | boolean = config.value;

    // * Probably not the best but if a value is a boolean received as a string, convert to boolean type;
    if (value === "true") {
      value = true;
    }
    if (value === "false") {
      value = false;
    }

    sendPatchRequest(
      generateCompleteURL(
        AssessmentEndpoints.assessment.patch,
        {
          assessment_id: config.assessmentId
        },
        {},
      ),
      {
        [config.keyName]: value
      },
      cb
    )
  };

  const restoreAssessment = useCallback(
    (assessment_id: string, cb: (assessment: IAssessment) => void) =>
      sendPatchRequest(
        generateCompleteURL(
          AssessmentEndpoints.assessment.restore,
          {
            assessment_id: assessment_id
          },
          {},
        ),
        {},
        (assessment) => cb(assessment as IAssessment),
        {}
        ),
    [sendPatchRequest]
      );

  return {
    IsUpdating: IsLoading,
    UpdateError: RequestError,
    updateAssessment,
    restoreAssessment
  }
}