/**
 * Free Type configuration
 * No current rules need setting up for a TextArea as it's free entry for a user
 */
const TextAreaConfig = () => (
  <div className="my-2">
    <p className="text-base text-center">
      A Text Area is a large text box that the user can freely enter any
      response into.
    </p>
  </div>
);

export default TextAreaConfig;
