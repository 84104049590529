import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { useParams } from "react-router-dom";
import { WORDPRESS_INDEX } from "../../../routes";
import AppLogsTable from "../../components/AppLogsTable/AppLogsTable";
import { useRequestAppLogsIndex } from "../../services/useRequestAppLogsIndex/useRequestAppLogsIndex";

const AppLogsIndex = () => {
  const { AppLogs, getAppLogsIndex, RequestError, IsLoading } = useRequestAppLogsIndex();

  const { id } = useParams<{ id: string }>();

  useMountEffect(() =>
    getAppLogsIndex({
      id: id as string,
    }),
  );

  return (
    <Page
      pageTitle="WordPress Logs"
      isLoadingContent={IsLoading}
      hasError={RequestError}
      breadcrumbs={[
        {
          url: WORDPRESS_INDEX,
          content: "Back to All Tenants",
        },
      ]}
      wide
    >
      {AppLogs && (
        <>
          <div className="p-2">
            <p className="text-sm font-medium">{AppLogs.data.length} logs</p>
          </div>
          <AppLogsTable logs={AppLogs.data} />
        </>
      )}
    </Page>
  );
};

export default AppLogsIndex;
