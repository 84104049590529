import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import Modal from "components/UI/Modal/Modal";
import { Formik } from "formik";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import useTheme from "providers/ThemeStoreProvider/useTheme";

type CreateAnswerOptionTypeModalProps = {
  answerOptionType: null;
  onSubmit: (values: { name: string; description: string }) => void;
  onClose: () => void;
};

const CreateAnswerOptionTypeModal = (props: CreateAnswerOptionTypeModalProps) => {
  const { Theme } = useTheme();

  const { getAMLEndpointTitle } = useAMLEndpoint();

  const onSubmit = (values: { name: string; description: string }) => {
    props.onSubmit({
      ...values,
    });
  };

  return (
    <Modal onClose={props.onClose}>
      <Formik
        initialValues={{
          name: "",
          description: "",
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className={`${Theme["bg-tertiary"]} px-4 py-2`}>
              <h2 className="font-medium text-lg">Create Answer Option Type ({getAMLEndpointTitle()})</h2>
            </div>
            <div className={`p-4`}>
              <div>
                <FieldSet
                  error={formik.errors.name}
                  label="Name"
                  name="name"
                  placeholder="Answer Option Type name..."
                />
              </div>
              <div className="mt-4">
                <FieldSet
                  error={formik.errors.description}
                  label="Description"
                  name="description"
                  placeholder="Answer Option Type description..."
                />
              </div>
            </div>
            <div className={`${Theme["bg-tertiary"]} p-4 flex items-center justify-between`}>
              <TertiaryButton type="button" onClick={() => props.onClose()}>
                Cancel
              </TertiaryButton>
              <PrimaryButton type="submit">Create</PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateAnswerOptionTypeModal;
