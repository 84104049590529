import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAnswerOptionTypeInterface } from "../../interfaces/AMLAnswerOptionTypeInterface";

type AnswerOptionTypesTableDetailsProps = {
  answerOptionType: AMLAnswerOptionTypeInterface;
};

const AnswerOptionTypesTableDetails = (
  props: AnswerOptionTypesTableDetailsProps
) => (
  <tbody>
    <TableRow>
      <TableData>Description</TableData>
      <TableData>{props.answerOptionType.description}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Answer Option Type Key</TableData>
      <TableData>{props.answerOptionType.key}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Created at</TableData>
      <TableData>
        {new Date(props.answerOptionType.created_at).toLocaleString()}
      </TableData>
    </TableRow>
    <TableRow>
      <TableData>Updated at</TableData>
      <TableData>
        {new Date(props.answerOptionType.updated_at).toLocaleString()}
      </TableData>
    </TableRow>
  </tbody>
);

export default AnswerOptionTypesTableDetails;
