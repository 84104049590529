import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface TableProps extends React.ComponentPropsWithoutRef<"table"> {
  children: React.ReactNode;
  className?: string;
}

const Table = (props: TableProps) => {
  const { Theme } = useTheme();

  return (
    <div
      {...props}
      className={twMerge(Theme["table:container"], props.className)}
    >
      <table className={twMerge(Theme["table"], props.className)}>{props.children}</table>
    </div>
  );
};

export default Table;
