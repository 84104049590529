/**
 * * Display details for the Textarea Answer Option
 */
const TextAreaDetails = () => (
  <p className="text-center font-medium text-base mt-4 mb-2">
    Selected Answer Type: TextArea
  </p>
);

export default TextAreaDetails;
