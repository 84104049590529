import Table from "components/UI/Table/Table";
import { ServicesIndexInterface } from "../../interfaces/ServicesIndexInterface";
import ServicesIndexTableRow from "../ServicesIndexTableRow/ServicesIndexTableRow";

type ServicesIndexTableProps = {
  services: Array<ServicesIndexInterface>;
};

const ServicesIndexTable = (props: ServicesIndexTableProps) => (
  <Table>
    <tbody>
      {props.services.map((service) => (
        <ServicesIndexTableRow service={service} key={service.name} />
      ))}
    </tbody>
  </Table>
);

export default ServicesIndexTable;
