import { Route } from "react-router-dom";

import AppLogShow from "./App/AppLogs/containers/AppLogsIndex/AppLogsIndex";
import AppLogsIndex from "./App/AppLogs/containers/AppsIndex/AppsIndex";
import WPRedirect from "./App/AppLogs/containers/WPRedirect/WPRedirect";
import { WORDPRESS_INDEX, WORDPRESS_LOGS_INDEX, WP_REDIRECT } from "./App/routes";
import { TENANTS } from "./routes";

export const TenantsRouter = (
  <Route path={TENANTS}>
    {/* * Apps */}
    <Route path={WORDPRESS_INDEX} element={<AppLogsIndex />} />
    <Route path={WORDPRESS_LOGS_INDEX} element={<AppLogShow />} />
    <Route path={WP_REDIRECT} element={<WPRedirect />} />
  </Route>
);
