import Table from "components/UI/Table/Table";
import { AppsIndexInterface } from "../../interfaces/AppsIndexInterface";
import AppsTableRow from "../AppsTableRow/AppsTableRow";

type AppsTableProps = {
  logs: Array<AppsIndexInterface>;
};

const AppsTable = (props: AppsTableProps) => (
  <Table>
    <tbody>
      {props.logs.map((log) => (
        <AppsTableRow log={log} key={log.id} />
      ))}
    </tbody>
  </Table>
);

export default AppsTable;
