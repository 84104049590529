import { useCallback } from "react";
import { AMLSectionInterface } from "../../interfaces/AMLSectionInterface";
import SectionListItem from "../SectionListItem/SectionListItem";

type SectionsListProps = {
  sections: Array<AMLSectionInterface>;
  handleCreate: () => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const SectionsList = (props: SectionsListProps) => {
  const handleEdit = useCallback((id: string) => props.handleEdit(id), [props]);

  const handleDelete = useCallback(
    (id: string) => props.handleDelete(id),
    [props]
  );

  return (
    <>
      {props.sections.map((section) => (
        <SectionListItem
          section={section}
          key={section.id}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
        />
      ))}
    </>
  );
};

export default SectionsList;
