/**
 * * Hook interface to ThemeStore context
 * * Reduces need for rewriting context imports each time its required
 * * And includes checking that the component is within a ThemeStoreProvider
 */

import { useContext } from "react";
import ThemeStoreContext, {
  ThemeStoreContextInterface,
} from "./ThemeStoreContext";

const useTheme = () => {
  const context = useContext<ThemeStoreContextInterface>(ThemeStoreContext);

  // * Add some error logging
  if (context === undefined) {
    console.error(
      "[useTheme] Context not provided. Ensure your component is a child of <ThemeStoreProvider />"
    );
  }

  return context;
};

export default useTheme;
