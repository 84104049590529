import { IOptions } from "components/UI/Inputs/SelectField";
import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { useUpdateCheckService } from "../../services/useUpdateCheckService/useUpdateCheckService";
import { EditableSelectField } from "../EditableCheckFields/EditableSelectField";

interface IProps {
  readonly content?: React.ReactNode;
  readonly value: string;
  readonly name: string;
  readonly keyName: string;
  readonly checkId: string;
  readonly options: IOptions;
  readonly handleOnUpdate: (value: string) => void;
}

export const EditableSelectCheckRow = ({ content, value, keyName, name, checkId, options, handleOnUpdate }: IProps) => {
  const { IsLoading, RequestError, updateCheck } = useUpdateCheckService();

  const onUpdate = (newValue: string) => {
    updateCheck(
      {
        checkId,
        keyName,
        value: newValue,
      },
      () => {
        handleOnUpdate(newValue);
      },
    );
  };

  return (
    <TableRow>
      <TableData>{name}:</TableData>
      <TableData>
        <EditableSelectField
          content={content}
          value={value}
          onConfirm={onUpdate}
          isLoading={IsLoading}
          options={options}
        />
        {RequestError && (
          <div className="mt-2">
            <NegativeMessage content={RequestError.message} />
          </div>
        )}
      </TableData>
    </TableRow>
  );
};
