// * log/app level routes

import { TENANTS } from "../../Tenants/routes";

export const WORDPRESS = `${TENANTS}/wp`;
export const WORDPRESS_INDEX = `${WORDPRESS}/all`;

export const WORDPRESS_LOGS_INDEX = `${WORDPRESS}/show/:id`;

export const WP_REDIRECT = `${TENANTS}/apps/wp/:id`;
