import CodeBlock from "components/UI/CodeBlock/CodeBlock";

type ServiceLogDetailProps = {
  title: string;
  children: React.ReactNode;
};

const ServiceLogContent = (props: ServiceLogDetailProps) => (
  <div className="mt-6 first-of-type:mt-0">
    <h2 className="font-medium text-lg mb-2 text-left">{props.title}</h2>
    <CodeBlock>{props.children}</CodeBlock>
  </div>
);

export default ServiceLogContent;
