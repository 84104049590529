import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import AnswerOptionTypesTable from "../../components/AnswerOptionTypesTable/AnswerOptionTypesTable";
import CreateAssessmentModal from "../../components/CreateAnswerOptionTypeModal/CreateAnswerOptionTypeModal";
import DeleteAssessmentModal from "../../components/DeleteAnswerOptionTypeModal/DeleteAnswerOptionTypeModal";
import EditAssessmentModal from "../../components/EditAnswerOptionTypeModal/EditAnswerOptionTypeModal";
import { AMLAnswerOptionTypeInterface } from "../../interfaces/AMLAnswerOptionTypeInterface";
import { useRequestAMLAnswerOptionTypes } from "../../services/useRequestAMLAnswerOptionTypes/useRequestAMLAnswerOptionTypes";

const AnswerOptionTypesIndex = () => {
  const {
    AnswerOptionTypes,
    getAnswerOptionTypesIndex,
    postAnswerOptionType,
    patchAnswerOptionType,
    deleteAnswerOptionType,
    RequestError,
    IsLoading,
  } = useRequestAMLAnswerOptionTypes();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  /**
   * * Current context for any assessment being created / edited / deleted
   */
  const [EditAssessmentContext, setEditAssessmentContext] = useState<
    | {
        editContext: "create";
        answerOptionType: null;
      }
    | {
        editContext: "edit" | "delete";
        answerOptionType: AMLAnswerOptionTypeInterface;
      }
    | null
  >(null);

  /**
   * * On Mount, request answerOptionTypes
   */
  useMountEffect(() => getAnswerOptionTypesIndex());

  /**
   * * Handle creating
   */
  const handleCreate = () => {
    setEditAssessmentContext({
      editContext: "create",
      answerOptionType: null,
    });
  };

  /**
   * * On confirm create
   */
  const handleConfirmCreate = (values: { name: string; description: string }) => {
    postAnswerOptionType({
      name: values.name,
      description: values.description,
    });
    clearEditAssessmentContext();
  };

  /**
   * * Handle editing
   */
  const handleEdit = (id: string) => {
    if (!AnswerOptionTypes) return;
    const assessment = AnswerOptionTypes.find((a) => a.id === id);

    if (assessment) {
      setEditAssessmentContext({
        editContext: "edit",
        answerOptionType: assessment,
      });
    }
  };

  /**
   * * On confirm edit
   */
  const handleConfirmEdit = (values: { id: string; name: string; description: string }) => {
    patchAnswerOptionType(values.id, {
      name: values.name,
      description: values.description,
    });
    clearEditAssessmentContext();
  };

  /**
   * * Handle deleting
   */
  const handleDelete = (id: string) => {
    if (!AnswerOptionTypes) return;
    const assessment = AnswerOptionTypes.find((a) => a.id === id);

    if (assessment) {
      setEditAssessmentContext({
        editContext: "delete",
        answerOptionType: assessment,
      });
    }
  };

  /**
   * * on confirm deletion
   */
  const handleConfirmDelete = (id: string) => {
    deleteAnswerOptionType(id);
    clearEditAssessmentContext();
  };

  /**
   * * Clear edit assessment context
   */
  const clearEditAssessmentContext = () => setEditAssessmentContext(null);

  return (
    <Page
      pageTitle={
        <span>
          AML Answer Option Types{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      {AnswerOptionTypes && (
        <>
          <div className="flex justify-end">
            <PrimaryButton onClick={() => handleCreate()} title="Create Assessment type" icon={<PlusCircleIcon />}>
              New
            </PrimaryButton>
          </div>
          {AnswerOptionTypes.map((answerOptionType) => (
            <AnswerOptionTypesTable
              key={answerOptionType.id}
              answerOptionType={answerOptionType}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
          {EditAssessmentContext && (
            <>
              {EditAssessmentContext.editContext === "create" && (
                <CreateAssessmentModal
                  answerOptionType={null}
                  onClose={() => clearEditAssessmentContext()}
                  onSubmit={handleConfirmCreate}
                />
              )}
              {EditAssessmentContext.editContext === "edit" && (
                <EditAssessmentModal
                  answerOptionType={EditAssessmentContext.answerOptionType}
                  onClose={() => clearEditAssessmentContext()}
                  onSubmit={handleConfirmEdit}
                />
              )}
              {EditAssessmentContext.editContext === "delete" && (
                <DeleteAssessmentModal
                  answerOptionType={EditAssessmentContext.answerOptionType}
                  onClose={() => clearEditAssessmentContext()}
                  onDelete={handleConfirmDelete}
                />
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default AnswerOptionTypesIndex;
