/* eslint-disable @typescript-eslint/ban-ts-comment */ // TODO: Refactor this file
import { MinusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { AMLAnswerOptionFieldInterface } from "../../../interfaces/AMLAnswerOptionFieldInterface";
import AnswerOptionItem from "../../AnswerOptions/AnswerOptionItem/AnswerOptionItem";
import { LocalAMLAnswerOptionFieldInterface } from "../AnswerOptionFieldsForm/AnswerOptionFieldsForm";

type AnswerOptionFieldsListItemProps = {
  answerOptionField: AMLAnswerOptionFieldInterface | LocalAMLAnswerOptionFieldInterface;
  handleCreate: (id: string, answer_option_id: string) => void;
  handleDelete: (id: string, local_id?: string) => void;
  handleEdit: (values: {
    id: string;
    local_id?: string;
    name: string;
    description: string;
    display_order: number;
  }) => void;
};

const AnswerOptionFieldsListItem = (props: AnswerOptionFieldsListItemProps) => {
  const [ShowDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const handleOnAnswerOptionCreate = useCallback(
    (answer_option_id: string) => {
      props.handleCreate(props.answerOptionField.id, answer_option_id);
    },
    [props],
  );

  const handleDelete = () => setShowDeleteConfirmation(true);

  const handleConfirmDelete = useCallback(() => {
    if ("local_id" in props.answerOptionField) {
      props.handleDelete(props.answerOptionField.id, props.answerOptionField.local_id as string);
    } else {
      props.handleDelete(props.answerOptionField.id);
    }
  }, [props]);

  const handleEdit = useCallback(
    (values: { name: string; description: string; display_order: string }) => {
      const updateConfig = {
        id: props.answerOptionField.id,
        ...values,
        display_order: parseInt(values.display_order),
      };

      if ("local_id" in props.answerOptionField && props.answerOptionField.local_id) {
        //@ts-ignore
        updateConfig["local_id"] = props.answerOptionField.local_id;
      }

      props.handleEdit(updateConfig);
    },
    [props],
  );

  const { name, description, display_order } = props.answerOptionField;

  return (
    <div className={`mt-2 first-of-type:mt-0 border border-neutral-500 p-2 rounded-md`}>
      {!ShowDeleteConfirmation ? (
        <>
          <div className="flex justify-end mb-2">
            <span onClick={handleDelete}>
              <MinusCircleIcon className="h-5 w-5 inline-block cursor-pointer hover:opacity-80" />
            </span>
          </div>
          <Formik
            initialValues={{
              name: name ?? "",
              description: description ?? "",
              display_order: typeof display_order === "number" ? display_order.toString() : display_order,
            }}
            onSubmit={handleEdit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} className="flex items-start">
                <div className="w-full">
                  <div className="mb-2">
                    <FieldSet
                      name="name"
                      label="Field Name"
                      placeholder="Answer option field  name..."
                      error={formik.errors.name}
                      layoutHorizontal
                      showLabel
                    />
                  </div>
                  <div className="mb-2">
                    <FieldSet
                      name="description"
                      label="Field Description"
                      placeholder="Answer option field  description..."
                      error={formik.errors.name}
                      layoutHorizontal
                      showLabel
                    />
                  </div>
                  <div className="mb-2">
                    <FieldSet
                      name="display_order"
                      label="Display order (override)"
                      placeholder="Order position number..."
                      error={formik.errors.display_order}
                      layoutHorizontal
                      showLabel
                      type="number"
                      inputConfig={{
                        step: "1",
                        min: "0",
                      }}
                    />
                  </div>
                </div>
                <div className="ml-2">
                  <TertiaryButton type="submit">Update</TertiaryButton>
                </div>
              </form>
            )}
          </Formik>
          <AnswerOptionItem
            handleOnAnswerOptionCreate={handleOnAnswerOptionCreate}
            //@ts-ignore
            answer_option_id={
              "answer_option_id" in props.answerOptionField ? props.answerOptionField.answer_option_id : null
            }
          />
        </>
      ) : (
        <div className="p-10">
          <p className="text-base text-center mb-2">Are you sure you wish to delete this Field?</p>
          <div className="flex justify-center items-center">
            <PrimaryButton onClick={() => handleConfirmDelete()}>Delete</PrimaryButton>
            <TertiaryButton className="ml-2" onClick={() => setShowDeleteConfirmation(false)}>
              Cancel
            </TertiaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerOptionFieldsListItem;
