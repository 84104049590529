import { ArrowCircleDownIcon, ArrowCircleLeftIcon } from "@heroicons/react/outline";
import { NavigationItemType } from "modules/NavigationTree";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";
import AppNavigationSubList from "../AppNavigationSubList/AppNavigationSubList";

type AppNavigationBranchProps = {
  condensed?: boolean;
  toggleCondense: () => void;
  navigationItem: NavigationItemType;
};

const AppNavigationBranch = (props: AppNavigationBranchProps) => {
  /**
   * * Get location to highlight current view nav item
   */
  const location = useLocation();

  const { Theme } = useTheme();

  /**
   * * Collapse nav
   */
  const [CollapseSubList, setCollapseSubList] = useState<boolean>(false);

  const { navigationItem } = props;

  const handleCollapsingSubList = useCallback(() => {
    // * if currently condensed
    if (props.condensed) {
      // * toggle opening
      props.toggleCondense();
      // * and if collapsed, open up
      if (CollapseSubList) {
        setCollapseSubList(!CollapseSubList);
      }
    } else {
      // * otherwise, just toggle collapse
      setCollapseSubList(!CollapseSubList);
    }
  }, [CollapseSubList, props]);

  return (
    <div className={Theme["appnavigation:branch"]}>
      <h2
        onClick={handleCollapsingSubList}
        className={`${Theme["appnavigation:branch:title"]} ${
          props.condensed && navigationItem.urlPath && location.pathname.includes(navigationItem.urlPath)
            ? Theme["appnavigation:branch:title:active"]
            : ""
        }`}
      >
        <span>
          {navigationItem.icon ? <>{navigationItem.icon} </> : null}
          <span className={`${props.condensed ? "hidden" : ""}`}>{navigationItem.name}</span>
        </span>
        {!props.condensed && (
          <span>
            <span>
              {CollapseSubList ? (
                <ArrowCircleLeftIcon className={Theme["icon"]} />
              ) : (
                <ArrowCircleDownIcon className={Theme["icon"]} />
              )}
            </span>
          </span>
        )}
      </h2>
      {!props.condensed && navigationItem.children && navigationItem.children.length > 0 ? (
        <AppNavigationSubList collapse={CollapseSubList} subList={navigationItem.children} />
      ) : null}
    </div>
  );
};

export default AppNavigationBranch;
