import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLEntityTypeInterface } from "../../interfaces/AMLEntityTypeInterface";

type EntityTypesTableDetailsProps = {
  entityType: AMLEntityTypeInterface;
};

const EntityTypesTableDetails = (props: EntityTypesTableDetailsProps) => (
  <tbody>
    <TableRow>
      <TableData>Description</TableData>
      <TableData>{props.entityType.description}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Entity Type Key</TableData>
      <TableData>{props.entityType.key}</TableData>
    </TableRow>
    <TableRow>
      <TableData>Created at</TableData>
      <TableData>
        {new Date(props.entityType.created_at).toUTCString()}
      </TableData>
    </TableRow>
    <TableRow>
      <TableData>Updated at</TableData>
      <TableData>
        {new Date(props.entityType.updated_at).toUTCString()}
      </TableData>
    </TableRow>
  </tbody>
);

export default EntityTypesTableDetails;
