import Table from "components/UI/Table/Table";
import { ITenants } from "../../interfaces/ITenant";
import { TenantsTableRow } from "../TenantsTableRow/TenantsTableRow";

interface IProps {
  readonly tenants: ITenants;
}

export const TenantsTable = ({ tenants }: IProps) => (
  <Table>
    <tbody>
      {tenants.map((tenant) => (
        <TenantsTableRow tenant={tenant} key={tenant.id} />
      ))}
    </tbody>
  </Table>
);
