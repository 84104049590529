import {
  ArrowCircleDownIcon,
  ArrowCircleLeftIcon,
} from "@heroicons/react/outline";
import { AMLEntityTypeInterface } from "modules/Projects/AML/EntityTypes/interfaces/AMLEntityTypeInterface";
import { useState } from "react";

type TemplatesEntityTypeListProps = {
  entityType: AMLEntityTypeInterface;
  children?: React.ReactNode;
};

const TemplatesEntityTypeList = (props: TemplatesEntityTypeListProps) => {
  const [CollapseSubList, setCollapseSubList] = useState<boolean>(true);

  return (
    <div className="mt-4 first-of-type:mt-0">
      <h2
        className="text-left text-2xl font-medium mb-2 flex justify-between items-center cursor-pointer"
        onClick={() => setCollapseSubList(!CollapseSubList)}
      >
        {`${props.entityType.name} templates`}
        <span>
          {CollapseSubList ? (
            <ArrowCircleLeftIcon className="h-5 w-5 inline-block" />
          ) : (
            <ArrowCircleDownIcon className="h-5 w-5 inline-block" />
          )}
        </span>
      </h2>
      <div className={`ml-5 ${CollapseSubList ? "hidden" : "block"}`}>
        {props.children && props.children}
      </div>
    </div>
  );
};

export default TemplatesEntityTypeList;
