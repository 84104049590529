import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import Table from "components/UI/Table/Table";
import TableData from "components/UI/Table/TableData/TableData";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { useCallback } from "react";
import { AMLQuestionInterface } from "../../interfaces/AMLQuestionInterface";
import QuestionTableDetails from "../QuestionTableDetails/QuestionTableDetails";

type QuestionTableProps = {
  question: AMLQuestionInterface;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const QuestionTable = (props: QuestionTableProps) => {
  const handleEdit = useCallback(
    () => props.handleEdit(props.question.id),
    [props]
  );

  const handleDelete = useCallback(
    () => props.handleDelete(props.question.id),
    [props]
  );

  return (
    <div className="mt-2 first-of-type:mt-0">
      <Table>
        <thead>
          <TableRow>
            <TableHeading>
              {props.question.display_order} | {props.question.ref}
            </TableHeading>
          </TableRow>
        </thead>
        <QuestionTableDetails question={props.question} />
        <tfoot>
          <TableRow>
            <TableData colSpan={2}>
              <div className="flex justify-end">
                <PrimaryButton onClick={handleEdit} className="mr-2">
                  Edit
                </PrimaryButton>
                <TertiaryButton onClick={handleDelete}>Delete</TertiaryButton>
              </div>
            </TableData>
          </TableRow>
        </tfoot>
      </Table>
    </div>
  );
};

export default QuestionTable;
