import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import { produce } from "immer";
import { useCallback, useState } from "react";
import AnswerOptionTypeSelector from "./AnswerOptionTypeSelector/AnswerOptionTypeSelector";
import { AMLAnswerOptionInterface } from "../../../interfaces/AMLAnswerOptionInterface";
import AnswerOptionConfig from "../AnswerOptionConfig/AnswerOptionConfig";
import { createEmptyRuleset, setDefaultRulesetValues } from "../utilities/rulesetUtilities";

type AnswerOptionEditProps = {
  answerOption: AMLAnswerOptionInterface | null;
  handleCreate: (answerOption: AMLAnswerOptionInterface) => void;
  handleEdit: (answerOption: AMLAnswerOptionInterface) => void;
};

const AnswerOptionEdit = (props: AnswerOptionEditProps) => {
  const [CurrentAnswerOptionConfig, setCurrentAnswerOptionConfig] = useState<AMLAnswerOptionInterface>(() => {
    if (props.answerOption) {
      const answerOptions = JSON.parse(JSON.stringify(props.answerOption)) as AMLAnswerOptionInterface;

      // * Process the ruleset
      answerOptions.rules = setDefaultRulesetValues(answerOptions.rules);

      if (!("options" in answerOptions) || !answerOptions.options) {
        answerOptions.options = {};
      }

      return {
        ...answerOptions,
      };
    } else {
      return {
        id: "",
        answer_option_type_id: "",
        default_answer: [],
        options: {},
        rules: createEmptyRuleset(),
        created_at: "",
        updated_at: "",
        deleted_at: "",
      };
    }
  });

  const handleAnswerOptionTypeUpdate = (id: string) => {
    setCurrentAnswerOptionConfig((prev) =>
      produce(prev, (draft) => {
        draft.answer_option_type_id = id;
        // * Reset all options when changing type
        draft.default_answer = [];
        draft.options = {};
        draft.rules = createEmptyRuleset();
      }),
    );
  };

  const handleAnswerOptionUpdate = (answerOption: AMLAnswerOptionInterface) => {
    setCurrentAnswerOptionConfig((prev) =>
      produce(prev, (draft) => {
        return {
          ...draft,
          ...answerOption,
        };
      }),
    );
  };

  const handleSave = useCallback(() => {
    if (CurrentAnswerOptionConfig.id) {
      props.handleEdit({ ...CurrentAnswerOptionConfig });
    } else {
      props.handleCreate({ ...CurrentAnswerOptionConfig });
    }
  }, [CurrentAnswerOptionConfig, props]);

  return (
    <div className="mt-2 border border-neutral-500 p-2 rounded-md">
      <AnswerOptionTypeSelector
        handleSelectAnswerOptionType={handleAnswerOptionTypeUpdate}
        currentSelection={CurrentAnswerOptionConfig.answer_option_type_id}
      />
      <AnswerOptionConfig answerOption={CurrentAnswerOptionConfig} handleAnswerChange={handleAnswerOptionUpdate} />
      {CurrentAnswerOptionConfig.answer_option_type_id && (
        <div className="mt-2 flex justify-center items-center">
          <PrimaryButton onClick={handleSave}>Save</PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default AnswerOptionEdit;
