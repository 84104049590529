export const GET_QUESTIONS = `/config-questions`;
export const GET_QUESTION = `${GET_QUESTIONS}/:id`;
export const POST_QUESTION = `${GET_QUESTIONS}`;
export const PATCH_QUESTION = `${GET_QUESTIONS}/:id`;
export const DELETE_QUESTION = `${GET_QUESTIONS}/:id`;

// * Answer Option Field Groups
export const GET_ANSWER_OPTION_FIELD_GROUPS = `/config-answer-option-field-groups`;
export const GET_ANSWER_OPTION_FIELD_GROUP = `${GET_ANSWER_OPTION_FIELD_GROUPS}/:id`;
export const POST_ANSWER_OPTION_FIELD_GROUP = `${GET_ANSWER_OPTION_FIELD_GROUPS}`;
export const PATCH_ANSWER_OPTION_FIELD_GROUP = `${GET_ANSWER_OPTION_FIELD_GROUPS}/:id`;
export const DELETE_ANSWER_OPTION_FIELD_GROUP = `${GET_ANSWER_OPTION_FIELD_GROUPS}/:id`;

// * Answer Option Fields
export const GET_ANSWER_OPTION_FIELDS = `/config-answer-option-fields`;
export const GET_ANSWER_OPTION_FIELD = `${GET_ANSWER_OPTION_FIELDS}/:id`;
export const POST_ANSWER_OPTION_FIELD = `${GET_ANSWER_OPTION_FIELDS}`;
export const PATCH_ANSWER_OPTION_FIELD = `${GET_ANSWER_OPTION_FIELDS}/:id`;
export const DELETE_ANSWER_OPTION_FIELD = `${GET_ANSWER_OPTION_FIELDS}/:id`;

// * Answer Options
export const GET_ANSWER_OPTIONS = `/config-answer-options`;
export const GET_ANSWER_OPTION = `${GET_ANSWER_OPTIONS}/:id`;
export const POST_ANSWER_OPTION = `${GET_ANSWER_OPTIONS}`;
export const PATCH_ANSWER_OPTION = `${GET_ANSWER_OPTIONS}/:id`;
export const DELETE_ANSWER_OPTION = `${GET_ANSWER_OPTIONS}/:id`;
