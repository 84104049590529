import { AxiosError } from "axios";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLChecksEndpoints } from "../../endpoints";
import { ICheck, IChecks } from "../../interfaces/ICheck";

interface IUseRequestAMLChecks {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly getAMLChecks: (
    tenant_id: string,
    assessment_group_id: string,
    cb: (currentCheck: ICheck, pastChecks: IChecks) => void,
  ) => void;
  readonly searchAMLChecks: (ref: string, cb: (check: ICheck) => void) => void;
}

type ISearchCheckResponse = { data: ICheck };

export const useRequestAMLChecks = (): IUseRequestAMLChecks => {
  const { IsLoading, RequestError, sendGetRequest } = useRequestAMLService<ISearchCheckResponse | IChecks>();

  const onAMLChecksResponse = (checks: IChecks, cb: (currentCheck: ICheck, pastChecks: IChecks) => void) => {
    if (checks.length) {
      const sortedChecks = [...checks].sort((checkA, checkB) => {
        const refAMatch = checkA.ref.match(/([0-9])\w+/g);
        const refBMatch = checkB.ref.match(/([0-9])\w+/g);
        if (refAMatch && refAMatch[0] && refBMatch && refBMatch[0]) {
          const refA = parseInt(refAMatch[0]);
          const refB = parseInt(refBMatch[0]);

          if (refA < refB) return 1;
          if (refA > refB) return -1;
          else return 0;
        }
        // * not matched ref, default to last
        return -1;
      });

      cb(sortedChecks[0], sortedChecks.slice(1));
    }
  };

  const getAMLChecks = useCallback(
    (tenant_id: string, assessment_group_id: string, cb: (currentCheck: ICheck, pastChecks: IChecks) => void) => {
      sendGetRequest(
        generateCompleteURL(
          AMLChecksEndpoints.index.get,
          {
            assessment_group_id,
          },
          {},
        ),
        (data) => onAMLChecksResponse(data as IChecks, cb),
        {
          headers: {
            tenant_id,
          },
        },
      );
    },
    [sendGetRequest],
  );

  const searchAMLChecks = useCallback(
    (ref: string, cb: (check: ICheck) => void) => {
      sendGetRequest(generateCompleteURL(AMLChecksEndpoints.check.search, { ref }, {}), (data) =>
        cb((data as ISearchCheckResponse).data),
      );
    },
    [sendGetRequest],
  );

  return {
    IsLoading,
    RequestError,
    getAMLChecks,
    searchAMLChecks,
  };
};
