import CodeBlock from "components/UI/CodeBlock/CodeBlock";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AppLogsIndexInterface } from "modules/Tenants/App/AppLogs/interfaces/AppLogsIndexInterface";
import { useState } from "react";

type AppLogsTableRowProps = {
  log: AppLogsIndexInterface;
};

const AppLogsTableRow = (props: AppLogsTableRowProps) => {
  const [ShowDebug, setShowDebug] = useState<boolean>(false);

  const { log } = props;

  return (
    <TableRow
      className={`${
        log.is_error ? "bg-negative-600 hover:bg-negative-500 text-white" : ""
      }`}
    >
      <TableData>
        <div className="flex justify-start items-center">
          <div className="mr-2">
            <p className="text-center">
              {new Date(Date.parse(log.created_at)).toLocaleDateString("en-GB")}
              <br />
              <span className="font-normal">
                {new Date(Date.parse(log.created_at)).toLocaleTimeString(
                  "en-GB"
                )}
              </span>
            </p>
          </div>
          <div>
            <p className="text-base">{log.function}</p>
            <p className="font-normal">{log.description}</p>
          </div>
        </div>
        {ShowDebug && log.data ? (
          <CodeBlock>{JSON.stringify(JSON.parse(log.data), null, 4)}</CodeBlock>
        ) : null}
      </TableData>
      <TableData className="w-24 align-top">
        {log.data && (
          <p
            className={`cursor-pointer text-center text-base mt-2 ${
              log.is_error
                ? "text-white"
                : "text-positive-500 hover:text-positive-600"
            }`}
            onClick={() => setShowDebug(!ShowDebug)}
          >
            Debug
          </p>
        )}
      </TableData>
    </TableRow>
  );
};

export default AppLogsTableRow;
