/* eslint-disable @typescript-eslint/no-unsafe-argument */
import FieldSet from "components/UI/FieldSet/FieldSet";
import { Formik } from "formik";
import React, { useCallback, useContext } from "react";
import { AnswerOptionTypesContext } from "../../../EditQuestionModal/EditQuestionModal";

type AnswerOptionTypeSelectorProps = {
  handleSelectAnswerOptionType: (id: string) => void;
  currentSelection: string;
};

const AnswerOptionTypeSelector = (props: AnswerOptionTypeSelectorProps) => {
  const { answerOptionTypes } = useContext(AnswerOptionTypesContext);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      props.handleSelectAnswerOptionType(event.target.value);
    },
    [props],
  );

  return (
    <Formik
      initialValues={{
        answerOptionType: props.currentSelection,
      }}
      onSubmit={() => undefined}
    >
      {(formik) => (
        <form onSubmit={formik.handleSubmit} onChange={handleOnChange}>
          <FieldSet
            error={formik.errors.answerOptionType}
            name="answerOptionType"
            label="Answer Option Type"
            layoutHorizontal
            showLabel
            type="select"
            options={[
              {
                content: "Select an answer option type",
                value: "",
                config: {
                  disabled: true,
                },
              },
              ...answerOptionTypes.map((answerOptionType) => ({
                content: answerOptionType.name,
                value: answerOptionType.id,
              })),
            ]}
          />
        </form>
      )}
    </Formik>
  );
};

export default AnswerOptionTypeSelector;
