import { PropsWithChildren, useCallback, useMemo, useState } from "react";
import SettingsEnvModal from "./components/SettingsEnvModal";
import SettingsEndpointContext, { SettingsEnvContextInterface } from "./SettingsEnvContext";
import { SettingsEnvType } from "../../interfaces/SettingsEnvType";

const { Provider } = SettingsEndpointContext;

export const SettingsEnvProvider = ({ children }: PropsWithChildren) => {
  const [selectedSettingsEnv, setSelectedSettingsEnv] = useState<SettingsEnvType>();
  const [bearerToken, setBearerToken] = useState<string>();

  const clearSettingsEnv = useCallback(() => setSelectedSettingsEnv(undefined), []);

  const setSettingsEnv = useCallback((env: SettingsEnvType) => setSelectedSettingsEnv(env), []);

  const setToken = useCallback((token: string) => setBearerToken(token), []);

  const contextValue: SettingsEnvContextInterface = useMemo(() => {
    return {
      selectedSettingsEnv,
      bearerToken,
      setToken,
      clearSettingsEnv,
      setSettingsEnv,
    };
  }, [selectedSettingsEnv, bearerToken, setToken, clearSettingsEnv, setSettingsEnv]);

  return <Provider value={contextValue}>{selectedSettingsEnv ? children : <SettingsEnvModal />}</Provider>;
};
