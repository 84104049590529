import Page from "components/UI/Page/Page";
import PaginationNav from "components/UI/PaginationNav/PaginationNav";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { useStateWithCallback } from "hooks/useStateWithCallback/useStateWithCallback";
import { produce } from "immer";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TenantsTable } from "../../components/TenantsTable/TenantsTable";
import { TenantsTableTopNav } from "../../components/TenantsTableTopNav/TenantsTableTopNav";
import { IGetAMLTenantsIndexParams } from "../../interfaces/IGetAMLTenantsIndexParams";
import { ITenants } from "../../interfaces/ITenant";
import { IPaginationMeta, useRequestTenantsIndex } from "../../services/useRequestTenantsIndex/useRequestTenantsIndex";

const defaultParams: IGetAMLTenantsIndexParams = {
  page: 1,
  per_page: 25,
  q: "",
};

export const RiskAssessmentTenantIndex = () => {
  const [tenants, setTenants] = useState<ITenants>([]);
  const [pagination, setPagination] = useState<IPaginationMeta | null>(null);

  const { getTenantsIndex, RequestError, IsLoading } = useRequestTenantsIndex();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentParams, setCurrentParams] = useStateWithCallback<IGetAMLTenantsIndexParams>(() => {
    const page = searchParams.get("page");
    const per_page = searchParams.get("per_page");
    const q = searchParams.get("q");

    return {
      ...defaultParams,
      ...(page ? { page: parseInt(page) } : {}),
      ...(per_page ? { per_page: parseInt(per_page) } : {}),
      ...(q ? { q } : {}),
    };
  });

  const handleGoToPage = (page: number) => {
    setCurrentParams(
      (params) =>
        produce(params, (draft) => {
          draft.page = page;
        }),
      (update) => {
        getTenantsIndex(
          {
            ...update,
          },
          (data, meta) => {
            setTenants(data);
            setPagination(meta);
          },
        );
        setSearchParams({ page: update.page.toString() });
      },
    );
  };

  const handleQuerySearch = (query: string) => {
    setCurrentParams(
      (params) =>
        produce(params, (draft) => {
          draft.page = 1;
          draft.q = query;
        }),
      (update) => {
        getTenantsIndex(
          {
            ...update,
          },
          (data, meta) => {
            setTenants(data);
            setPagination(meta);
          },
        );
        if (update.q?.length) {
          setSearchParams({ q: update.q });
        } else {
          const params = searchParams;
          params.delete("q");
          setSearchParams(params);
        }
      },
    );
  };

  useMountEffect(() =>
    getTenantsIndex(
      {
        ...currentParams,
      },
      (data, meta) => {
        setTenants(data);
        setPagination(meta);
      },
    ),
  );

  return (
    <Page
      pageTitle={
        <span>
          Tenants{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      <TenantsTableTopNav
        total={pagination?.total ?? 0}
        currentQuery={currentParams.q ?? ""}
        handleQuerySearch={handleQuerySearch}
      />
      <TenantsTable tenants={tenants} />
      <PaginationNav
        handleGoToPage={handleGoToPage}
        lastPage={pagination?.last_page ?? 0}
        currentPage={pagination?.current_page ?? 0}
      />
    </Page>
  );
};
