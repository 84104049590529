import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback, useState } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { DELETE_QUESTION, GET_QUESTIONS, PATCH_QUESTION, POST_QUESTION } from "../../endpoints";
import { AMLQuestionInterface } from "../../interfaces/AMLQuestionInterface";

type SubmitQuestionType = {
  value: string;
  guidance: string;
  note: string;
  level: string | number;
  allow_multiple: boolean;
  section_id: string;
  is_default: boolean;
  display_order: number;
};

interface useRequestAMLQuestionInterface<T> extends useRequestServiceImplementation<T> {
  Questions: Array<T>;
  getQuestionsIndex: (section_id: string) => void;
  postQuestion: (config: SubmitQuestionType) => void;
  patchQuestion: (
    id: string,
    config: {
      readonly value: string;
      readonly note: string;
      readonly level: string | number;
      readonly guidance: string;
      readonly display_order?: number | string;
      readonly allow_multiple: boolean;
      readonly minor_update: boolean;
    },
  ) => void;
  deleteQuestion: (id: string) => void;
  LastCreatedQuestionId: string;
  clearLastCreatedQuestionId: () => void;
}

export const useRequestAMLQuestions = <T extends AMLQuestionInterface>(): useRequestAMLQuestionInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const [LastCreatedQuestionId, setLastCreatedQuestionId] = useState<string>("");

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get questions
   */
  const handleOnGetQuestionsResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all questions
   */
  const getQuestionsIndex = useCallback(
    (section_id: string) =>
      sendGetRequest(
        generateCompleteURL(
          GET_QUESTIONS,
          {},
          {
            section_id,
          },
        ),
        (response) => handleOnGetQuestionsResponse(response),
        {},
      ),
    [handleOnGetQuestionsResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a question
   */
  const handleOnPostQuestion = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      const id = Array.isArray(data) ? data[0].id : data.id;
      updateResource(data, () => setLastCreatedQuestionId(id));
    },
    [updateResource],
  );

  /**
   * * Post a new question
   */
  const postQuestion = useCallback(
    (config: SubmitQuestionType) => {
      setLastCreatedQuestionId("");

      sendPostRequest(
        generateCompleteURL(POST_QUESTION, {}, {}),
        { ...config },
        (response) => handleOnPostQuestion(response),
        {},
      );
    },
    [handleOnPostQuestion, sendPostRequest],
  );

  /**
   * * Handle on patching a question
   */
  const handleOnPatchQuestion = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a question
   */
  const patchQuestion = useCallback(
    (
      id: string,
      config: {
        value: string;
        note: string;
        level: string | number;
        guidance: string;
        display_order?: number | string;
        allow_multiple: boolean;
        minor_update: boolean;
      },
    ) => {
      setLastCreatedQuestionId("");
      sendPatchRequest(
        generateCompleteURL(PATCH_QUESTION, { id }, {}),
        { ...config },
        (response) => handleOnPatchQuestion(response),
        {},
      );
    },

    [handleOnPatchQuestion, sendPatchRequest],
  );

  /**
   * * Handle on deleting a question
   */
  const handleOnDeleteQuestion = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a question
   */
  const deleteQuestion = useCallback(
    (id: string) => {
      setLastCreatedQuestionId("");
      sendDeleteRequest(generateCompleteURL(DELETE_QUESTION, { id }, {}), () => handleOnDeleteQuestion(id), {});
    },
    [handleOnDeleteQuestion, sendDeleteRequest],
  );

  /**
   * * Clear the reference to the last created question id
   */
  const clearLastCreatedQuestionId = () => setLastCreatedQuestionId("");

  return {
    Questions: Resource,
    IsLoading,
    RequestError,
    getQuestionsIndex,
    postQuestion,
    patchQuestion,
    deleteQuestion,
    LastCreatedQuestionId,
    clearLastCreatedQuestionId,
  };
};
