import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import {
  DELETE_ANSWER_OPTION_TYPE,
  GET_ANSWER_OPTION_TYPES,
  PATCH_ANSWER_OPTION_TYPE,
  POST_ANSWER_OPTION_TYPE,
} from "../../endpoints";
import { AMLAnswerOptionTypeInterface } from "../../interfaces/AMLAnswerOptionTypeInterface";

type SubmitAnswerOptionType = {
  name: string;
  description: string;
};

interface useRequestAMLAnswerOptionTypesInterface<T> extends useRequestServiceImplementation<T> {
  AnswerOptionTypes: Array<T>;
  getAnswerOptionTypesIndex: () => void;
  postAnswerOptionType: (config: SubmitAnswerOptionType) => void;
  patchAnswerOptionType: (id: string, config: SubmitAnswerOptionType) => void;
  deleteAnswerOptionType: (id: string) => void;
}

export const useRequestAMLAnswerOptionTypes = <
  T extends AMLAnswerOptionTypeInterface,
>(): useRequestAMLAnswerOptionTypesInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on successful get response
   */
  const handleOnGetResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      createResource(data);
    },
    [createResource],
  );

  const getAnswerOptionTypesIndex = useCallback(
    () => sendGetRequest(generateCompleteURL(GET_ANSWER_OPTION_TYPES, {}, {}), handleOnGetResponse, {}),
    [handleOnGetResponse, sendGetRequest],
  );

  /**
   * * Handle on successful post request
   */

  const handleOnPostResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * handle requesting post
   */
  const postAnswerOptionType = useCallback(
    (config: SubmitAnswerOptionType) =>
      sendPostRequest(
        generateCompleteURL(POST_ANSWER_OPTION_TYPE, {}, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
          key: config.name.toLowerCase().trim().replace(/ /, "-"),
        },
        (response) => handleOnPostResponse(response),
        {},
      ),
    [handleOnPostResponse, sendPostRequest],
  );

  /**
   * * Handle on patch response
   */
  const handleOnPatchResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * handle requesting patch
   */
  const patchAnswerOptionType = useCallback(
    (id: string, config: SubmitAnswerOptionType) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_ANSWER_OPTION_TYPE, { id: id }, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
        },
        handleOnPatchResponse,
        {},
      ),
    [handleOnPatchResponse, sendPatchRequest],
  );

  /**
   * * Handle on delete response
   */
  const handleOnDeleteResponse = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * handle requesting delete
   */
  const deleteAnswerOptionType = useCallback(
    (id: string) =>
      sendDeleteRequest(
        generateCompleteURL(DELETE_ANSWER_OPTION_TYPE, { id: id }, {}),
        () => handleOnDeleteResponse(id),
        {},
      ),
    [handleOnDeleteResponse, sendDeleteRequest],
  );

  return {
    AnswerOptionTypes: Resource,
    IsLoading,
    RequestError,
    getAnswerOptionTypesIndex,
    postAnswerOptionType,
    patchAnswerOptionType,
    deleteAnswerOptionType,
  };
};
