import { createContext } from "react";
import { AMLEndpointEnvironmentType } from "../../../AML/interfaces/AMLEndpointEnvironmentType";

export interface AMLEndpointContextInterface {
  /**
   * * Get the current selected AML Endpoint
   */
  getAMLEndpoint: () => string;

  /**
   * * Get the current endpoint title
   */
  getAMLEndpointTitle: () => string;

  /**
   * * Get the current endpoint names
   */
  getAMLEndpointName: () => null | AMLEndpointEnvironmentType;

  /**
   * * Clear the current endpoint
   */
  clearAMLEndpoint: () => void;

  /**
   * * Select an AML Endpoint to use
   */
  setAMLEndpoint: (endpointEnv: AMLEndpointEnvironmentType, customURL?: string, customBearer?: string) => void;
}

const AMLEndpointContext = createContext<AMLEndpointContextInterface>({} as AMLEndpointContextInterface);

export default AMLEndpointContext;
