export const AMLTenantsAssessmentGroupsEndpoints = {
  index: {
    get: `/assessment-groups/assessment`,
  },
  assessment_groups: {
    index: `/assessment-groups`,
    patch: `/assessment-groups/:assessment_group_id`,
    restore: `/assessment-groups/:assessment_group_id/restore`
  }
};
