import { SearchIcon } from "@heroicons/react/outline";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import { Field, Formik } from "formik";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface IProps {
  readonly handleQuerySearch: (query: string) => void;
  readonly currentQuery: string | null;
}

export const SearchCheckHeader = ({ currentQuery, handleQuerySearch }: IProps) => {
  const { Theme } = useTheme();

  const onQuerySearch = (values: { query: string }) => {
    handleQuerySearch(values.query);
  };

  return (
    <div className="flex items-center justify-start">
      <div className="py-2">
        <Formik
          initialValues={{
            query: "",
          }}
          onSubmit={(values, { resetForm }) => {
            onQuerySearch(values);
            resetForm({ values: { query: "" } });
          }}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit} className="flex items-stretch">
              <Field name="query" className={twMerge(Theme["input"], "w-56")} placeholder="Search apps..." id="query" />
              <TertiaryButton type="submit" title="Search" className="ml-2">
                <SearchIcon className={Theme["icon"]} />
              </TertiaryButton>
            </form>
          )}
        </Formik>
      </div>
      <div className="flex items-center p-2">
        {currentQuery && (
          <>
            <p className="text-sm font-medium mr-2">
              Showing results for <span className="font-semibold text-positive-500">{currentQuery}</span>
            </p>
            <TertiaryButton title="Clear search query" className="ml-2" onClick={() => onQuerySearch({ query: "" })}>
              Clear
            </TertiaryButton>
          </>
        )}
      </div>
    </div>
  );
};
