import { MinusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import { Formik } from "formik";
import { useCallback, useState } from "react";
import { AMLAnswerOptionFieldGroupInterface } from "../../../interfaces/AMLAnswerOptionFieldGroupInterface";
import AnswerOptionFieldsForm from "../../AnswerOptionFields/AnswerOptionFieldsForm/AnswerOptionFieldsForm";

type AnswerOptionFieldGroupsListItemProps = {
  answerOptionFieldGroup: AMLAnswerOptionFieldGroupInterface;
  displayAdditionalConfig: boolean;
  handleDelete: (id: string) => void;
  handleEdit: (values: {
    id: string;
    name: string;
    description: string;
    display_order: number;
  }) => void;
};

const AnswerOptionFieldGroupsListItem = (
  props: AnswerOptionFieldGroupsListItemProps
) => {
  const [ShowDeleteConfirmation, setShowDeleteConfirmation] =
    useState<boolean>(false);

  const handleDelete = () => setShowDeleteConfirmation(true);

  const handleConfirmDelete = useCallback(
    () => props.handleDelete(props.answerOptionFieldGroup.id),
    [props]
  );

  const handleEdit = useCallback(
    (values: { name: string; description: string; display_order: string }) =>
      props.handleEdit({
        id: props.answerOptionFieldGroup.id,
        ...values,
        display_order: parseInt(values.display_order),
      }),
    [props]
  );

  const { name, description, display_order } = props.answerOptionFieldGroup;

  return (
    <div
      className={`mt-4 first-of-type:mt-0 border border-neutral-500 p-2 rounded-md`}
    >
      {!ShowDeleteConfirmation ? (
        <>
          <div className="flex justify-end mb-2">
            <span onClick={handleDelete}>
              <MinusCircleIcon className="h-5 w-5 inline-block cursor-pointer hover:opacity-80" />
            </span>
          </div>
          {props.displayAdditionalConfig && (
            <Formik
              initialValues={{
                name: name ?? "",
                description: description ?? "",
                display_order:
                  typeof display_order === "number"
                    ? display_order.toString()
                    : display_order,
              }}
              onSubmit={handleEdit}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  className="flex items-start"
                >
                  <div className="w-full">
                    <div className="mb-2">
                      <FieldSet
                        name="name"
                        label="Group Name"
                        placeholder="Answer option field group name..."
                        error={formik.errors.name}
                        layoutHorizontal
                        showLabel
                      />
                    </div>
                    <div className="mb-2">
                      <FieldSet
                        name="description"
                        label="Group Description"
                        placeholder="Answer option field group description..."
                        error={formik.errors.name}
                        layoutHorizontal
                        showLabel
                      />
                    </div>
                    <div className="mb-2">
                      <FieldSet
                        name="display_order"
                        label="Display order (override)"
                        placeholder="Order position number..."
                        error={formik.errors.display_order}
                        layoutHorizontal
                        showLabel
                        type="number"
                        inputConfig={{
                          step: "1",
                          min: "0",
                        }}
                      />
                    </div>
                  </div>
                  <div className="ml-2">
                    <TertiaryButton type="submit">Update</TertiaryButton>
                  </div>
                </form>
              )}
            </Formik>
          )}
          <div className="mt-2">
            <h2 className="font-medium text-base mb-2 text-center">
              Answer Fields
            </h2>
            <AnswerOptionFieldsForm
              answer_option_field_group_id={props.answerOptionFieldGroup.id}
            />
          </div>
        </>
      ) : (
        <div className="p-10">
          <p className="text-base text-center mb-2">
            Are you sure you wish to delete this group?
          </p>
          <div className="flex justify-center items-center">
            <PrimaryButton onClick={() => handleConfirmDelete()}>
              Delete
            </PrimaryButton>
            <TertiaryButton
              className="ml-2"
              onClick={() => setShowDeleteConfirmation(false)}
            >
              Cancel
            </TertiaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default AnswerOptionFieldGroupsListItem;
