import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";

const RouteNotFound = () => (
  <div>
    <NegativeMessage
      title="Route not found"
      content="That path doesn't exist."
    />
  </div>
);

export default RouteNotFound;
