import { createContext } from "react";

export type ThemeType = "Light" | "Dark";

export interface ThemeStyles {
  "bg-primary": string;
  "bg-secondary": string;
  "bg-tertiary": string;

  "hover:bg-primary": string;
  "hover:bg-secondary": string;
  "hover:bg-tertiary": string;

  "text-color-primary": string;
  "text-color-secondary": string;
  "text-color-tertiary": string;

  "code:bg": string;

  "table:bg": string;
  "table:hover:bg": string;

  "app-header:bg": string;

  "form:input:bg": string;
  "form:input:placeholder-color": string;
  "form:input:text-color": string;
  "form:input:border": string;

  "form:input:focus:outline": string;

  "form:input:error:text-color": string;
  "form:input:error:placeholder-color": string;
  "form:input:error:bg": string;
  "form:input:error:focus:outline": string;
  "form:input:error:border": string;

  "theme-selector:button": string;
}

export interface GeneratedThemeTableType extends ThemeStyles {
  "login:container": string;
  "login:logo:container": string;
  "login:logo": string;

  corevalue: string;

  form: string;
  label: string;
  input: string;
  "input:error": string;
  "input:additional-information": string;
  "form:error": string;

  "table:container": string;
  table: string;
  "table:row": string;
  "table:heading": string;
  "table:data": string;

  "theme-selector:container": string;

  "theme-selector:button:light": string;
  "theme-selector:button:dark": string;

  "message:container": string;
  "message:sidebar": string;
  "message:title": string;
  "message:text": string;

  icon: string;

  "flex:centralise": string;

  "modal:container": string;
  "modal:background": string;
  "modal:inner": string;

  "breadcrumb:link": string;

  page: string;
  "page:title": string;

  "pagination:container": string;
  "pagination:inner": string;
  "pagination:current": string;

  "loader:overlay:container": string;
  "loader:overlay:background": string;
  "loader:overlay:inner": string;

  "codeblock:container": string;
  "codeblock:pre": string;

  "appheader:container": string;

  "appnavigation:container": string;
  "appnavigation:branch": string;
  "appnavigation:branch:title": string;
  "appnavigation:branch:title:active": string;

  "appnavigation:sublist:item": string;
  "appnavigation:sublist:title": string;
  "appnavigation:sublist:link": string;
  "appnavigation:sublist:link:active": string;
}

export type ThemeTableType = {
  [key in ThemeType]: ThemeStyles;
};

export interface ThemeStoreContextInterface {
  /**
   * * Get current theme
   */
  Theme: GeneratedThemeTableType;

  /**
   * * Get active theme
   */
  ActiveTheme: ThemeType;

  /**
   * * Change current theme
   */
  changeTheme: (theme: ThemeType) => void;
}

const ThemeStoreContext = createContext<ThemeStoreContextInterface>({} as ThemeStoreContextInterface);

export default ThemeStoreContext;
