import { NavigationItemType } from "modules/NavigationTree";
import { WORDPRESS, WORDPRESS_INDEX } from "./routes";

export const AppLogsNavigationTree: Array<NavigationItemType> = [
  {
    name: "WordPress",
    urlPath: WORDPRESS,
    children: [
      {
        name: "View All",
        urlPath: WORDPRESS_INDEX,
        isLink: true,
      },
    ],
  },
];
