import { AxiosError } from "axios";
import { toast } from "react-toastify";

/**
 * Handle logging service errors
 * @param {AxiosError} err Error from the request attempt
 * @param {(error: AxiosError) => void} setErrorHandler set Error string
 */
export const handleRequestError = (err: AxiosError, setErrorHandler: (error: AxiosError) => void) => {
  // * catch the error
  if (err.response) {
    const { data } = err.response;
    if (Object.hasOwn(data as object, "message")) {
      const { message } = data as { message: string };
      setErrorHandler(new AxiosError(`${err.response.status}: ${message}`));
    } else {
      setErrorHandler(err);
    }
    console.log(err.response.data);
  } else if (err.request) {
    setErrorHandler(err);
    console.log(err.request);
  } else {
    setErrorHandler(new AxiosError(`Error: ${err.message}`));
  }
  toast.error("Something went wrong: " + err.message);
};
