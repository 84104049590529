import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import {
  DELETE_ANSWER_OPTION_FIELD,
  GET_ANSWER_OPTION_FIELDS,
  PATCH_ANSWER_OPTION_FIELD,
  POST_ANSWER_OPTION_FIELD,
} from "../../endpoints";
import { AMLAnswerOptionFieldInterface } from "../../interfaces/AMLAnswerOptionFieldInterface";

type SubmitAnswerOptionFieldType = {
  answer_option_id: string;
  answer_option_field_group_id: string;
  name: string;
  description: string;
  display_order: number;
};

interface useRequestAMLAnswerOptionFieldInterface<T> extends useRequestServiceImplementation<T> {
  AnswerOptionFields: Array<T>;
  getAnswerOptionFieldsIndex: (answer_option_field_group_id: string) => void;
  postAnswerOptionField: (config: SubmitAnswerOptionFieldType) => void;
  patchAnswerOptionField: (
    id: string,
    config: {
      name: string;
      description: string;
      display_order: number;
    },
  ) => void;
  deleteAnswerOptionField: (id: string) => void;
}

export const useRequestAMLAnswerOptionFields = <
  T extends AMLAnswerOptionFieldInterface,
>(): useRequestAMLAnswerOptionFieldInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get answerOptionFields
   */
  const handleOnGetAnswerOptionFieldsResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all answerOptionFields
   */
  const getAnswerOptionFieldsIndex = useCallback(
    (answer_option_field_group_id: string) =>
      sendGetRequest(
        generateCompleteURL(
          GET_ANSWER_OPTION_FIELDS,
          {},
          {
            answer_option_field_group_id,
          },
        ),
        (response) => handleOnGetAnswerOptionFieldsResponse(response),
        {},
      ),
    [handleOnGetAnswerOptionFieldsResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a answerOptionField
   */
  const handleOnPostAnswerOptionField = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Post a new answerOptionField
   */
  const postAnswerOptionField = useCallback(
    (config: SubmitAnswerOptionFieldType) =>
      sendPostRequest(
        generateCompleteURL(POST_ANSWER_OPTION_FIELD, {}, {}),
        { ...config },
        (response) => handleOnPostAnswerOptionField(response),
        {},
      ),
    [handleOnPostAnswerOptionField, sendPostRequest],
  );

  /**
   * * Handle on patching a answerOptionField
   */
  const handleOnPatchAnswerOptionField = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a answerOptionField
   */
  const patchAnswerOptionField = useCallback(
    (
      id: string,
      config: {
        name: string;
        description: string;
        display_order: number;
      },
    ) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_ANSWER_OPTION_FIELD, { id }, {}),
        { ...config },
        (response) => handleOnPatchAnswerOptionField(response),
        {},
      ),

    [handleOnPatchAnswerOptionField, sendPatchRequest],
  );

  /**
   * * Handle on deleting a answerOptionField
   */
  const handleOnDeleteAnswerOptionField = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a answerOptionField
   */
  const deleteAnswerOptionField = useCallback(
    (id: string) =>
      sendDeleteRequest(
        generateCompleteURL(DELETE_ANSWER_OPTION_FIELD, { id }, {}),
        () => handleOnDeleteAnswerOptionField(id),
        {},
      ),
    [handleOnDeleteAnswerOptionField, sendDeleteRequest],
  );

  return {
    AnswerOptionFields: Resource,
    IsLoading,
    RequestError,
    getAnswerOptionFieldsIndex,
    postAnswerOptionField,
    patchAnswerOptionField,
    deleteAnswerOptionField,
  };
};
