import { NavigationItemType } from "modules/NavigationTree";
import AppNavigationSubListItem from "../AppNavigationSubListItem/AppNavigationSubListItem";

type AppNavigationSubListProps = {
  readonly subList: NavigationItemType["children"];
  readonly collapse?: boolean;
};

const AppNavigationSubList = (props: AppNavigationSubListProps) =>
  props.subList ? (
    <ul className={`pl-5 mb-1 ${props.collapse ? "hidden" : "block"}`}>
      {props.subList.map((child) => (
        <AppNavigationSubListItem item={child} key={child.name} />
      ))}
    </ul>
  ) : null;

export default AppNavigationSubList;
