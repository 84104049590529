import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_WORDPRESS_LOGS_INDEX } from "../../endpoints";
import { AppsIndexInterface } from "../../interfaces/AppsIndexInterface";

export type GetWPAppShowParamsType = {
  wp_id: string;
};

type getWPAppShow = {
  params: GetWPAppShowParamsType;
};

interface useRequestWPAppShowInterface<T>
  extends useRequestServiceImplementation<T> {
  App: T | null;
  getWPApp: (config: getWPAppShow) => void;
}

export const useRequestWPAppShow = <
  T extends AppsIndexInterface
>(): useRequestWPAppShowInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getWPApp = useCallback(
    (config: getWPAppShow) =>
      sendGetRequest(
        generateCompleteURL(GET_WORDPRESS_LOGS_INDEX, {}, { ...config.params }),
        (data) => setData(data),
        {
          bearer: getAccessToken() as string,
        }
      ),
    [getAccessToken, sendGetRequest]
  );

  return {
    App: Data,
    IsLoading,
    RequestError,
    getWPApp,
  };
};
