import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { LOGS_SERVICE_SHOW } from "modules/Logs/Services/routes";
import { Link } from "react-router-dom";
import { ServiceLogsIndexInterface } from "../../interfaces/ServiceLogsIndexInterface";
import ServiceLogLevelBadge from "../ServiceLogLevelBadge/ServiceLogLevelBadge";

type ServiceLogsTableRowProps = {
  log: ServiceLogsIndexInterface;
};

const ServiceLogsTableRow = (props: ServiceLogsTableRowProps) => (
  <TableRow>
    <TableData>
      <ServiceLogLevelBadge level_name={props.log.level_name} />
    </TableData>
    <TableData>
      <Link
        to={LOGS_SERVICE_SHOW.replace(/:id/, props.log.channel).replace(
          /:logId/,
          props.log.id.toString()
        )}
        className="block truncate"
      >
        {props.log.message.slice(0, 100)}
      </Link>
    </TableData>
    <TableData>
      <p className="block text-center font-normal">
        {new Date(props.log.created_at).toUTCString()}
      </p>
    </TableData>
    <TableData>
      <Link
        to={LOGS_SERVICE_SHOW.replace(/:id/, props.log.channel).replace(
          /:logId/,
          props.log.id.toString()
        )}
        className="block text-right"
      >
        <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">
          View Log
        </span>
      </Link>
    </TableData>
  </TableRow>
);

export default ServiceLogsTableRow;
