import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import {Link, useParams} from "react-router-dom";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { RiskAssessmentRoutes } from "../../../routes";
import { IAssessmentGroup } from "../../interfaces/IAssessmentGroup";
import {EditableTextEntityIdRow} from "../EditableTextEntityIdRow/EditableTextEntityIdRow";

interface IProps {
  readonly handleRestore: () => void;
  readonly assessmentGroup: IAssessmentGroup;
}

export const AssessmentGroupsTableRow = ({handleRestore, assessmentGroup }: IProps) => {
  const { tenant_id } = useParams<{ tenant_id: string }>();

  const AssessmentURL = generateCompleteURL(
    RiskAssessmentRoutes.assessments.index,
    {
      tenant_id: tenant_id as string,
      assessment_group_id: assessmentGroup.id,
    },
    {
      trashed: assessmentGroup.is_trashed ? "true" : "false"
    },
  );

  return (
    <TableRow>
      <TableData>
        <Link to={AssessmentURL} className="block">
          {assessmentGroup.name}
        </Link>
      </TableData>
      <EditableTextEntityIdRow
        content={assessmentGroup.entity_id}
        keyName="entity_id"
        assessmentGroupId={assessmentGroup.id}
        tenantId={tenant_id as string}
      />
      <TableData>{assessmentGroup.is_trashed ? <span className="bg-red-500 bold text-white px-1 rounded-sm"> Trashed </span> : <span className="bg-green-600 bold text-white px-1 rounded-sm"> Active </span>}</TableData>
      {assessmentGroup.is_trashed ?
        <TableData className={"bg-red-600 text-white hover:bg-white hover:text-black hover:cursor-pointer"}><a
                      onClick={handleRestore}>Restore</a></TableData>
        :
        <TableData></TableData>
      }

      <TableData>
        <Link to={AssessmentURL} className="block text-right">
          <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">View Assessments</span>
        </Link>
      </TableData>
    </TableRow>
  );
};
