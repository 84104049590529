import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Loader from "components/UI/Loader/Loader";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { determineNextAvailableDisplayOrderValue } from "modules/Projects/AML/utilities/determineNextAvailableDisplayOrderValue/determineNextAvailableDisplayOrderValue";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback } from "react";
import { sortByDisplayOrder } from "utilities/sortByDisplayOrder/sortByDisplayOrder";
import { useRequestAMLAnswerOptionFieldGroups } from "../../../services/useRequestAMLAnswerOptionFieldGroups/useRequestAMLAnswerOptionFieldGroups";
import AnswerOptionFieldGroupsList from "../AnswerOptionFieldGroupsList/AnswerOptionFieldGroupsList";

type AnswerOptionFieldGroupsFormProps = {
  question_id: string;
};

const AnswerOptionFieldGroupsForm = (
  props: AnswerOptionFieldGroupsFormProps
) => {
  const { Theme } = useTheme();

  const {
    AnswerOptionFieldGroups,
    IsLoading,
    RequestError,
    getAnswerOptionFieldGroupsIndex,
    postAnswerOptionFieldGroup,
    patchAnswerOptionFieldGroup,
    deleteAnswerOptionFieldGroup,
  } = useRequestAMLAnswerOptionFieldGroups();

  useMountEffect(() => getAnswerOptionFieldGroupsIndex(props.question_id));

  const handleCreate = useCallback(() => {
    postAnswerOptionFieldGroup({
      name: "",
      description: "",
      display_order: determineNextAvailableDisplayOrderValue(
        AnswerOptionFieldGroups
      ),
      question_id: props.question_id,
    });
  }, [AnswerOptionFieldGroups, postAnswerOptionFieldGroup, props.question_id]);

  const handleEdit = useCallback(
    (values: {
      id: string;
      name: string;
      description: string;
      display_order: number;
    }) =>
      patchAnswerOptionFieldGroup(values.id, {
        name: values.name,
        description: values.description,
        display_order: values.display_order,
      }),
    [patchAnswerOptionFieldGroup]
  );

  const handleDelete = useCallback(
    (id: string) => deleteAnswerOptionFieldGroup(id),
    [deleteAnswerOptionFieldGroup]
  );

  return (
    <div className="relative min-h-[150px]">
      {IsLoading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center p-10">
          <div className={`${Theme["bg-primary"]} opacity-50 absolute top-0 bottom-0 left-0 right-0`}></div>
          <span className="inline-block w-6 h-6">
            <Loader />
          </span>
        </div>
      ) : null}
      {RequestError && <p className="text-center text-base mb-2">{RequestError.message}</p>}
      <div>
        <div className="flex justify-end">
          <PrimaryButton
            onClick={() => handleCreate()}
            title="Create Answer Option Field Group"
            icon={<PlusCircleIcon />}
          >
            New Field Group
          </PrimaryButton>
        </div>
        {AnswerOptionFieldGroups.length === 0 ? (
          <div className="mt-4">
            <p className="text-base text-center">
              No Answer Option Field Groups are currently defined for this Question.
            </p>
          </div>
        ) : (
          <AnswerOptionFieldGroupsList
            answerOptionFieldGroups={[...AnswerOptionFieldGroups].sort(sortByDisplayOrder)}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        )}
      </div>
    </div>
  );
};

export default AnswerOptionFieldGroupsForm;
