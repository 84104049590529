import { Identifiable } from "interfaces/ResourceInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLChecksEndpoints } from "../../endpoints";

export const useRequestVendorDetail = () => {
  const { IsLoading, RequestError, sendGetRequest } = useRequestAMLService<Identifiable>();

  const getVendorDetails = useCallback(
    (checkId: string, cb: (vendorDetails: Identifiable) => void) =>
      sendGetRequest(
        generateCompleteURL(
          AMLChecksEndpoints.vendor.get,
          {
            check_id: checkId,
          },
          {},
        ),
        (data) => cb(data),
      ),
    [sendGetRequest],
  );

  return {
    IsLoading,
    RequestError,
    getVendorDetails,
  };
};
