import useTheme from "providers/ThemeStoreProvider/useTheme";
import ReactDOM from "react-dom";
import Loader from "../Loader/Loader";

const LoadingOverlay = () => {
  const { Theme } = useTheme();

  return ReactDOM.createPortal(
    <div className={Theme["loader:overlay:container"]}>
      <div className={Theme["loader:overlay:background"]}></div>
      <div className={Theme["loader:overlay:inner"]}>
        <Loader />
      </div>
    </div>,
    document.querySelector("#modal-portal") as Element
  );
};

export default LoadingOverlay;
