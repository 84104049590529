import { useMemo, useState } from "react";
import { getCookie } from "utilities/getCookie/getCookie";
import { setCookie } from "utilities/setCookie/setCookie";
import ThemeStoreContext, {
  ThemeStoreContextInterface,
  ThemeType,
} from "./ThemeStoreContext";
import { GeneratedThemeTable } from "./ThemeTable";

const { Provider } = ThemeStoreContext;

type ThemeStoreProviderProps = {
  /**
   * * Child content that Provider wraps around
   */
  children: React.ReactNode;
};

const THEME_COOKIE = "watcher_theme";

export const ThemeStoreProvider = (props: ThemeStoreProviderProps) => {
  const initialTheme = (getCookie(THEME_COOKIE) || "Light") as ThemeType;

  /**
   * * Prepare the current selected theme state
   */
  const [ActiveTheme, setActiveTheme] = useState<ThemeType>(initialTheme);

  const changeTheme = (theme: ThemeType) => {
    setActiveTheme(theme);
    setCookie(THEME_COOKIE, theme, 3600);
  };

  const generateThemeTable = (theme: ThemeType) => {
    return GeneratedThemeTable(theme);
  };

  const contextValue: ThemeStoreContextInterface = useMemo(() => {
    return {
      Theme: generateThemeTable(ActiveTheme),
      ActiveTheme,
      changeTheme,
    };
  }, [ActiveTheme]);

  return <Provider value={contextValue}>{props.children}</Provider>;
};
