import {AxiosError} from "axios";
import {useCallback} from "react";
import {generateCompleteURL} from "../../../../../utilities/generateCompleteURL/generateCompleteURL";
import {useRequestAMLService} from "../../../shared/services/useRequestAMLService";
import {AssessmentEndpoints} from "../endpoints";
import {IAssessment, IAssessments} from "../interfaces/IAssessment";

interface IUseRequestAssessments {
  readonly IsLoading: boolean;
  readonly RequestError: AxiosError | null;
  readonly getAssessments: (
    tenant_id: string,
    assessment_group_id: string,
    trashed: "true" | "false",
    cb: (data: IAssessments) => void
  ) => void;
}

type IAssessmentsResponse = IAssessments

export const useRequestAssessments = (): IUseRequestAssessments => {
  const { IsLoading, RequestError, sendGetRequest } = useRequestAMLService<IAssessmentsResponse>();

  const getAssessments = useCallback(
    (tenant_id: string, assessment_group_id: string, trashed: string, cb: (assessments: IAssessments) => void) => {
      sendGetRequest(
        generateCompleteURL(
          AssessmentEndpoints.index.get,
          {
            assessment_group_id,
          },
          {
            withtrashed: trashed
          }
        ),
        (assessments) => cb(assessments),
        {
          headers: {
            tenant_id
          }
        }
      )
    },
      [sendGetRequest]
  );

  return {
    IsLoading,
    RequestError,
    getAssessments
  }
}