import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { Link } from "react-router-dom";
import { CRON_SHOW } from "../../../routes";
import { CronLogsIndexInterface } from "../../interfaces/CronLogsIndexInterface";

type CronLogsTableRowProps = {
  log: CronLogsIndexInterface;
};

const CronLogsTableRow = (props: CronLogsTableRowProps) => (
  <TableRow>
    <TableData>
      <Link to={CRON_SHOW.replace(/:id/, props.log.id)} className="block text-left">
        {props.log.display_name}
      </Link>
    </TableData>
    <TableData>
      <p className="block text-center whitespace-nowrap">{props.log.updated_at}</p>
    </TableData>
    <TableData>
      <Link to={CRON_SHOW.replace(/:id/, props.log.id)} className="block text-right">
        <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">View Log</span>
      </Link>
    </TableData>
  </TableRow>
);

export default CronLogsTableRow;
