import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { AML_TEMPLATES } from "modules/Projects/AML/routes";
import CreateSectionModal from "modules/Projects/AML/Sections/components/CreateSectionModal/CreateSectionModal";
import DeleteSectionModal from "modules/Projects/AML/Sections/components/DeleteSectionModal/DeleteSectionModal";
import EditSectionModal from "modules/Projects/AML/Sections/components/EditSectionModal/EditSectionModal";
import SectionsList from "modules/Projects/AML/Sections/components/SectionsList/SectionsList";
import { AMLSectionInterface } from "modules/Projects/AML/Sections/interfaces/AMLSectionInterface";
import { useRequestAMLSections } from "modules/Projects/AML/Sections/services/useRequestAMLSections/useRequestAMLSections";
import { determineNextAvailableDisplayOrderValue } from "modules/Projects/AML/utilities/determineNextAvailableDisplayOrderValue/determineNextAvailableDisplayOrderValue";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";
import { useRequestAMLTemplates } from "../../services/useRequestAMLTemplates/useRequestAMLTemplates";

const TemplateShow = () => {
  // * Require Templates Service
  const {
    Templates,
    IsLoading: IsLoadingTemplates,
    RequestError: TemplatesRequestError,
    getTemplate,
  } = useRequestAMLTemplates();

  // * Require Sections Service
  const {
    Sections,
    IsLoading: IsLoadingSections,
    RequestError: SectionsRequestError,
    getSectionsIndex,
    postSection,
    patchSection,
    deleteSection,
  } = useRequestAMLSections();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  const [EditSectionContext, setEditSectionContext] = useState<
    | {
        context: "create";
        section: {
          templateId: string;
        };
      }
    | {
        context: "edit" | "delete";
        section: AMLSectionInterface;
      }
    | null
  >(null);

  const [CurrentTemplate, setCurrentTemplate] = useState<AMLTemplateInterface | null>(null);

  const { id } = useParams<{ id: string }>();

  useMountEffect(() => {
    getTemplate(id as string);
    getSectionsIndex(id as string);
  });

  useEffect(() => {
    setCurrentTemplate(() => {
      const template = Templates.find((t) => t.id === id);

      return template ?? null;
    });
  }, [Templates, id]);

  const handleCreateSection = useCallback(() => {
    setEditSectionContext({
      context: "create",
      section: { templateId: id as string },
    });
  }, [id]);

  const handleConfirmCreate = useCallback(
    (values: { name: string; description: string; templateId: string }) => {
      postSection({
        name: values.name,
        description: values.description,
        template_id: values.templateId,
        is_default: 1,
        display_order: determineNextAvailableDisplayOrderValue(Sections),
      });
      clearEditSectionContext();
    },
    [Sections, postSection],
  );

  const handleEditSection = useCallback(
    (idx: string) => {
      const section = Sections.find((s) => s.id === idx);

      if (section) {
        setEditSectionContext({
          context: "edit",
          section: section,
        });
      }
    },
    [Sections],
  );

  const handleConfirmEdit = useCallback(
    (values: { id: string; name: string; description: string }) => {
      patchSection(values.id, {
        name: values.name,
        description: values.description,
      });
      clearEditSectionContext();
    },
    [patchSection],
  );

  const handleDeleteSection = useCallback(
    (idx: string) => {
      const section = Sections.find((s) => s.id === idx);

      if (section) {
        setEditSectionContext({
          context: "delete",
          section: section,
        });
      }
    },
    [Sections],
  );

  const handleConfirmDelete = useCallback(
    (idx: string) => {
      deleteSection(idx);
      clearEditSectionContext();
    },
    [deleteSection],
  );

  const clearEditSectionContext = () => setEditSectionContext(null);

  return (
    <Page
      pageTitle={
        <span>
          AML Template{CurrentTemplate ? `: ${CurrentTemplate.name}` : ""}{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      hasError={TemplatesRequestError || SectionsRequestError}
      isLoadingContent={IsLoadingTemplates || IsLoadingSections}
      breadcrumbs={[{ content: "Templates", url: AML_TEMPLATES }]}
      wide
    >
      <div className="flex justify-end">
        <PrimaryButton onClick={() => handleCreateSection()} title="Create Section" icon={<PlusCircleIcon />}>
          New
        </PrimaryButton>
      </div>
      <SectionsList
        sections={Sections}
        handleCreate={handleCreateSection}
        handleEdit={handleEditSection}
        handleDelete={handleDeleteSection}
      />
      {EditSectionContext && (
        <>
          {EditSectionContext.context === "create" && (
            <CreateSectionModal
              onSubmit={handleConfirmCreate}
              onClose={clearEditSectionContext}
              templateId={CurrentTemplate ? CurrentTemplate.id : ""}
            />
          )}
          {EditSectionContext.context === "edit" && (
            <EditSectionModal
              section={EditSectionContext.section}
              onSubmit={handleConfirmEdit}
              onClose={clearEditSectionContext}
            />
          )}
          {EditSectionContext.context === "delete" && (
            <DeleteSectionModal
              section={EditSectionContext.section}
              onDelete={handleConfirmDelete}
              onClose={clearEditSectionContext}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default TemplateShow;
