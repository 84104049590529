import AppNavigationSubListItem from "components/UI/AppNavigation/AppNavigationSubListItem/AppNavigationSubListItem";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { LOGS_SERVICE_INDEX } from "../../../routes";
import { useRequestServicesIndex } from "../../services/useRequestServicesIndex/useRequestServicesIndex";

const LoadServicesNavigationTreeItems = () => {
  const { Services, IsLoading, getServicesIndex } = useRequestServicesIndex();

  useMountEffect(() => getServicesIndex());

  return (
    <>
      {IsLoading ? (
        "Loading..."
      ) : Services && Services.length > 0 ? (
        Services.map((service) => (
          <AppNavigationSubListItem
            item={{
              name: service.name.replace(/-/gi, " "),
              urlPath: LOGS_SERVICE_INDEX.replace(/:id/, service.name),
              isLink: true,
            }}
            key={service.name}
          />
        ))
      ) : (
        <p className="p-1">No services found</p>
      )}
    </>
  );
};

export default LoadServicesNavigationTreeItems;
