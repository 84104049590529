import { Component, ErrorInfo } from "react";

type ErrorBoundaryProps = {
  fallback?: JSX.Element;
  children: JSX.Element;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

/**
 * * Error Boundary Catcher
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = { hasError: false };

  public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback;
      } else {
        return <p>Something went wrong.</p>;
      }
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
