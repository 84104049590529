import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/outline";
import Table from "components/UI/Table/Table";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { IVisualAuthenticity, VAString } from "../../interfaces/IVisualAuthenticity";

interface IProps {
  readonly visualAuthenticity: IVisualAuthenticity;
}

export const VisualAuthenticity = ({ visualAuthenticity }: IProps) => {
  const content = Object.entries(visualAuthenticity).map(([k, { result }]) => (
    <TableRow key={k}>
      <TableData>{VAString[k as keyof IVisualAuthenticity]}</TableData>
      <TableData>
        <span className={`capitalize ${result === "clear" ? "text-green-500" : "text-yellow-500"}`}>
          {result === "clear" ? (
            <CheckCircleIcon className="w-4 h-4 mr-1 inline-block text-inherit" />
          ) : (
            <MinusCircleIcon className="w-4 h-4 mr-1 inline-block text-inherit" />
          )}
          {result}
        </span>
      </TableData>
    </TableRow>
  ));

  return (
    <>
      <h3 className="mb-2 font-500 p-2">Visual Authenticity</h3>
      <Table>
        <tbody>{content}</tbody>
      </Table>
    </>
  );
};
