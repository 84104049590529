import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Check } from "../components/Check/Check";
import { SearchCheckHeader } from "../components/SearchCheckHeader/SearchCheckHeader";
import { ICheck } from "../interfaces/ICheck";
import { useRequestAMLChecks } from "../services/useRequestAMLChecks/useRequestAMLChecks";

export const SearchChecks = () => {
  const [check, setCheck] = useState<ICheck | null>(null);

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  const [searchParams, setSearchParams] = useSearchParams();

  const { searchAMLChecks, IsLoading, RequestError } = useRequestAMLChecks();

  const handleSearchChecks = (ref: string) => {
    setCheck(null);

    if (ref) {
      setSearchParams({ ref });
      searchAMLChecks(ref, (data) => setCheck(data));
    } else {
      const params = searchParams;
      params.delete("ref");
      setSearchParams(params);
    }
  };

  useMountEffect(() => {
    const ref = searchParams.get("ref");
    if (ref) handleSearchChecks(ref);
  });

  return (
    <Page
      pageTitle={
        <span>
          Search AML Checks{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      <SearchCheckHeader handleQuerySearch={handleSearchChecks} currentQuery={searchParams.get("ref")} />
      {check && <Check check={check} />}
    </Page>
  );
};
