import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface TableHeadingProps extends React.ComponentPropsWithoutRef<"th"> {
  className?: string;
  children?: React.ReactNode;
}

const TableHeading = (props: TableHeadingProps) => {
  const { Theme } = useTheme();

  return (
    <th {...props} className={twMerge(Theme["table:heading"], props.className)}>
      {props.children && props.children}
    </th>
  );
};

export default TableHeading;
