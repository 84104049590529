import {Outlet, Route} from "react-router-dom";
import AnswerOptionTypesIndex from "./AML/AnswerOptionTypes/containers/AnswerOptionTypesIndex/AnswerOptionTypesIndex";
import {
  RiskAssessmentAssessmentGroupIndex
} from "./AML/AssessmentGroups/containers/AssessmentGroupsIndex/AssessmentGroupsIndex";
import {Assessments} from "./AML/Assessments/containers/Assessments";
import AssessmentTypesIndex from "./AML/AssessmentTypes/containers/AssessmentTypesIndex/AssessmentTypesIndex";
import EntityTypesIndex from "./AML/EntityTypes/containers/EntityTypesIndex/EntityTypesIndex";
import {
  AML_ANSWER_OPTION_TYPES,
  AML_ASSESSMENT_TYPES,
  AML_ENTITY_TYPES,
  AML_TEMPLATE,
  AML_TEMPLATES,
  RiskAssessmentRoutes
} from "./AML/routes";
import TemplateShow from "./AML/Templates/components/TemplateShow/TemplateShow";
import TemplatesIndex from "./AML/Templates/containers/TemplatesIndex/TemplatesIndex";
import {RiskAssessmentTenantIndex} from "./AML/Tenants/containers/TenantsIndex/TenantsIndex";
import {
  AssessmentGroupsIndex
} from "./AMLChecks/AssessmentGroups/containers/AssessmentGroupsIndex/AssessmentGroupsIndex";
import {Checks} from "./AMLChecks/Checks/containers/Checks";
import {SearchChecks} from "./AMLChecks/Checks/containers/SearchChecks";
import {AMLCheckRoutes} from "./AMLChecks/routes";
import {TenantsIndex} from "./AMLChecks/Tenants/containers/TenantsIndex/TenantsIndex";
import {AML, AMLCHECKS, PROJECTS} from "./routes";
import {AMLEndpointProvider} from "./shared/Providers/AMLEndpointProvider/AMLEndpointProvider";

const AMLRoutesWithProvider = () => (
  <AMLEndpointProvider>
    <Outlet/>
  </AMLEndpointProvider>
);

export const ProjectsRouter = (
  <Route path={PROJECTS}>
    {/* AML */}
    <Route path={AML} element={<AMLRoutesWithProvider/>}>
      <Route path={AML_ENTITY_TYPES} element={<EntityTypesIndex/>}/>
      <Route path={AML_ASSESSMENT_TYPES} element={<AssessmentTypesIndex/>}/>
      <Route path={AML_ANSWER_OPTION_TYPES} element={<AnswerOptionTypesIndex/>}/>
      <Route path={AML_TEMPLATES} element={<TemplatesIndex/>}/>
      <Route path={AML_TEMPLATE} element={<TemplateShow/>}/>
      <Route path={RiskAssessmentRoutes.tenants.index} element={<RiskAssessmentTenantIndex/>}/>
      <Route path={RiskAssessmentRoutes.assessment_groups.index} element={<RiskAssessmentAssessmentGroupIndex/>}/>
      <Route path={RiskAssessmentRoutes.assessments.index} element={<Assessments/>}/>
    </Route>
    <Route path={AMLCHECKS} element={<AMLRoutesWithProvider/>}>
      <Route path={AMLCheckRoutes.tenants.index} element={<TenantsIndex/>}/>
      <Route path={AMLCheckRoutes.assessment_groups.index} element={<AssessmentGroupsIndex/>}/>
      <Route path={AMLCheckRoutes.checks.index} element={<Checks/>}/>
      <Route path={AMLCheckRoutes.checks.search} element={<SearchChecks/>}/>
    </Route>
  </Route>
);
