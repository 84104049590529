import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useCallback, useState } from "react";
import CreateEntityModal from "../../components/CreateEntityModal/CreateEntityModal";
import DeleteEntityModal from "../../components/DeleteEntityModal/DeleteEntityModal";
import EditEntityModal from "../../components/EditEntityModal/EditEntityModal";
import EntityTypesTable from "../../components/EntityTypesTable/EntityTypesTable";
import { AMLEntityTypeInterface } from "../../interfaces/AMLEntityTypeInterface";
import { useRequestAMLEntityTypes } from "../../services/useRequestAMLEntityTypes/useRequestAMLEntityTypes";
import { findEntityType } from "../../utilities/findEntityType";

const EntityTypesIndex = () => {
  const {
    EntityTypes,
    getEntityTypesIndex,
    postEntityType,
    patchEntityType,
    deleteEntityType,
    RequestError,
    IsLoading,
  } = useRequestAMLEntityTypes();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  /**
   * * Current context for any entity being created / edited / deleted
   */
  const [EditEntityContext, setEditEntityContext] = useState<{
    editContext: "create" | "edit" | "delete";
    entityType: AMLEntityTypeInterface;
  } | null>(null);

  /**
   * * On Mount, request entityTypes
   */
  useMountEffect(() => getEntityTypesIndex());

  /**
   * * Handle creating
   */
  const handleCreate = useCallback(
    (parentId: string) => {
      if (!EntityTypes) return;
      const entity = findEntityType(EntityTypes, parentId);

      if (entity) {
        setEditEntityContext({ editContext: "create", entityType: entity });
      }
    },
    [EntityTypes],
  );

  /**
   * * On confirm create
   */
  const handleConfirmCreate = useCallback(
    (values: { id: string; name: string; description: string }) => {
      postEntityType({
        name: values.name,
        description: values.description,
        entity_type_id: values.id,
      });
      clearEditEntityContext();
    },
    [postEntityType],
  );

  /**
   * * Handle editing
   */
  const handleEdit = useCallback(
    (id: string) => {
      if (!EntityTypes) return;
      const entity = findEntityType(EntityTypes, id);

      if (entity) {
        setEditEntityContext({ editContext: "edit", entityType: entity });
      }
    },
    [EntityTypes],
  );

  /**
   * * On confirm edit
   */
  const handleConfirmEdit = useCallback(
    (values: { id: string; name: string; description: string }) => {
      patchEntityType({
        name: values.name,
        description: values.description,
        entity_type_id: values.id,
      });
      clearEditEntityContext();
    },
    [patchEntityType],
  );

  /**
   * * Handle deleting
   */
  const handleDelete = useCallback(
    (id: string) => {
      if (!EntityTypes) return;
      const entity = findEntityType(EntityTypes, id);

      if (entity) {
        setEditEntityContext({ editContext: "delete", entityType: entity });
      }
    },
    [EntityTypes],
  );

  /**
   * * on confirm deletion
   */
  const handleConfirmDelete = useCallback(
    (id: string) => {
      deleteEntityType(id);
      clearEditEntityContext();
    },
    [deleteEntityType],
  );

  /**
   * * Clear edit entity context
   */
  const clearEditEntityContext = () => setEditEntityContext(null);

  return (
    <Page
      pageTitle={
        <span>
          AML Entity Types{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      {EntityTypes && (
        <>
          {EntityTypes.map((entityType) => (
            <EntityTypesTable
              key={entityType.id}
              entityType={entityType}
              subLevel={false}
              handleCreate={handleCreate}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
          {EditEntityContext && (
            <>
              {EditEntityContext.editContext === "create" && (
                <CreateEntityModal
                  entityType={EditEntityContext.entityType}
                  onClose={() => clearEditEntityContext()}
                  onSubmit={handleConfirmCreate}
                />
              )}
              {EditEntityContext.editContext === "edit" && (
                <EditEntityModal
                  entityType={EditEntityContext.entityType}
                  onClose={() => clearEditEntityContext()}
                  onSubmit={handleConfirmEdit}
                />
              )}
              {EditEntityContext.editContext === "delete" && (
                <DeleteEntityModal
                  entityType={EditEntityContext.entityType}
                  onClose={() => clearEditEntityContext()}
                  onDelete={handleConfirmDelete}
                />
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default EntityTypesIndex;
