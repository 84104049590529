export const sortByDisplayOrder = <
  T extends { display_order: string | number }
>(
  a: T,
  b: T
) => {
  let a_display_order = a.display_order;
  let b_display_order = b.display_order;

  if (typeof a_display_order === "string") {
    a_display_order = parseInt(a_display_order);
  }

  if (typeof b_display_order === "string") {
    b_display_order = parseInt(b_display_order);
  }

  if (a_display_order < b_display_order) {
    return -1;
  } else if (b_display_order < a_display_order) {
    return 1;
  } else {
    return 0;
  }
};
