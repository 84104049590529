import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_CRON_LOG_SHOW } from "../../endpoints";
import { CronLogShowResponseInterface } from "../../interfaces/CronLogShowResponseInterface";

type GetCronLogShowConfig = {
  id: string;
};

interface useRequestCronLogShowInterface<T> extends useRequestServiceImplementation<T> {
  CronLog: T | null;
  getCronLogShow: (config: GetCronLogShowConfig) => void;
}

export const useRequestCronLogShow = <T extends CronLogShowResponseInterface>(): useRequestCronLogShowInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getCronLogShow = useCallback(
    (config: GetCronLogShowConfig) =>
      sendGetRequest(generateCompleteURL(GET_CRON_LOG_SHOW, { id: config.id }, {}), (data) => setData(data), {
        bearer: getAccessToken() as string,
      }),
    [getAccessToken, sendGetRequest],
  );

  return {
    CronLog: Data,
    IsLoading,
    RequestError,
    getCronLogShow,
  };
};
