/**
 * * Hook interface to AMLEndpoint context
 * * Reduces need for rewriting context imports each time its required
 * * And includes checking that the component is within a AMLEndpointProvider
 */

import { useContext } from "react";
import AMLEndpointContext, {
  AMLEndpointContextInterface,
} from "./AMLEndpointContext";

const useAMLEndpoint = () => {
  const context = useContext<AMLEndpointContextInterface>(AMLEndpointContext);

  // * Add some error logging
  if (context === undefined) {
    console.error(
      "[useAMLEndpoint] Context not provided. Ensure your component is a child of <AMLEndpointProvider />"
    );
  }

  return context;
};

export default useAMLEndpoint;
