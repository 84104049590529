import CodeBlock from "components/UI/CodeBlock/CodeBlock";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { CRON_INDEX } from "modules/Logs/Cron/routes";
import { useParams } from "react-router-dom";
import { useRequestCronLogShow } from "../../services/useRequestCronLogShow/useRequestCronLogShow";

const CronShow = () => {
  const { CronLog, getCronLogShow, RequestError, IsLoading } = useRequestCronLogShow();

  const { id } = useParams<{ id: string }>();

  useMountEffect(() => getCronLogShow({ id: id as string }));

  if (!id) {
    return null;
  }

  return (
    <Page
      pageTitle={`Cron Log: ${id}`}
      isLoadingContent={IsLoading}
      hasError={RequestError}
      wide
      breadcrumbs={[{ url: CRON_INDEX, content: "Back to All Cron Logs" }]}
    >
      {CronLog && <CodeBlock>{CronLog.log}</CodeBlock>}
    </Page>
  );
};

export default CronShow;
