import useTheme from "providers/ThemeStoreProvider/useTheme";

export interface IOption {
  readonly key: string;
  readonly value: string;
  readonly displayValue?: string;
}

export type IOptions = ReadonlyArray<IOption>;

interface IProps extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  readonly options: IOptions;
}

export const SelectField = ({ options, ...props }: IProps) => {
  const { Theme } = useTheme();

  return (
    <select {...props} className={`${Theme["input"]}`}>
      <option value="" disabled>
        Select one...
      </option>
      {options.map((option) => (
        <option key={option.key} value={option.value}>
          {option.displayValue ?? option.value}
        </option>
      ))}
    </select>
  );
};
