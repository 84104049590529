import { AnswerOptionRuleSetInterface } from "../../../interfaces/AMLAnswerOptionInterface";

export const orderByDisplayOrderRules = (
  A: string,
  B: string,
  display_order_rules: { [key: string]: number | string }
) => {
  if (A in display_order_rules && B in display_order_rules) {
    let orderA = display_order_rules[A];
    let orderB = display_order_rules[B];

    // * ensure values are numbers
    if (typeof orderA === "string") {
      orderA = parseInt(orderA);
    }
    if (typeof orderB === "string") {
      orderB = parseInt(orderB);
    }

    if (orderA < orderB) {
      return -1;
    }
    if (orderB < orderA) {
      return 1;
    }
  }
  return 0;
};

export const sortOptionsByRulesDisplayOrder = (
  options: {
    [key: string]: string;
  },
  rules: AnswerOptionRuleSetInterface | null
): Array<[string, string]> =>
  [...Object.entries(options)].sort(
    (objA: [string, string], objB: [string, string]) => {
      if (rules && "display_order" in rules) {
        const { display_order } = rules;
        if (display_order !== null && display_order !== undefined) {
          return orderByDisplayOrderRules(objA[0], objB[0], display_order);
        }
      }
      // * no display order in rules so don't reorder
      return 0;
    }
  );
