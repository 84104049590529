import Table from "components/UI/Table/Table";
import {useState} from "react";
import TableHeading from "../../../../../../components/UI/Table/TableHeading/TableHeading";
import {useMountEffect} from "../../../../../../hooks/useMountEffect/useMountEffect";
import {IAssessmentGroup, IAssessmentGroups} from "../../interfaces/IAssessmentGroup";
import {useUpdateAssessmentGroups} from "../../services/useUpdateAssessmentGroups";
import {AssessmentGroupsTableRow} from "../AssessmentGroupsTableRow/AssessmentGroupsTableRow";

interface IProps {
  readonly assessmentGroups: IAssessmentGroups;
}

export const AssessmentGroupsTable = ({assessmentGroups}: IProps) => {

  const [oldAssessmentGroups, setAssessmentGroups] = useState<IAssessmentGroups>([]);

  const {restoreAssessmentGroup, RequestError, IsLoading} = useUpdateAssessmentGroups();

  const restore = (assessmentGroup: IAssessmentGroup) => {
    restoreAssessmentGroup(assessmentGroup.id, (restoredAssessmentGroup) => {
      const newAssessmentGroups = [...assessmentGroups];
      const idx = newAssessmentGroups.findIndex(i => i.id === assessmentGroup.id);
      if (idx > -1) {
        newAssessmentGroups[idx] = {
          ...newAssessmentGroups[idx],
          deleted_at: restoredAssessmentGroup.deleted_at,
          is_trashed: restoredAssessmentGroup.deleted_at !== null
        };
      }
      setAssessmentGroups(newAssessmentGroups);
    })
  }

  useMountEffect(
    () => setAssessmentGroups(assessmentGroups)
  );

  return (
    <Table className={"table-auto"}>
      <thead>
      <tr>
        <TableHeading className={"px-2"}>Assessment Group Name</TableHeading>
        <TableHeading className={"px-2"}>Entity ID</TableHeading>
        <TableHeading className={"mx-2"}>Status</TableHeading>
        <TableHeading className={"px-2"}>Restore?</TableHeading>
      </tr>
      </thead>
      <tbody>
      {oldAssessmentGroups.map((assessmentGroup) => (
        <AssessmentGroupsTableRow handleRestore={() => restore(assessmentGroup)} assessmentGroup={assessmentGroup}
                                  key={assessmentGroup.id}/>
      ))}
      </tbody>
    </Table>
  )
}
