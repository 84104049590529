import { useCallback, useContext } from "react";
import FreeTypeConfig from "./Forms/FreeTypeConfig";
import OptionableConfig from "./Forms/OptionableConfig";
import TextAreaConfig from "./Forms/TextAreaConfig";
import { AMLAnswerOptionInterface } from "../../../interfaces/AMLAnswerOptionInterface";
import { AnswerOptionTypesContext } from "../../EditQuestionModal/EditQuestionModal";

type AnswerOptionConfigProps = {
  answerOption: AMLAnswerOptionInterface;
  handleAnswerChange: (answerOption: AMLAnswerOptionInterface) => void;
};

const AnswerOptionConfig = (props: AnswerOptionConfigProps) => {
  const { answerOptionTypes } = useContext(AnswerOptionTypesContext);

  const currentAnswerOptionType = answerOptionTypes.find(
    (answerOptionType) => answerOptionType.id === props.answerOption.answer_option_type_id,
  );

  const handleAnswerChange = useCallback(
    (answerOption: AMLAnswerOptionInterface) => {
      props.handleAnswerChange(answerOption);
    },
    [props],
  );

  if (!currentAnswerOptionType) {
    return <p className="text-center text-sm my-2 font-medium">Select an Answer Option to being configuration</p>;
  }

  switch (currentAnswerOptionType.key) {
    case "freetype": {
      return <FreeTypeConfig answerOption={props.answerOption} onChange={handleAnswerChange} />;
    }
    case "radio": {
      return (
        <OptionableConfig
          answerOption={props.answerOption}
          onChange={handleAnswerChange}
          content={"Radio permits a user to select only one option."}
        />
      );
    }
    case "multiple-preset": {
      return (
        <OptionableConfig
          answerOption={props.answerOption}
          onChange={handleAnswerChange}
          content={"Multi Preset permits a user to select one or more options from a dropdown list."}
        />
      );
    }
    case "single-preset": {
      return (
        <OptionableConfig
          answerOption={props.answerOption}
          onChange={handleAnswerChange}
          content={"Single Preset permits a user to select only one option from a dropdown list."}
        />
      );
    }
    case "textarea": {
      return <TextAreaConfig />;
    }

    case "checkbox": {
      return (
        <OptionableConfig
          answerOption={props.answerOption}
          onChange={handleAnswerChange}
          content={"Checkbox permits a user to select only multiple options."}
        />
      );
    }

    default: {
      return <p className="text-center text-sm my-2 font-medium">Unable to load Answer Option Config</p>;
    }
  }
};

export default AnswerOptionConfig;
