import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import Modal from "components/UI/Modal/Modal";
import { AML_ENDPOINTS } from "modules/Projects/AML/endpoints";
import { AMLEndpointEnvironmentType } from "modules/Projects/AML/interfaces/AMLEndpointEnvironmentType";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useState } from "react";
import { API_URL_FRAGMENT } from "../AMLEndpointProvider";
import useAMLEndpoint from "../useAMLEndpointProvider";

const AMLEndpointModal = () => {
  const { Theme } = useTheme();

  const { setAMLEndpoint } = useAMLEndpoint();

  /**
   * * Current AML env selection
   */
  const [CurrentAMLEndpointSelection, setCurrentAMLEndpointSelection] = useState<AMLEndpointEnvironmentType | null>(
    null,
  );

  /**
   * * User entered localhost URL
   */
  const [LocalhostURL, setLocalhostURL] = useState<string>(process.env.REACT_APP_AML_ENDPOINT_LOCALHOST ?? "");

  /**
   * * Optional localhost bearer token
   */
  const [LocalBearerToken, setLocalBearerToken] = useState<string>("");

  /**
   * * Should display modal?
   */
  const [DisplayModal, setDisplayModal] = useState<boolean>(true);

  /**
   * * Handle setting the selected environment
   * @param {AMLEndpointEnvironmentType} endpointEnv
   * @returns
   */
  const handleSettingAMLEndpoint = (endpointEnv: AMLEndpointEnvironmentType) =>
    setCurrentAMLEndpointSelection(endpointEnv);

  /**
   * * Handle confirming env selection
   */
  const handleConfirmSettingAMLEndpoint = useCallback(() => {
    if (CurrentAMLEndpointSelection) {
      setAMLEndpoint(CurrentAMLEndpointSelection, LocalhostURL, LocalBearerToken);
    }
  }, [CurrentAMLEndpointSelection, LocalBearerToken, LocalhostURL, setAMLEndpoint]);

  /**
   * * Handle modal toggle
   */
  const handleToggleModal = useCallback(() => {
    setDisplayModal(!DisplayModal);
    setCurrentAMLEndpointSelection(null);
    setLocalhostURL("");
    setLocalBearerToken("");
  }, [DisplayModal]);

  return (
    <>
      {DisplayModal ? (
        <Modal onClose={handleToggleModal}>
          <div className="p-4">
            <p className="mb-2 text-center">Before proceeding, please select the AML environment you wish to edit:</p>
            <select
              className={Theme.input}
              onChange={(e) => handleSettingAMLEndpoint(e.currentTarget.value as AMLEndpointEnvironmentType)}
            >
              <option>Select an option</option>
              {AML_ENDPOINTS.map((endpoint) => (
                <option value={endpoint.name} key={endpoint.name}>
                  {endpoint.title}
                </option>
              ))}
            </select>
            {CurrentAMLEndpointSelection === "localhost" && (
              <div className="mt-4">
                <p className="mb-2 text-sm">Please enter the URL for your localhost:</p>
                <div className="flex items-center">
                  <input
                    value={LocalhostURL}
                    onChange={(e) => setLocalhostURL(e.currentTarget.value)}
                    className={Theme["input"]}
                  />
                  <p>{API_URL_FRAGMENT}</p>
                </div>
                <p className="my-2 text-sm">
                  When using localhost, either update the .env file with the bearer token if running Watcher locally, or
                  enter the bearer token below:
                </p>
                <input
                  value={LocalBearerToken}
                  onChange={(e) => setLocalBearerToken(e.currentTarget.value)}
                  className={Theme["input"]}
                />
              </div>
            )}
            <div className="flex justify-between items-center mt-4">
              <TertiaryButton onClick={() => setDisplayModal(!DisplayModal)}>Cancel</TertiaryButton>
              <PrimaryButton
                type="submit"
                disabled={
                  !CurrentAMLEndpointSelection || (CurrentAMLEndpointSelection === "localhost" && LocalhostURL === "")
                }
                onClick={handleConfirmSettingAMLEndpoint}
              >
                Confirm
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      ) : (
        <div className="p-2">
          <p onClick={handleToggleModal} className={`${Theme["text-color-primary"]} cursor-pointer font-medium`}>
            Click here to select the AML environment you wish to edit
          </p>
        </div>
      )}
    </>
  );
};

export default AMLEndpointModal;
