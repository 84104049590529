import { CogIcon, DatabaseIcon, FolderOpenIcon, GlobeAltIcon } from "@heroicons/react/outline";
import React from "react";
import { CronNavigationTree } from "./Logs/Cron/NavigationTree";
import { LOGS } from "./Logs/routes";
import { ServiceLogsNavigationTree } from "./Logs/Services/NavigationTree";
import { AMLProjectNavigationTree } from "./Projects/AML/NavigationTree";
import { PromoCodes } from "./Settings/PromoCodes/routes";
import { PROMOTIONS } from "./Settings/Promotions/routes";
import { SETTINGS } from "./Settings/routes";
import { AppLogsNavigationTree } from "./Tenants/App/NavigationTree";
import { TENANTS } from "./Tenants/routes";

export type LoadingNavigationItemType = {
  name: string;
  isLoader: boolean;
  el: JSX.Element;
};

export type NavigationItemType = {
  name: string;
  urlPath?: string;
  isLink?: boolean;
  children?: Array<NavigationItemType | LoadingNavigationItemType>;
  icon?: React.ReactNode;
};

export const NavigationTree: {
  [key: string]: NavigationItemType;
} = {
  projects: {
    name: "Content",
    urlPath: "/projects",
    icon: <FolderOpenIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [...AMLProjectNavigationTree],
  },
  tenants: {
    name: "Tenants",
    urlPath: TENANTS,
    icon: <GlobeAltIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [...AppLogsNavigationTree],
  },
  logs: {
    name: "Logs",
    urlPath: LOGS,
    icon: <DatabaseIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [...ServiceLogsNavigationTree, ...CronNavigationTree],
  },
  settings: {
    name: "Settings",
    urlPath: SETTINGS,
    icon: <CogIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [
      {
        name: "Promotions",
        urlPath: PROMOTIONS,
        isLink: true,
      },
      {
        name: "Promo Codes",
        urlPath: PromoCodes,
        isLink: true,
      },
    ],
  },
};
