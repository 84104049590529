import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import AssessmentTypesTable from "../../components/AssessmentTypesTable/AssessmentTypesTable";
import CreateAssessmentTypeModal from "../../components/CreateAssessmentTypeModal/CreateAssessmentTypeModal";
import DeleteAssessmentTypeModal from "../../components/DeleteAssessmentTypeModal/DeleteAssessmentTypeModal";
import EditAssessmentTypeModal from "../../components/EditAssessmentTypeModal/EditAssessmentTypeModal";
import { AMLAssessmentTypeInterface } from "../../interfaces/AMLAssessmentTypeInterface";
import { useRequestAMLAssessmentTypes } from "../../services/useRequestAMLAssessmentTypes/useRequestAMLAssessmentTypes";

const AssessmentTypesIndex = () => {
  const {
    AssessmentTypes,
    getAssessmentTypesIndex,
    postAssessmentType,
    patchAssessmentType,
    deleteAssessmentType,
    RequestError,
    IsLoading,
  } = useRequestAMLAssessmentTypes();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  /**
   * * Current context for any assessment being created / edited / deleted
   */
  const [EditAssessmentContext, setEditAssessmentContext] = useState<
    | {
        editContext: "create";
        assessmentType: null;
      }
    | {
        editContext: "edit" | "delete";
        assessmentType: AMLAssessmentTypeInterface;
      }
    | null
  >(null);

  /**
   * * On Mount, request assessmentTypes
   */
  useMountEffect(() => getAssessmentTypesIndex());

  /**
   * * Handle creating
   */
  const handleCreate = () => {
    setEditAssessmentContext({
      editContext: "create",
      assessmentType: null,
    });
  };

  /**
   * * On confirm create
   */
  const handleConfirmCreate = (values: { name: string; description: string }) => {
    postAssessmentType({
      name: values.name,
      description: values.description,
    });
    clearEditAssessmentContext();
  };

  /**
   * * Handle editing
   */
  const handleEdit = (id: string) => {
    if (!AssessmentTypes) return;
    const assessment = AssessmentTypes.find((a) => a.id === id);

    if (assessment) {
      setEditAssessmentContext({
        editContext: "edit",
        assessmentType: assessment,
      });
    }
  };

  /**
   * * On confirm edit
   */
  const handleConfirmEdit = (values: { id: string; name: string; description: string }) => {
    patchAssessmentType(values.id, {
      name: values.name,
      description: values.description,
    });
    clearEditAssessmentContext();
  };

  /**
   * * Handle deleting
   */
  const handleDelete = (id: string) => {
    if (!AssessmentTypes) return;
    const assessment = AssessmentTypes.find((a) => a.id === id);

    if (assessment) {
      setEditAssessmentContext({
        editContext: "delete",
        assessmentType: assessment,
      });
    }
  };

  /**
   * * on confirm deletion
   */
  const handleConfirmDelete = (id: string) => {
    deleteAssessmentType(id);
    clearEditAssessmentContext();
  };

  /**
   * * Clear edit assessment context
   */
  const clearEditAssessmentContext = () => setEditAssessmentContext(null);

  return (
    <Page
      pageTitle={
        <span>
          AML Assessment Types{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      {AssessmentTypes && (
        <>
          <div className="flex justify-end">
            <PrimaryButton onClick={() => handleCreate()} title="Create Assessment type" icon={<PlusCircleIcon />}>
              New
            </PrimaryButton>
          </div>
          {AssessmentTypes.map((assessmentType) => (
            <AssessmentTypesTable
              key={assessmentType.id}
              assessmentType={assessmentType}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))}
          {EditAssessmentContext && (
            <>
              {EditAssessmentContext.editContext === "create" && (
                <CreateAssessmentTypeModal
                  assessmentType={null}
                  onClose={() => clearEditAssessmentContext()}
                  onSubmit={handleConfirmCreate}
                />
              )}
              {EditAssessmentContext.editContext === "edit" && (
                <EditAssessmentTypeModal
                  assessmentType={EditAssessmentContext.assessmentType}
                  onClose={() => clearEditAssessmentContext()}
                  onSubmit={handleConfirmEdit}
                />
              )}
              {EditAssessmentContext.editContext === "delete" && (
                <DeleteAssessmentTypeModal
                  assessmentType={EditAssessmentContext.assessmentType}
                  onClose={() => clearEditAssessmentContext()}
                  onDelete={handleConfirmDelete}
                />
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

export default AssessmentTypesIndex;
