import {
  ArrowCircleDownIcon,
  ArrowCircleLeftIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
} from "@heroicons/react/outline";
import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { useState } from "react";
import { AMLEntityTypeInterface } from "../../interfaces/AMLEntityTypeInterface";
import EntityTypesTableDetails from "../EntityTypesTableDetails/EntityTypesTableDetails";

type EntityTypesTableProps = {
  entityType: AMLEntityTypeInterface;
  subLevel?: boolean;
  handleCreate: (parentId: string) => void;
  handleEdit: (entityTypeId: string) => void;
  handleDelete: (entityTypeId: string) => void;
};

const EntityTypesTable = (props: EntityTypesTableProps) => {
  const [CollapseSubList, setCollapseSubList] = useState<boolean>(true);

  const handleCreate = () => props.handleCreate(props.entityType.id);

  const handleEdit = () => props.handleEdit(props.entityType.id);

  const handleDelete = () => props.handleDelete(props.entityType.id);

  return (
    <div
      className={`${props.subLevel ? "mt-4" : "mt-10"} first-of-type:mt-0`}
      key={props.entityType.id}
    >
      <Table>
        <thead>
          <TableRow>
            <TableHeading>{props.entityType.name}</TableHeading>
            <TableHeading>
              <div className="flex justify-end">
                {!props.subLevel && (
                  <span
                    className="cursor-pointer mr-4 hover:opacity-80"
                    onClick={() => handleCreate()}
                    title="Create Entity type"
                  >
                    <PlusCircleIcon className="h-5 w-5 inline-block" />
                  </span>
                )}
                <span
                  className="cursor-pointer mr-4 hover:opacity-80"
                  onClick={() => handleEdit()}
                  title="Edit Entity type"
                >
                  <PencilIcon className="h-5 w-5 inline-block" />
                </span>
                <span
                  className="cursor-pointer hover:opacity-80"
                  onClick={() => handleDelete()}
                  title="Delete Entity type"
                >
                  <MinusCircleIcon className="h-5 w-5 inline-block" />
                </span>
              </div>
            </TableHeading>
          </TableRow>
        </thead>
        <EntityTypesTableDetails entityType={props.entityType} />
      </Table>
      {props.entityType.entity_sub_types &&
      props.entityType.entity_sub_types.length > 0 ? (
        <div className="ml-10 mt-2">
          <h3
            className="font-medium text-xl mb-2 flex items-center justify-between cursor-pointer"
            onClick={() => setCollapseSubList(!CollapseSubList)}
          >
            {CollapseSubList ? "View " : ""}Sub Entity Types
            <span>
              {CollapseSubList ? (
                <ArrowCircleLeftIcon className="h-5 w-5 inline-block" />
              ) : (
                <ArrowCircleDownIcon className="h-5 w-5 inline-block" />
              )}
            </span>
          </h3>
          <div className={`${CollapseSubList ? "hidden" : "block"}`}>
            {props.entityType.entity_sub_types.map((entityType) => (
              <EntityTypesTable
                key={entityType.id}
                entityType={entityType}
                subLevel={true}
                handleCreate={props.handleCreate}
                handleEdit={props.handleEdit}
                handleDelete={props.handleDelete}
              />
            ))}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default EntityTypesTable;
