import { CheckIcon } from "@heroicons/react/outline";
import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";
import { isLevel1QuestionByQuestion } from "modules/Projects/AML/Questions/utilities/determineQuestionLevel";
import { useCallback, useContext } from "react";
import { QuestionsContext } from "../../../../QuestionsList/QuestionsList";
import { sortOptionsByRulesDisplayOrder } from "../../../utilities/orderByDisplayOrderRules";

type DetailsTablePropsType = {
  answerOption: AMLAnswerOptionInterface;
};

/**
 * * Display table for the answer type config (details only)
 */
const DetailsTable = (props: DetailsTablePropsType) => {
  const { options, default_answer, rules } = props.answerOption;

  // * Get Questions context to use for referencing sub q's
  const { questions, currentQuestion } = useContext(QuestionsContext);

  // * Get the Question numbers by display order, display unknown for any potentially missing questions
  const SubQuestionSelections = useCallback(
    (a_id: string) => {
      return rules &&
        rules.level_2 &&
        a_id in rules.level_2 &&
        rules.level_2[a_id] !== null
        ? rules.level_2[a_id].map((q_id) => {
            const question = questions.find((q) => q.id === q_id);
            return (
              <span className="mr-1" key={question?.id}>
                {question ? `${question.ref}` : `Unknown`},
              </span>
            );
          })
        : null;
    },
    [questions, rules]
  );

  return (
    <>
      {options && Object.keys(options).length ? (
        <table className="table table-auto w-full divide-y mb-4">
          <thead>
            <tr className="divide-x">
              <th className="text-sm font-medium py-1">Value</th>
              <th className="text-sm font-medium py-1">
                Leads To
                <br />
                Enhanced?
              </th>
              <th className="text-sm font-medium py-1">Is Default?</th>
              {currentQuestion &&
              isLevel1QuestionByQuestion(currentQuestion) ? (
                <th className="text-sm font-medium py-1">Sub Questions</th>
              ) : null}
            </tr>
          </thead>
          <tbody>
            {sortOptionsByRulesDisplayOrder(options, rules).map(
              (obj: [string, string]) => (
                <tr className="py-1 px-2 divide-x" key={obj[0]}>
                  <td className="text-sm py-1 px-2 text-center">{obj[1]}</td>
                  <td className="py-1 px-2 text-center">
                    {rules &&
                    "leads_to_enhanced" in rules &&
                    rules.leads_to_enhanced.includes(obj[0]) ? (
                      <CheckIcon className="h-4 w-4 inline-block" />
                    ) : null}
                  </td>
                  <td className="py-1 px-2 text-center">
                    {default_answer && default_answer.includes(obj[0]) ? (
                      <CheckIcon className="h-4 w-4 inline-block" />
                    ) : null}
                  </td>
                  {currentQuestion &&
                  isLevel1QuestionByQuestion(currentQuestion) ? (
                    <td className="py-1 px-2 text-center">
                      {SubQuestionSelections(obj[0])}
                    </td>
                  ) : null}
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <p className="text-sm text-center mb-2">No Options defined</p>
      )}
    </>
  );
};

export default DetailsTable;
