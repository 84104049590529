import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { CronLogsIndexInterface } from "../../interfaces/CronLogsIndexInterface";
import CronLogsTableRow from "../CronLogsTableRow/CronLogsTableRow";

type CronLogsTableProps = {
  logs: Array<CronLogsIndexInterface>;
};

const CronLogsTable = (props: CronLogsTableProps) => (
  <Table>
    <thead>
      <TableRow>
        <TableHeading>Log</TableHeading>
        <TableHeading className="w-56 text-center">Last executed</TableHeading>
        <TableHeading className="w-24"></TableHeading>
      </TableRow>
    </thead>
    <tbody>
      {props.logs.map((log) => (
        <CronLogsTableRow log={log} key={log.id} />
      ))}
    </tbody>
  </Table>
);

export default CronLogsTable;
