/**
 * * Define the Auth context for the App
 */

import { AxiosError } from "axios";
import { AMLEndpointEnvironmentType } from "modules/Projects/AML/interfaces/AMLEndpointEnvironmentType";
import { createContext } from "react";

/**
 * * Interface for the Auth context
 */
export interface AuthContextInterface {
  /**
   * * Get the current Auth token
   */
  getAccessToken: () => null | string;

  /**
   * * Get the current AML Access token
   */
  getAMLAccessToken: (amlEnvName: AMLEndpointEnvironmentType) => null | string;

  /**
   * * Set an AML access token
   */
  setAMLAccessToken: (amlEnvName: AMLEndpointEnvironmentType, bearer: string) => void;

  /**
   * * Clear the Auth tokens
   */
  clearAccessTokens: () => void;

  /**
   * * Check if is authenticated
   */
  isAuthenticated: () => boolean;

  /**
   * * Request Auth Token
   */
  requestLogin: (data: { username: string; password: string }) => void;

  /**
   * * Is Requesting Auth Token
   */
  IsLoading: boolean;

  /**
   * * Request Error
   */
  Error: null | AxiosError;
}

/**
 * * Create a context for the Auth Store Provider
 */
const AuthStoreContext = createContext<AuthContextInterface>({} as AuthContextInterface);

export default AuthStoreContext;
