import Table from "components/UI/Table/Table";
import { AppLogsIndexInterface } from "modules/Tenants/App/AppLogs/interfaces/AppLogsIndexInterface";
import AppLogsTableRow from "../AppLogsTableRow/AppLogsTableRow";

type AppLogsTableProps = {
  logs: Array<AppLogsIndexInterface>;
};

const AppLogsTable = (props: AppLogsTableProps) => (
  <Table>
    <tbody>
      {props.logs.map((log) => (
        <AppLogsTableRow log={log} key={log.id} />
      ))}
    </tbody>
  </Table>
);

export default AppLogsTable;
