import { AMLAssessmentTypeInterface } from "modules/Projects/AML/AssessmentTypes/interfaces/AMLAssessmentTypeInterface";
import { AMLEntityTypeInterface } from "modules/Projects/AML/EntityTypes/interfaces/AMLEntityTypeInterface";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";
import { findTemplate } from "../../utilities/findTemplate";
import TemplatesEntityTypeList from "../TemplatesEntityTypeList/TemplatesEntityTypeList";
import TemplateTable from "../TemplateTable/TemplateTable";

type TemplatesTablesProps = {
  templates: Array<AMLTemplateInterface>;
  entityTypes: Array<AMLEntityTypeInterface>;
  assessmentTypes: Array<AMLAssessmentTypeInterface>;
  handleCreate: (assessmentTypeId: string, entitySubTypeId: string) => void;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const TemplatesTables = (props: TemplatesTablesProps) => {
  const handleCreate = (assessmentTypeId: string, entitySubTypeId: string) =>
    props.handleCreate(assessmentTypeId, entitySubTypeId);

  const handleEdit = (id: string) => props.handleEdit(id);

  const handleDelete = (id: string) => props.handleDelete(id);

  return (
    <>
      {props.entityTypes.map((entityType) => (
        <TemplatesEntityTypeList entityType={entityType} key={entityType.id}>
          {entityType.entity_sub_types &&
            entityType.entity_sub_types.map((entitySubType) => (
              <TemplatesEntityTypeList
                key={entitySubType.id}
                entityType={entitySubType}
              >
                {props.assessmentTypes.map((assessmentType) => (
                  <TemplateTable
                    key={`${entitySubType.id}_${assessmentType.id}`}
                    entitySubType={entitySubType}
                    assessmentType={assessmentType}
                    template={findTemplate(
                      entitySubType.id,
                      assessmentType.id,
                      props.templates
                    )}
                    handleCreate={handleCreate}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                  />
                ))}
              </TemplatesEntityTypeList>
            ))}
        </TemplatesEntityTypeList>
      ))}
    </>
  );
};

export default TemplatesTables;
