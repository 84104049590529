import {
  ArrowCircleDownIcon,
  ArrowCircleLeftIcon,
  MinusCircleIcon,
  PencilIcon,
} from "@heroicons/react/outline";
import QuestionsList from "modules/Projects/AML/Questions/components/QuestionsList/QuestionsList";
import React, { useCallback, useState } from "react";
import { AMLSectionInterface } from "../../interfaces/AMLSectionInterface";

type SectionListItemProps = {
  section: AMLSectionInterface;
  children?: React.ReactNode;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
};

const SectionListItem = (props: SectionListItemProps) => {
  const [CollapseSubList, setCollapseSubList] = useState<boolean>(true);

  const handleEdit = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      props.handleEdit(props.section.id);
    },
    [props]
  );

  const handleDelete = useCallback(
    (e: React.SyntheticEvent) => {
      e.stopPropagation();
      props.handleDelete(props.section.id);
    },
    [props]
  );

  return (
    <div className="mt-4 first-of-type:mt-0">
      <h2
        className="text-left text-2xl font-medium mb-2 flex justify-between items-center cursor-pointer"
        onClick={() => setCollapseSubList(!CollapseSubList)}
      >
        {props.section.name}
        <div className="flex justify-end">
          <span
            className="cursor-pointer mr-4 hover:opacity-80"
            onClick={(e) => handleEdit(e)}
            title="Edit Section"
          >
            <PencilIcon className="h-5 w-5 inline-block" />
          </span>
          <span
            className="cursor-pointer mr-4 hover:opacity-80"
            onClick={(e) => handleDelete(e)}
            title="Delete Section"
          >
            <MinusCircleIcon className="h-5 w-5 inline-block" />
          </span>
          <span className="cursor-pointer hover:opacity-80">
            {CollapseSubList ? (
              <ArrowCircleLeftIcon className="h-5 w-5 inline-block" />
            ) : (
              <ArrowCircleDownIcon className="h-5 w-5 inline-block" />
            )}
          </span>
        </div>
      </h2>
      {props.section.description ? (
        <p className="text-sm font-medium">{props.section.description}</p>
      ) : null}
      <div className={`ml-5 ${CollapseSubList ? "hidden" : "block"}`}>
        {!CollapseSubList && <QuestionsList sectionId={props.section.id} />}
      </div>
    </div>
  );
};

export default SectionListItem;
