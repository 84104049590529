export const getCookie = (cookieName: string): string => {
  const searchCookie: string | undefined = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${cookieName}=`));

  if (searchCookie) {
    return searchCookie.split("=")[1];
  }

  return "";
};
