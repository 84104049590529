import { GeneratedThemeTableType, ThemeTableType, ThemeType } from "./ThemeStoreContext";

export const ERROR_CLASSNAME = "ErrorMsg";
/**
 * * Base styles
 */
export const ThemeTable: ThemeTableType = {
  Dark: {
    "bg-primary": "bg-neutral-900",
    "bg-secondary": "bg-neutral-800",
    "bg-tertiary": "bg-neutral-700",

    "hover:bg-primary": "hover:bg-neutral-800",
    "hover:bg-secondary": "hover:bg-neutral-700",
    "hover:bg-tertiary": "hover:bg-neutral-600",

    "text-color-primary": "text-neutral-200",
    "text-color-secondary": "text-neutral-200",
    "text-color-tertiary": "text-neutral-200",

    "code:bg": "bg-neutral-600",

    "table:bg": "bg-neutral-800",
    "table:hover:bg": "hover:bg-neutral-700",

    "app-header:bg": "bg-black",

    "form:input:bg": "bg-neutral-700 disabled:bg-neutral-800",
    "form:input:placeholder-color": "placeholder-gray-400",
    "form:input:text-color": "text-neutral-200",

    "form:input:border": "border-gray-300",
    "form:input:focus:outline": "focus:outline-green-400",
    "form:input:error:text-color": "text-negative-500",
    "form:input:error:placeholder-color": "placeholder-negative-500",
    "form:input:error:bg": "bg-negative-100",
    "form:input:error:focus:outline": "focus:outline-negative-500",
    "form:input:error:border": "border-negative-500",

    "theme-selector:button": "opacity-50 hover:opacity-100",
  },
  Light: {
    "bg-primary": "bg-neutral-100",
    "bg-secondary": "bg-white",
    "bg-tertiary": "bg-neutral-200",

    "hover:bg-primary": "hover:bg-neutral-200",
    "hover:bg-secondary": "hover:bg-neutral-100",
    "hover:bg-tertiary": "hover:bg-neutral-200",

    "text-color-primary": "text-black",
    "text-color-secondary": "text-black",
    "text-color-tertiary": "text-black",

    "code:bg": "bg-neutral-200",

    "table:bg": "bg-white",
    "table:hover:bg": "hover:bg-neutral-50",

    "app-header:bg": "bg-neutral-900",

    "form:input:bg": "text-gray-900 disabled:text-gray-800",
    "form:input:placeholder-color": "placeholder-gray-500",
    "form:input:text-color": "bg-white",

    "form:input:border": "border-gray-300",
    "form:input:focus:outline": "focus:outline-green-400",
    "form:input:error:text-color": "text-negative-500",
    "form:input:error:placeholder-color": "placeholder-negative-500",
    "form:input:error:bg": "bg-negative-100",
    "form:input:error:focus:outline": "focus:outline-negative-500",
    "form:input:error:border": "border-negative-500",

    "theme-selector:button": "opacity-50 hover:opacity-100",
  },
};

/**
 * * Element styles with base styles
 */
export const GeneratedThemeTable = (theme: ThemeType): GeneratedThemeTableType => {
  const Theme = ThemeTable[theme];

  return {
    ...Theme,
    "login:container": `rounded-lg shadow-lg px-6 py-8 lg:px-8 lg:py-12 w-full max-w-md m-4 ${Theme["bg-secondary"]}`,
    "login:logo:container": "rounded-lg overflow-hidden mx-auto w-32",
    "login:logo": "block w-full",

    corevalue: `mt-6 text-center text-xl font-semibold ${Theme["text-color-primary"]}`,

    form: "mx-auto max-w-xs",
    label: "text-sm font-medium block",
    input: `rounded-md block w-full px-3 py-2 border text-sm ${Theme["form:input:bg"]} ${Theme["form:input:border"]} ${Theme["form:input:placeholder-color"]} ${Theme["form:input:text-color"]} ${Theme["form:input:focus:outline"]} disabled:opacity-70`,
    "input:error": `${Theme["form:input:error:text-color"]} ${Theme["form:input:error:placeholder-color"]} ${Theme["form:input:error:bg"]} ${Theme["form:input:error:focus:outline"]} ${Theme["form:input:error:border"]}`,
    "input:additional-information": "text-sm font-regular mt-1",
    "form:error": `${ERROR_CLASSNAME} text-center font-semibold my-2 text-sm ${Theme["form:input:error:text-color"]}`,

    "table:container": `w-full overflow-auto text-sm font-medium text-left ${Theme["table:bg"]} ${Theme["text-color-primary"]}`,
    table: "table-fixed w-full min-w-[600px] border-collapse",
    "table:row": `${Theme["table:hover:bg"]}`,
    "table:heading": "p-2 text-base",
    "table:data": "p-2",

    "flex:centralise": "flex items-center justify-center",

    "theme-selector:container": "flex items-center bg-black rounded-2xl border border-neutral-700 overflow-hidden",

    "theme-selector:button:light": `cursor-pointer py-1 px-2 rounded-l-2xl ${
      theme === "Dark" ? Theme["theme-selector:button"] : ""
    }`,
    "theme-selector:button:dark": `cursor-pointer py-1 px-2 rounded-l-2xl ${
      theme === "Light" ? Theme["theme-selector:button"] : ""
    }`,

    "message:container": `border flex justify-start items-stretch ${Theme["bg-primary"]} ${Theme["text-color-primary"]} border-gray-500`,
    "message:sidebar": "flex justify-center items-center p-100 bg-gray-500",
    "message:title": `text-left mb-2 text-base font-700`,
    "message:text": `text-left text-sm font-500`,

    icon: "text-inherit w-5 h-5 inline-block",

    "modal:container": `fixed transform top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 ${Theme["text-color-primary"]}`,
    "modal:background": `fixed top-0 bottom-0 left-0 right-0 ${Theme["bg-primary"]} opacity-70 z-40`,
    "modal:inner": `${Theme["bg-primary"]} rounded-md shadow-lg overflow-hidden w-full`,

    "breadcrumb:link": "text-left text-positive-500 hover:text-positive-600 font-medium text-sm mr-1",

    page: `min-h-[64px] relative ${Theme["text-color-primary"]}`,
    "page:title": "text-left font-medium text-5xl mb-6 break-words",

    "pagination:container": "flex items-center justify-center",
    "pagination:inner": "flex items-center py-2",
    "pagination:current": "text-center text-sm font-medium mx-2",

    "loader:overlay:container": `fixed top-0 left-0 right-0 bottom-0 z-50 ${Theme["text-color-primary"]}`,
    "loader:overlay:background": `fixed top-0 left-0 right-0 bottom-0 opacity-70 ${Theme["bg-primary"]}`,
    "loader:overlay:inner": `fixed top-0 left-0 right-0 bottom-0 w-12 h-12 my-auto mx-auto`,

    "codeblock:container": `mt-2 first-of-type:mt-0 p-2 leading-500 ${Theme["code:bg"]} ${Theme["text-color-primary"]}`,
    "codeblock:pre": "text-sm w-auto whitespace-pre-line break-words",

    "appheader:container": `fixed top-0 left-0 right-0 z-10 w-full flex items-stretch justify-between px-4 text-white ${Theme["app-header:bg"]}`,

    "appnavigation:container": `fixed left-0 top-10 bottom-0 right-auto z-10 shadow-lg ${Theme["text-color-primary"]} ${Theme["bg-secondary"]}`,
    "appnavigation:branch": "mt-4 first-of-type:mt-0 cursor-pointer select-none",
    "appnavigation:branch:title": "p-1 rounded-sm flex items-center justify-between",
    "appnavigation:branch:title:active": Theme["bg-tertiary"],

    "appnavigation:sublist:item": "text-inherit text-lg select-none",
    "appnavigation:sublist:title": "capitalize cursor-pointer p-1 flex justify-between items-center",
    "appnavigation:sublist:link": `cursor-pointer block rounded-sm p-1 ${Theme["hover:bg-secondary"]}`,
    "appnavigation:sublist:link:active": `cursor-pointer block rounded-sm p-1 ${Theme["bg-tertiary"]}`,
  };
};
