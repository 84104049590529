import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { useRequestAMLAssessmentTypes } from "modules/Projects/AML/AssessmentTypes/services/useRequestAMLAssessmentTypes/useRequestAMLAssessmentTypes";
import { useRequestAMLEntityTypes } from "modules/Projects/AML/EntityTypes/services/useRequestAMLEntityTypes/useRequestAMLEntityTypes";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useCallback, useState } from "react";
import CreateTemplateModal from "../../components/CreateTemplateModal/CreateTemplateModal";
import DeleteTemplateModal from "../../components/DeleteTemplateModal/DeleteTemplateModal";
import EditTemplateModal from "../../components/EditTemplateModal/EditTemplateModal";
import TemplatesTables from "../../components/TemplatesTables/TemplatesTables";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";
import { useRequestAMLTemplates } from "../../services/useRequestAMLTemplates/useRequestAMLTemplates";
import { findTemplate } from "../../utilities/findTemplate";

const TemplatesIndex = () => {
  // * Require Templates Service
  const {
    Templates,
    IsLoading: IsLoadingTemplates,
    RequestError: TemplatesRequestError,
    getTemplatesIndex,
    postTemplate,
    patchTemplate,
    deleteTemplate,
  } = useRequestAMLTemplates();

  // * Require Entity Types Service
  const {
    EntityTypes,
    IsLoading: IsLoadingEntityTypes,
    RequestError: EntityTypesRequestError,
    getEntityTypesIndex,
  } = useRequestAMLEntityTypes();

  // * Require Assessment Types Service
  const {
    AssessmentTypes,
    IsLoading: IsLoadingAssessmentTypes,
    RequestError: AssessmentTypesRequestError,
    getAssessmentTypesIndex,
  } = useRequestAMLAssessmentTypes();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  useMountEffect(() => {
    getTemplatesIndex();
    getAssessmentTypesIndex();
    getEntityTypesIndex();
  });

  /**
   * * Current context for any template being created / deleted
   */
  const [EditTemplateContext, setEditTemplateContext] = useState<
    | {
        editContext: "create";
        template: {
          assessmentTypeId: string;
          entityTypeId: string;
          entitySubTypeId: string;
        };
      }
    | {
        editContext: "edit" | "delete";
        template: AMLTemplateInterface;
      }
    | null
  >(null);

  const handleCreate = useCallback(
    (assessmentTypeId: string, entitySubTypeId: string) => {
      // * ensure no matching template already exists
      const template = findTemplate(entitySubTypeId, assessmentTypeId, Templates);

      const entityType = EntityTypes.find(
        (e) => e.entity_sub_types && e.entity_sub_types.find((st) => st.id === entitySubTypeId),
      );

      if (!template && entityType) {
        setEditTemplateContext({
          editContext: "create",
          template: {
            assessmentTypeId: assessmentTypeId,
            entityTypeId: entityType.id,
            entitySubTypeId: entitySubTypeId,
          },
        });
      }
    },
    [EntityTypes, Templates],
  );

  const handleConfirmCreate = useCallback(
    (values: {
      name: string;
      description: string;
      assessmentTypeId: string;
      entityTypeId: string;
      entitySubTypeId: string;
    }) => {
      postTemplate({
        assessment_type_id: values.assessmentTypeId,
        entity_subtype_id: values.entitySubTypeId,
        entity_type_id: values.entityTypeId,
        is_default: 1,
        name: values.name,
        description: values.description,
      });
      clearEditTemplateContext();
    },
    [postTemplate],
  );

  const handleEdit = useCallback(
    (id: string) => {
      const template = Templates.find((t) => t.id === id);

      if (template) {
        setEditTemplateContext({
          editContext: "edit",
          template: template,
        });
      }
    },
    [Templates],
  );

  const handleConfirmEdit = useCallback(
    (values: { id: string; name: string; description: string }) => {
      patchTemplate(values.id, {
        name: values.name,
        description: values.description,
      });
      clearEditTemplateContext();
    },
    [patchTemplate],
  );

  const handleDelete = useCallback(
    (id: string) => {
      const template = Templates.find((t) => t.id === id);

      if (template) {
        setEditTemplateContext({
          editContext: "delete",
          template: template,
        });
      }
    },
    [Templates],
  );

  const handleConfirmDelete = useCallback(
    (id: string) => {
      deleteTemplate(id);
      clearEditTemplateContext();
    },
    [deleteTemplate],
  );

  const clearEditTemplateContext = () => setEditTemplateContext(null);

  return (
    <Page
      pageTitle={
        <span>
          AML Templates{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoadingAssessmentTypes || IsLoadingEntityTypes || IsLoadingTemplates}
      hasError={AssessmentTypesRequestError || EntityTypesRequestError || TemplatesRequestError}
    >
      <TemplatesTables
        assessmentTypes={AssessmentTypes}
        entityTypes={EntityTypes}
        templates={Templates}
        handleCreate={handleCreate}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
      />
      {EditTemplateContext && (
        <>
          {EditTemplateContext.editContext === "create" && (
            <CreateTemplateModal
              onSubmit={handleConfirmCreate}
              onClose={clearEditTemplateContext}
              assessmentTypeId={EditTemplateContext.template.assessmentTypeId}
              entityTypeId={EditTemplateContext.template.entityTypeId}
              entitySubTypeId={EditTemplateContext.template.entitySubTypeId}
            />
          )}
          {EditTemplateContext.editContext === "edit" && (
            <EditTemplateModal
              template={EditTemplateContext.template}
              onSubmit={handleConfirmEdit}
              onClose={clearEditTemplateContext}
            />
          )}
          {EditTemplateContext.editContext === "delete" && (
            <DeleteTemplateModal
              template={EditTemplateContext.template}
              onDelete={handleConfirmDelete}
              onClose={clearEditTemplateContext}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default TemplatesIndex;
