import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import Loader from "components/UI/Loader/Loader";
import Modal from "components/UI/Modal/Modal";
import { Formik } from "formik";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback } from "react";

type CreateQuestionModalProps = {
  onSubmit: (values: {
    value: string;
    guidance: string;
    note: string;
    level: "1" | "2";
    allow_multiple: boolean;
    sectionId: string;
  }) => void;
  onClose: () => void;
  sectionId: string;
  isLoading?: boolean;
};

const CreateQuestionModal = (props: CreateQuestionModalProps) => {
  const { Theme } = useTheme();

  const { getAMLEndpointTitle } = useAMLEndpoint();

  const onSubmit = useCallback(
    (values: { value: string; guidance: string; note: string; level: "1" | "2"; allow_multiple: boolean }) => {
      props.onSubmit({
        ...values,
        sectionId: props.sectionId,
      });
    },
    [props],
  );

  return (
    <Modal onClose={props.onClose} wide>
      <div className="flex flex-col">
        <div className={`${Theme["bg-secondary"]} px-4 py-2`}>
          <h2 className="font-medium text-lg">Create Question ({getAMLEndpointTitle()})</h2>
        </div>
        <div className={`p-4 overflow-auto  max-h-[80vh]`}>
          <Formik
            initialValues={{
              value: "",
              guidance: "",
              note: "",
              level: "1",
              allow_multiple: false,
            }}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit} className="relative">
                <div>
                  <div>
                    <FieldSet
                      error={formik.errors.value}
                      label="Question:"
                      name="value"
                      placeholder="Question..."
                      layoutHorizontal
                      showLabel
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.guidance}
                      label="Guidance:"
                      name="guidance"
                      placeholder="Question guidance..."
                      layoutHorizontal
                      showLabel
                      additionalInformation="Guidance is shown when the user selects an enhanced risk
                    option"
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.note}
                      label="Note:"
                      name="note"
                      placeholder="Question note..."
                      layoutHorizontal
                      showLabel
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.allow_multiple}
                      label={"Allow multiple?"}
                      name={"allow_multiple"}
                      layoutHorizontal
                      showLabel
                      type="checkbox"
                      additionalInformation="Selecting allow multiple will allow the user to enter multiple answer sets"
                      currentValue={formik.values.allow_multiple}
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.level}
                      label={"Level:"}
                      name="level"
                      layoutHorizontal
                      showLabel
                      type="select"
                      additionalInformation="A level 2 is a sub question and must be linked to another level 1 question in order to be displayed. Level 1 questions will always be displayed as part of the assessment flow"
                      options={[
                        {
                          value: "1",
                          content: "1",
                        },
                        {
                          value: "2",
                          content: "2",
                        },
                      ]}
                    />
                  </div>
                  {/* <div className="mt-4">
                  <FieldSet
                    error={formik.errors.display_order}
                    label={"Display order (override):"}
                    name="display_order"
                    layoutHorizontal
                    showLabel
                    type="number"
                    additionalInformation="Set the numerical position the question should appear in the assessment flow"
                    inputConfig={{
                      min: "0",
                      step: "1",
                    }}
                  />
                </div> */}
                </div>
                {props.isLoading ? (
                  <div className="absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center p-10">
                    <div className={`${Theme["bg-primary"]} opacity-50 absolute top-0 bottom-0 left-0 right-0`}></div>
                    <span className="inline-block w-6 h-6">
                      <Loader />
                    </span>
                  </div>
                ) : null}
                <div className="my-2 flex items-center justify-end">
                  <TertiaryButton type="button" onClick={() => props.onClose()} className="mr-2">
                    Cancel
                  </TertiaryButton>
                  <PrimaryButton type="submit">Create</PrimaryButton>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default CreateQuestionModal;
