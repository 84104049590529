import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_SERVICE_LOGS_INDEX } from "../../endpoints";
import { ServiceLogsIndexResponseInterface } from "../../interfaces/ServiceLogsIndexResponseInterface";

export type GetServiceLogsIndexParamsType = {
  page: number;
  per_page: number;
};

type GetServiceLogsIndexConfig = {
  id: string;
  params: GetServiceLogsIndexParamsType;
};

interface useRequestServiceLogsIndexInterface<T>
  extends useRequestServiceImplementation<T> {
  ServiceLogs: T | null;
  getServiceLogsIndex: (config: GetServiceLogsIndexConfig) => void;
}

export const useRequestServiceLogsIndex = <
  T extends ServiceLogsIndexResponseInterface
>(): useRequestServiceLogsIndexInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getServiceLogsIndex = useCallback(
    (config: GetServiceLogsIndexConfig) =>
      sendGetRequest(
        generateCompleteURL(
          GET_SERVICE_LOGS_INDEX,
          { id: config.id },
          { page: config.params.page, per_page: config.params.per_page }
        ),
        (data) => setData(data),
        {
          bearer: getAccessToken() as string,
        }
      ),
    [getAccessToken, sendGetRequest]
  );

  return {
    ServiceLogs: Data,
    IsLoading,
    RequestError,
    getServiceLogsIndex,
  };
};
