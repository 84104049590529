export const GET_AML_CHECKS = "/checks";

export const AMLChecksEndpoints = {
  index: {
    get: "/assessment-groups/:assessment_group_id/aml-check",
  },
  vendor: {
    get: "/aml-check/:check_id?vendor=true",
  },
  visualAuthenticity: {
    get: "/aml-check/:check_id/visual-authenticity",
  },
  check: {
    patch: "/aml-check/:check_id",
    search: "/aml-check/:ref",
  },
};
