import Page from "components/UI/Page/Page";
import PaginationNav from "components/UI/PaginationNav/PaginationNav";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { useStateWithCallback } from "hooks/useStateWithCallback/useStateWithCallback";
import { produce } from "immer";
import { useCallback } from "react";
import AppsTable from "../../components/AppsTable/AppsTable";
import AppsTableTopNav from "../../components/AppsTableTopNav/AppsTableTopNav";
import { GetAppsIndexParamsType, useRequestAppsIndex } from "../../services/useRequestAppsIndex/useRequestAppsIndex";

const AppsIndex = () => {
  const { Apps, getAppsIndex, RequestError, IsLoading } = useRequestAppsIndex();

  const [CurrentParams, setCurrentParams] = useStateWithCallback<GetAppsIndexParamsType>({
    page: 1,
    per_page: 25,
    q: "",
  });

  const handleGoToPage = useCallback(
    (page: number) => {
      setCurrentParams(
        (params) =>
          produce(params, (draft) => {
            draft.page = page;
          }),
        (update) =>
          getAppsIndex({
            params: {
              ...update,
            },
          }),
      );
    },
    [getAppsIndex, setCurrentParams],
  );

  const handleQuerySearch = useCallback(
    (query: string) => {
      setCurrentParams(
        (params) =>
          produce(params, (draft) => {
            draft.page = 1;
            draft.q = query;
          }),
        (update) =>
          getAppsIndex({
            params: {
              ...update,
            },
          }),
      );
    },
    [getAppsIndex, setCurrentParams],
  );

  useMountEffect(() =>
    getAppsIndex({
      params: {
        ...CurrentParams,
      },
    }),
  );

  return (
    <Page pageTitle="All Tenants" isLoadingContent={IsLoading} hasError={RequestError}>
      {Apps && (
        <>
          <AppsTableTopNav
            total={Apps.total}
            currentQuery={CurrentParams.q ?? ""}
            handleQuerySearch={handleQuerySearch}
          />
          <AppsTable logs={Apps.data} />
          <PaginationNav handleGoToPage={handleGoToPage} lastPage={Apps.last_page} currentPage={Apps.current_page} />
        </>
      )}
    </Page>
  );
};

export default AppsIndex;
