import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import {useState} from "react";
import {EditableTextField} from "../../../AssessmentGroups/components/EditableAssessmentGroupFields/EditableTextField";
import {useUpdateAssessmentService} from "../../services/useUpdateAssessmentService";

interface IProps {
  readonly name: string,
  readonly content: string;
  readonly keyName: string;
  readonly assessmentId: string;
}

export const EditableTextEntityIdRow = ({name, content, keyName, assessmentId}: IProps) => {
  const [currentValue, setCurrentValue] = useState(content);

  const {IsUpdating, UpdateError, updateAssessment} = useUpdateAssessmentService();

  const onUpdate = (newValue: string) => {
    updateAssessment(
      {
        assessmentId,
        keyName,
        value: newValue
      },
      () => {
        setCurrentValue(newValue);
      },
    );
  }

  return (
    <TableRow>
      <TableData>{name}:</TableData>
      <TableData>
        <EditableTextField value={currentValue} onConfirm={onUpdate} isLoading={IsUpdating}/>
        {UpdateError && (
          <div className="mt-2">
            <NegativeMessage content={UpdateError.message}/>
          </div>
        )}
      </TableData>
    </TableRow>
  );
}