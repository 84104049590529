import { ClipboardListIcon } from "@heroicons/react/outline";
import { NavigationItemType } from "modules/NavigationTree";
import {
  AML_ANSWER_OPTION_TYPES,
  AML_ASSESSMENT_TYPES,
  AML_ENTITY_TYPES,
  AML_TEMPLATES,
  RiskAssessmentRoutes
} from "./routes";
import { AMLCheckRoutes } from "../AMLChecks/routes";
import {AML, PROJECTS} from "../routes";

export const AMLProjectNavigationTree: Array<NavigationItemType> = [
  {
    name: "Risk Assessments",
    urlPath: PROJECTS,
    icon: <ClipboardListIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [
      {
        name: "Entity Types",
        urlPath: AML_ENTITY_TYPES,
        isLink: true,
      },
      {
        name: "Assessment Types",
        urlPath: AML_ASSESSMENT_TYPES,
        isLink: true,
      },
      {
        name: "Answer Option Types",
        urlPath: AML_ANSWER_OPTION_TYPES,
        isLink: true,
      },
      {
        name: "Templates",
        urlPath: AML_TEMPLATES,
        isLink: true,
      },
      {
        name: "Tenants",
        urlPath: RiskAssessmentRoutes.tenants.index,
        isLink: true,
      },
    ],
  },
  {
    name: "AML Checks",
    urlPath: PROJECTS,
    icon: <ClipboardListIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [
      {
        name: "View all Tenants",
        urlPath: AMLCheckRoutes.tenants.index,
        isLink: true,
      },
      {
        name: "Search Checks",
        urlPath: AMLCheckRoutes.checks.search,
        isLink: true,
      },
    ],
  },
];
