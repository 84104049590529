import React from "react";
import { twMerge } from "tailwind-merge";

/**
 * * Base Button styling and definition
 */

type ButtonFontSizeType = "large" | "medium" | "small";

export interface ButtonInterface extends React.ComponentPropsWithoutRef<"button"> {
  children: React.ReactNode;
  contentClassName?: string;
  icon?: string | React.ReactNode;
  iconAlign?: "left" | "right";
  iconSplit?: boolean;
  size?: ButtonFontSizeType;
  shouldOpaqueOnDisable?: boolean;
}

/**
 *
 * @param {ButtonFontSizeType} size? Get font size based on provided button size
 * @returns
 */
const getButtonFontSize = (size?: ButtonFontSizeType) => {
  switch (size) {
    case "large":
      return "text-lg leading-[24px]";
    case "medium":
      return "text-base leading-[21px]";
    case "small":
      return "text-sm leading-[21px]";
    default:
      return "text-base leading-[21px]";
  }
};

/**
 *
 * @param {ButtonFontSizeType} size? Get dimensions based on provided button size
 * @returns
 */
const getButtonDimensions = (size?: ButtonFontSizeType) => {
  switch (size) {
    case "large":
      return "h-[48px]";
    case "medium":
      return "h-[40px]";
    case "small":
      return "h-[32px]";
    default:
      return " h-[40px]";
  }
};

/**
 *
 * @param props
 * @returns
 */
const getButtonPaddingSize = (size?: ButtonFontSizeType, iconSplit?: boolean) => {
  switch (size) {
    case "large":
      return `px-300 ${iconSplit ? "pr-200" : ""}`;
    case "medium":
      return `px-200 ${iconSplit ? "pr-200" : ""}`;
    case "small":
      return `px-200 ${iconSplit ? "pr-150" : ""}`;
    default:
      return `px-300 ${iconSplit ? "pr-200" : ""}`;
  }
};

/**
 * Base Button component with props for defining appearance
 * Avoid using this directly, use one of the provided Primary / Secondary or Tertiary buttons
 * @param {ButtonInterface}
 * @returns
 */
const Button = (props: ButtonInterface) => {
  // * Default to opaque on disable if prop not provided
  const shouldOpaqueOnDisable = props.shouldOpaqueOnDisable !== undefined ? props.shouldOpaqueOnDisable : true;

  return (
    <button
      className={twMerge(
        `flex justify-center items-center border-2 border-neutral-900 hover:border-neutral-800 disabled:border-neutral-200 bg-neutral-900 hover:bg-neutral-800 disabled:bg-neutral-200 rounded-[4px] text-white disabled:text-black text-center font-700 focus:ring-focus-500 focus:ring-2 ${getButtonDimensions(
          props.size,
        )} ${getButtonFontSize(props.size)} ${getButtonPaddingSize(props.size, props.iconSplit)}`,
        props.className || "",
      )}
      disabled={props.disabled}
      type={props.type || "button"}
      onClick={props.onClick}
    >
      {props.icon && props.iconAlign !== "right" && !props.iconSplit ? (
        <span
          className={`inline-block h-200 w-200 mr-100 ${props.disabled && shouldOpaqueOnDisable ? "opacity-30" : ""}`}
        >
          {props.icon}
        </span>
      ) : null}
      <span
        className={twMerge(
          `text-current ${props.disabled && shouldOpaqueOnDisable ? "opacity-30" : ""}`,
          props.contentClassName || "",
        )}
      >
        {props.children}
      </span>
      {props.icon && (props.iconAlign === "right" || props.iconSplit) ? (
        <span
          className={`ml-100 inline-block ${
            props.iconSplit
              ? `flex items-center justify-center self-stretch ${
                  props.size === "small" ? "ml-150 pl-150" : "ml-200 pl-200"
                } border-l-2 border-current my-[2px]`
              : "h-200 w-200 inline-block"
          } ${props.disabled && shouldOpaqueOnDisable ? "opacity-30" : ""}`}
        >
          <span className={`${props.iconSplit ? "h-200 w-200 inline-block" : ""}`}>{props.icon}</span>
        </span>
      ) : null}
    </button>
  );
};

export default Button;
