import ErrorBoundary from "errors/ErrorBoundary/ErrorBoundary";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import AppHeader from "../AppHeader/AppHeader";
import AppNavigation from "../AppNavigation/AppNavigation";

const Layout = () => {
  /**
   * * Condense view
   */
  const [IsCondensed, setIsCondensed] = useState<boolean>(false);

  /**
   * * Toggle condense view
   */
  const toggleCondense = () => setIsCondensed(!IsCondensed);

  /**
   * * Get the theme
   */
  const { Theme } = useTheme();

  return (
    <div className={`relative min-h-screen ${Theme["bg-primary"]}`}>
      {/* * Header */}
      <AppHeader />

      <div className="pt-10">
        {/* * Sidebar*  */}
        <AppNavigation
          condensed={IsCondensed}
          toggleCondense={toggleCondense}
        />

        <div className={IsCondensed ? "ml-16" : "ml-60"}>
          {/* * Main content */}
          <main className="p-8">
            {/* * View error boundary */}
            <ErrorBoundary>
              {/* * Router outlet */}
              <Outlet />
            </ErrorBoundary>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
