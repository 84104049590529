import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { DELETE_ANSWER_OPTION, GET_ANSWER_OPTION, PATCH_ANSWER_OPTION, POST_ANSWER_OPTION } from "../../endpoints";
import { AMLAnswerOptionInterface, AnswerOptionRuleSetInterface } from "../../interfaces/AMLAnswerOptionInterface";

type SubmitAnswerOptionType = {
  default_answer: Array<string>;
  options: { [key: string]: string } | null;
  rules: AnswerOptionRuleSetInterface | null;
  answer_option_type_id: string;
};

interface useRequestAMLAnswerOptionInterface<T> extends useRequestServiceImplementation<T> {
  AnswerOptions: Array<T>;
  getAnswerOptionsShow: (answer_option_id: string) => void;
  postAnswerOption: (config: SubmitAnswerOptionType) => void;
  patchAnswerOption: (id: string, config: SubmitAnswerOptionType) => void;
  deleteAnswerOption: (id: string) => void;
}

export const useRequestAMLAnswerOptions = <
  T extends AMLAnswerOptionInterface,
>(): useRequestAMLAnswerOptionInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get answerOptions
   */
  const handleOnGetAnswerOptionsResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all answerOptions
   */
  const getAnswerOptionsShow = useCallback(
    (answer_option_id: string) =>
      sendGetRequest(
        generateCompleteURL(
          GET_ANSWER_OPTION,
          {
            id: answer_option_id,
          },
          {},
        ),
        (response) => handleOnGetAnswerOptionsResponse(response),
        {},
      ),
    [handleOnGetAnswerOptionsResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a answerOption
   */
  const handleOnPostAnswerOption = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Post a new answerOption
   */
  const postAnswerOption = useCallback(
    (config: SubmitAnswerOptionType) =>
      sendPostRequest(
        generateCompleteURL(POST_ANSWER_OPTION, {}, {}),
        { ...config },
        (response) => handleOnPostAnswerOption(response),
        {},
      ),
    [handleOnPostAnswerOption, sendPostRequest],
  );

  /**
   * * Handle on patching a answerOption
   */
  const handleOnPatchAnswerOption = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a answerOption
   */
  const patchAnswerOption = useCallback(
    (id: string, config: SubmitAnswerOptionType) => {
      sendPatchRequest(
        generateCompleteURL(PATCH_ANSWER_OPTION, { id }, {}),
        { ...config },
        (response) => handleOnPatchAnswerOption(response),
        {},
      );
    },

    [handleOnPatchAnswerOption, sendPatchRequest],
  );

  /**
   * * Handle on deleting a answerOption
   */
  const handleOnDeleteAnswerOption = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a answerOption
   */
  const deleteAnswerOption = useCallback(
    (id: string) =>
      sendDeleteRequest(
        generateCompleteURL(DELETE_ANSWER_OPTION, { id }, {}),
        () => handleOnDeleteAnswerOption(id),
        {},
      ),
    [handleOnDeleteAnswerOption, sendDeleteRequest],
  );

  return {
    AnswerOptions: Resource,
    IsLoading,
    RequestError,
    getAnswerOptionsShow,
    postAnswerOption,
    patchAnswerOption,
    deleteAnswerOption,
  };
};
