import { twMerge } from "tailwind-merge";
import { ButtonInterface } from "../Button/Button";
import SecondaryButton from "../SecondaryButton/SecondaryButton";

/**
 * * Tertiary styled button
 * @param {ButtonInterface}
 * @returns
 */
const TertiaryButton = (props: ButtonInterface) => (
  <SecondaryButton
    {...props}
    className={twMerge(
      "border-0 disabled:bg-transparent",
      props.className || ""
    )}
  />
);

export default TertiaryButton;
