import { twMerge } from "tailwind-merge";
import Button, { ButtonInterface } from "../Button/Button";

/**
 * * Secondary styled button
 * @param {ButtonInterface}
 * @returns
 */
const SecondaryButton = (props: ButtonInterface) => (
  <Button
    {...props}
    className={twMerge(
      "bg-white text-black hover:bg-neutral-200 border-neutral-900 hover:border-neutral-900 disabled:bg-opacity-30",
      props.className || ""
    )}
  />
);

export default SecondaryButton;
