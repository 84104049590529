import { AMLCHECKS } from "../routes";

export const AMLCheckRoutes = {
  tenants: {
    index: `${AMLCHECKS}/tenants`,
  },
  assessment_groups: {
    index: `${AMLCHECKS}/tenants/:tenant_id/assessment-groups`,
  },
  checks: {
    index: `${AMLCHECKS}/tenants/:tenant_id/assessment_groups/:assessment_group_id`,
    search: `${AMLCHECKS}/search`,
  },
};
