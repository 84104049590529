import { PlusCircleIcon } from "@heroicons/react/outline";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import Loader from "components/UI/Loader/Loader";
import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { determineNextAvailableDisplayOrderValue } from "modules/Projects/AML/utilities/determineNextAvailableDisplayOrderValue/determineNextAvailableDisplayOrderValue";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { createContext, useCallback, useEffect, useState } from "react";
import { findById } from "utilities/findById/findById";
import { sortByDisplayOrder } from "utilities/sortByDisplayOrder/sortByDisplayOrder";
import { AMLQuestionInterface } from "../../interfaces/AMLQuestionInterface";
import { useRequestAMLQuestions } from "../../services/useRequestAMLQuestions/useRequestAMLQuestions";
import CreateQuestionModal from "../CreateQuestionModal/CreateQuestionModal";
import DeleteQuestionModal from "../DeleteQuestionModal/DeleteQuestionModal";
import EditQuestionModal from "../EditQuestionModal/EditQuestionModal";
import QuestionTable from "../QuestionTable/QuestionTable";

export const QuestionsContext = createContext<{
  questions: Array<AMLQuestionInterface>;
  currentQuestion: AMLQuestionInterface | undefined;
}>({
  questions: [],
  currentQuestion: undefined,
});

type QuestionsListProps = {
  sectionId: string;
};

const QuestionsList = (props: QuestionsListProps) => {
  const { Theme } = useTheme();

  // * Require questions service
  const {
    IsLoading,
    Questions,
    RequestError,
    getQuestionsIndex,
    postQuestion,
    patchQuestion,
    deleteQuestion,
    LastCreatedQuestionId,
    clearLastCreatedQuestionId,
  } = useRequestAMLQuestions();

  const [EditQuestionContext, setEditQuestionContext] = useState<
    | {
        context: "create";
        question: {
          sectionId: string;
        };
      }
    | {
        context: "edit" | "delete";
        question: AMLQuestionInterface;
      }
    | null
  >(null);

  useMountEffect(() => getQuestionsIndex(props.sectionId));

  const handleCreate = useCallback(
    () =>
      setEditQuestionContext({
        context: "create",
        question: { sectionId: props.sectionId },
      }),
    [props.sectionId],
  );

  const handleConfirmCreate = useCallback(
    (values: {
      value: string;
      guidance: string;
      note: string;
      level: "1" | "2";
      allow_multiple: boolean;
      sectionId: string;
    }) => {
      postQuestion({
        value: values.value,
        guidance: values.guidance,
        note: values.note,
        level: values.level,
        allow_multiple: values.allow_multiple,
        is_default: true,
        section_id: values.sectionId,
        display_order: determineNextAvailableDisplayOrderValue(Questions),
      });
    },
    [Questions, postQuestion],
  );

  const handleEdit = useCallback(
    (id: string) => {
      const Question = findById(Questions, id);

      if (Question) {
        setEditQuestionContext({
          context: "edit",
          question: Question,
        });
      }
    },
    [Questions],
  );

  // * If successful created a question, then jump to edit
  useEffect(() => {
    if (EditQuestionContext && EditQuestionContext.context === "create" && LastCreatedQuestionId && !IsLoading) {
      handleEdit(LastCreatedQuestionId);
      // * clear the last created reference as we won't need it again
      clearLastCreatedQuestionId();
    }
  }, [EditQuestionContext, IsLoading, LastCreatedQuestionId, clearLastCreatedQuestionId, handleEdit]);

  const handleConfirmEdit = (values: {
    readonly id: string;
    readonly value: string;
    readonly guidance: string;
    readonly note: string;
    readonly level: 1 | 2;
    readonly allow_multiple: boolean;
    readonly display_order: string | number;
    readonly minor_update: boolean;
  }) => {
    patchQuestion(values.id, {
      value: values.value,
      guidance: values.guidance,
      note: values.note,
      level: values.level,
      allow_multiple: values.allow_multiple,
      display_order: typeof values.display_order === "string" ? parseInt(values.display_order) : values.display_order,
      minor_update: values.minor_update,
    });
    clearEditQuestionContext();
  };

  const handleDelete = useCallback(
    (id: string) => {
      const Question = findById(Questions, id);

      if (Question) {
        setEditQuestionContext({
          context: "delete",
          question: Question,
        });
      }
    },
    [Questions],
  );

  const handleConfirmDelete = useCallback(
    (id: string) => {
      deleteQuestion(id);
      clearEditQuestionContext();
    },
    [deleteQuestion],
  );

  const clearEditQuestionContext = () => setEditQuestionContext(null);

  return (
    <div className="relative min-h-[150px]">
      {IsLoading ? (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center items-center p-10">
          <div className={`${Theme["bg-primary"]} opacity-50 absolute top-0 bottom-0 left-0 right-0`}></div>
          <span className="inline-block w-6 h-6">
            <Loader />
          </span>
        </div>
      ) : null}
      {RequestError ? <NegativeMessage title="Error" content={RequestError.message} /> : null}
      {[...Questions].sort(sortByDisplayOrder).map((question) => (
        <QuestionTable question={question} key={question.id} handleEdit={handleEdit} handleDelete={handleDelete} />
      ))}
      {!IsLoading && (
        <div className="flex justify-end mt-4">
          <PrimaryButton onClick={handleCreate} title="Create Assessment type" icon={<PlusCircleIcon />}>
            New
          </PrimaryButton>
        </div>
      )}
      {EditQuestionContext && (
        <>
          {EditQuestionContext.context === "create" && (
            <CreateQuestionModal
              onClose={clearEditQuestionContext}
              onSubmit={handleConfirmCreate}
              sectionId={EditQuestionContext.question.sectionId}
              isLoading={IsLoading}
            />
          )}
          {EditQuestionContext.context === "edit" && (
            <QuestionsContext.Provider
              value={{
                questions: Questions,
                currentQuestion: EditQuestionContext.question,
              }}
            >
              <EditQuestionModal
                question={EditQuestionContext.question}
                onClose={clearEditQuestionContext}
                onSubmit={handleConfirmEdit}
              />
            </QuestionsContext.Provider>
          )}
          {EditQuestionContext.context === "delete" && (
            <DeleteQuestionModal
              question={EditQuestionContext.question}
              onClose={clearEditQuestionContext}
              onDelete={handleConfirmDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

export default QuestionsList;
