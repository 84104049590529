import axios from "axios";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { WORDPRESS_LOGS_INDEX } from "modules/Tenants/App/routes";
import { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import useAuth from "../../../../../../providers/AuthStoreProvider/useAuth";
import { DELETE_APP, POST_DUPLICATE_APP, POST_RENAME_APP } from "../../endpoints";
import { AppsIndexInterface } from "../../interfaces/AppsIndexInterface";

type AppsTableRowProps = {
  log: AppsIndexInterface;
};

function containsSpecialChars(str: string) {
  const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
  return specialChars.test(str);
}

function useForceUpdate() {
  const [_, setValue] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-shadow
  return () => setValue((value) => value + 1);
}

const AppsTableRow = function (props: AppsTableRowProps) {
  const { getAccessToken } = useAuth();
  const forceUpdate = useForceUpdate();

  const deleteApp = useCallback(
    (log: AppsIndexInterface) => {
      const accessToken = getAccessToken() || "";

      const config = {
        headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
      };

      if (log.deleted) {
        if (confirm("Are you sure you want to restore this tenant?")) {
          axios
            .delete(generateCompleteURL(DELETE_APP, { id: log.id.toString() }, {}), config)
            .then((data) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (data.data.success) {
                alert("The tenant has been successfully restored.");
                log.deleted = false;
                forceUpdate();
              } else {
                alert("Something went wrong.");
              }
            })
            .catch(console.log);
        }
      } else {
        if (confirm("Are you sure you want to delete this tenant?")) {
          axios
            .delete(generateCompleteURL(DELETE_APP, { id: log.id.toString() }, {}), config)
            .then((data) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (data.data.success) {
                alert("The tenant has been successfully deleted.");
                log.deleted = true;
                forceUpdate();
              } else {
                alert("Something went wrong.");
              }
            })
            .catch(console.log);
        }
      }
    },
    [forceUpdate, getAccessToken],
  );

  const duplicateApp = useCallback(
    (log: AppsIndexInterface) => {
      if (confirm("Are you sure you want to duplicate this tenant?")) {
        const slug = prompt("Please enter a path for the new tenant", "");

        if (slug === null || slug.length < 2 || slug.indexOf(" ") !== -1 || containsSpecialChars(slug)) {
          alert("The path specified is invalid.");
          return;
        }

        alert("Duplicating tenant, please wait as this might take a while.");

        const accessToken = getAccessToken() || "";

        const config = {
          headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        };

        axios
          .post(generateCompleteURL(POST_DUPLICATE_APP, { id: log.id.toString(), slug }, {}), null, config)
          .then((data) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (data.data.success) {
              alert("The tenant has been successfully duplicated.");
            } else {
              alert("Something went wrong.");
            }
          })
          .catch(console.log);
      }
    },
    [getAccessToken],
  );

  const renameApp = useCallback(
    (log: AppsIndexInterface) => {
      if (confirm("Are you sure you want to rename this tenant?")) {
        const slug = prompt("Please enter a new path for the tenant", "");

        if (slug === null || slug.length < 2 || slug.indexOf(" ") !== -1 || containsSpecialChars(slug)) {
          alert("The path specified is invalid.");
          return;
        }

        alert("Renaming tenant, please wait as this might take a while.");

        const accessToken = getAccessToken() || "";

        const config = {
          headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
        };

        axios
          .post(generateCompleteURL(POST_RENAME_APP, { id: log.id.toString(), slug }, {}), null, config)
          .then((data) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (data.data.success) {
              alert("The tenant has been successfully renamed.");
            } else {
              alert("Something went wrong.");
            }
          })
          .catch(console.log);
      }
    },
    [getAccessToken],
  );

  return (
    <TableRow>
      <TableData>
        <Link to={WORDPRESS_LOGS_INDEX.replace(/:id/, props.log.id)} className="block">
          {props.log.slug}
          {props.log.deleted ? <span className="text-red-500"> (deleted)</span> : null}
        </Link>
      </TableData>
      <TableData>
        <div className="block text-right">
          <a
            href={`https://app.${props.log.type}.com/${props.log.slug}/wp-admin/`}
            target="_blank"
            rel="noreferrer"
            className="inline-block text-right mr-4"
          >
            <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">Admin</span>
          </a>
          <Link to={WORDPRESS_LOGS_INDEX.replace(/:id/, props.log.id)} className="inline-block text-right mr-4">
            <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">Logs</span>
          </Link>
          <a
            href="javascript:void(0)"
            onClick={() => {
              renameApp(props.log);
            }}
            className="inline-block text-right mr-4"
          >
            <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">Rename</span>
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => {
              duplicateApp(props.log);
            }}
            className="inline-block text-right mr-4"
          >
            <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">Duplicate</span>
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => {
              deleteApp(props.log);
            }}
            className="inline-block text-right"
          >
            <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">
              {props.log.deleted ? "Restore" : "Delete"}
            </span>
          </a>
        </div>
      </TableData>
    </TableRow>
  );
};

export default AppsTableRow;
