import LoginForm from "components/LoginForm/LoginForm";
import useTheme from "providers/ThemeStoreProvider/useTheme";

const Login = () => {
  const { Theme } = useTheme();

  return (
    <div
      className={`w-screen min-h-screen flex items-center justify-center ${Theme["bg-primary"]}`}
    >
      <LoginForm />
    </div>
  );
};

export default Login;
