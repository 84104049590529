import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_CRON_LOGS_INDEX } from "../../endpoints";
import { CronLogsIndexResponseInterface } from "../../interfaces/CronLogsIndexResponseInterface";

interface useRequestCronIndexInterface<T>
  extends useRequestServiceImplementation<T> {
  CronLogs: T | null;
  getCronLogsIndex: () => void;
}

export const useRequestCronLogsIndex = <
  T extends CronLogsIndexResponseInterface
>(): useRequestCronIndexInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getCronLogsIndex = useCallback(
    () =>
      sendGetRequest(
        generateCompleteURL(GET_CRON_LOGS_INDEX, {}, {}),
        (data) => setData(data),
        {
          bearer: getAccessToken() as string,
        }
      ),
    [getAccessToken, sendGetRequest]
  );

  return {
    CronLogs: Data,
    IsLoading,
    RequestError,
    getCronLogsIndex,
  };
};
