import { LinkWithSearchQuery } from "components/UI/LinkWithSearchQuery/LinkWithSearchQuery";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLCheckRoutes } from "modules/Projects/AMLChecks/routes";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { ITenant } from "../../interfaces/ITenant";

interface IProps {
  readonly tenant: ITenant;
}

export const TenantsTableRow = ({ tenant }: IProps) => {
  const Route = generateCompleteURL(
    AMLCheckRoutes.assessment_groups.index,
    {
      tenant_id: tenant.id,
    },
    {},
  );

  return (
    <TableRow>
      <TableData>
        <LinkWithSearchQuery to={Route} className="block">
          {tenant.name}
          {tenant.deleted_at ? <span className="text-red-500"> (deleted)</span> : null}
        </LinkWithSearchQuery>
      </TableData>
      <TableData>{tenant.external_id}</TableData>
      <TableData>
        {tenant.is_sfa ? (
          <span className="bg-green-500 bold text-white inline-block px-1 rounded-sm ml-2">SfA</span>
        ) : null}
      </TableData>
      <TableData>
        <LinkWithSearchQuery to={Route} className="block text-right">
          <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">View Assessment Groups</span>
        </LinkWithSearchQuery>
      </TableData>
    </TableRow>
  );
};
