/* eslint-disable no-debugger */
import { produce } from "immer";
import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";
import { duplicateRuleset, updateRuleset } from "../../utilities/rulesetUtilities";

type FreeTypeConfigType = {
  onChange: (answerOption: AMLAnswerOptionInterface) => void;
  answerOption: AMLAnswerOptionInterface;
};

/**
 * Free Type configuration
 * No current rules need setting up for a FreeType as it's free entry for a user
 */
const FreeTypeConfig = (props: FreeTypeConfigType) => {
  const { rules } = props.answerOption;

  const handleSelectAllowMultiple = () => {
    props.onChange(
      produce(props.answerOption, (draft) => {
        draft.rules = updateRuleset(duplicateRuleset(rules), (dt) => {
          if ("allow_multiple" in dt) {
            // * if it already exists, toggle it
            dt.allow_multiple = !dt.allow_multiple;
          } else {
            // * Otherwise it doesn't exist so set it to true
            dt.allow_multiple = true;
          }
        });
      }),
    );
  };

  return (
    <div className="p-2">
      <p className="text-sm text-center">
        Freetype is a simple text box that the user can freely enter any response into.
      </p>
      <div className="p-2 flex items-center justify-center">
        <label className="text-sm">
          <input
            className="mr-2"
            type="checkbox"
            onChange={handleSelectAllowMultiple}
            checked={rules && "allow_multiple" in rules ? rules.allow_multiple : false}
          />
          Allow entering multiple response for this question?
        </label>
      </div>
    </div>
  );
};

export default FreeTypeConfig;
