// * AML Routes

import { AML } from "../routes";

export const AML_ENTITY_TYPES = `${AML}/entity-types`;
export const AML_ASSESSMENT_TYPES = `${AML}/assessment-types`;
export const AML_ANSWER_OPTION_TYPES = `${AML}/answer-option-types`;
export const AML_TEMPLATES = `${AML}/templates`;
export const AML_TEMPLATE = `${AML_TEMPLATES}/:id`;
export const RiskAssessmentRoutes = {
    tenants: {
        index: `${AML}/tenants`
    },
    assessment_groups: {
        index: `${AML}/tenants/:tenant_id/assessment-groups`,
    },
    assessments: {
        index: `${AML}/tenants/:tenant_id/assessment-groups/:assessment_group_id`
    }
};