import { AMLEndpointEnvironmentType } from "./interfaces/AMLEndpointEnvironmentType";
import {
  AML_ENDPOINT_ENV_LOCALHOST,
  AML_ENDPOINT_ENV_PROD,
  AML_ENDPOINT_ENV_QA,
  AML_ENDPOINT_ENV_STAGING,
} from "./interfaces/TypeKeys/AMLEndpointEnvironmentTypeKeys";

export const AML_ENDPOINT_LOCALHOST: string =
  process.env.REACT_APP_AML_ENDPOINT_LOCALHOST ?? "";
export const AML_ENDPOINT_QA: string =
  process.env.REACT_APP_AML_ENDPOINT_QA ?? "";
export const AML_ENDPOINT_STAGING: string =
  process.env.REACT_APP_AML_ENDPOINT_STAGING ?? "";
export const AML_ENDPOINT_PRODUCTION: string =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_AML_ENDPOINT_LOCALHOST ?? ""
    : process.env.REACT_APP_AML_ENDPOINT_PRODUCTION ?? "";

export const AML_ENDPOINTS: Array<{
  name: AMLEndpointEnvironmentType;
  title: string;
  endpoint: string;
}> = [
  {
    name: AML_ENDPOINT_ENV_QA,
    title: "QA",
    endpoint: AML_ENDPOINT_QA,
  },
  {
    name: AML_ENDPOINT_ENV_STAGING,
    title: "Staging",
    endpoint: AML_ENDPOINT_STAGING,
  },
  {
    name: AML_ENDPOINT_ENV_PROD,
    title: "Production",
    endpoint: AML_ENDPOINT_PRODUCTION,
  },
  {
    name: AML_ENDPOINT_ENV_LOCALHOST,
    title: "Localhost",
    endpoint: AML_ENDPOINT_LOCALHOST,
  },
];
