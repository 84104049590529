import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import TableData from "components/UI/Table/TableData/TableData";
import {useState} from "react";
import {useUpdateAssessmentGroups} from "../../services/useUpdateAssessmentGroups";
import {EditableTextField} from "../EditableAssessmentGroupFields/EditableTextField";

interface IProps {
  readonly content: string;
  readonly keyName: string;
  readonly assessmentGroupId: string;
  readonly tenantId: string;
}

export const EditableTextEntityIdRow = ({content, keyName, assessmentGroupId, tenantId}: IProps) => {
  const [currentValue, setCurrentValue] = useState(content);

  const {IsLoading, IsUpdating, RequestError, updateAssessmentGroup} = useUpdateAssessmentGroups();

  const onUpdate = (newValue: string) => {
    updateAssessmentGroup(
      {
        assessmentGroupId,
        keyName,
        value: newValue,
        tenantId
      },
      () => {
        setCurrentValue(newValue);
      },
    );
  }

  return (
    <TableData>
      <EditableTextField value={currentValue} onConfirm={onUpdate} isLoading={IsLoading} isUpdating={IsUpdating}/>
      {RequestError && (
        <div className="mt-2">
          <NegativeMessage content={RequestError.message}/>
        </div>
      )}
    </TableData>
  );
}