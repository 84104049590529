import { ClipboardListIcon } from "@heroicons/react/outline";
import { NavigationItemType } from "modules/NavigationTree";
import { LOGS_SERVICES_ALL, SERVICES } from "./routes";
import LoadServicesNavigationTreeItems from "./ServiceLogs/components/LoadServicesNavigationTreeItems/LoadServicesNavigationTreeItems";

export const ServiceLogsNavigationTree: Array<NavigationItemType> = [
  {
    name: "Services",
    urlPath: SERVICES,
    icon: <ClipboardListIcon className="inline-block w-5 h-5 align-text-bottom" />,
    children: [
      {
        name: "View All",
        urlPath: LOGS_SERVICES_ALL,
        isLink: true,
      },
      {
        name: "Services",
        isLoader: true,
        el: <LoadServicesNavigationTreeItems />,
      },
    ],
  },
];
