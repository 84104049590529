import { LinkWithSearchQuery } from "components/UI/LinkWithSearchQuery/LinkWithSearchQuery";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLCheckRoutes } from "modules/Projects/AMLChecks/routes";
import { useParams } from "react-router-dom";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { IAssessmentGroup } from "../../interfaces/IAssessmentGroup";

interface IProps {
  readonly assessmentGroup: IAssessmentGroup;
}

export const AssessmentGroupsTableRow = ({ assessmentGroup }: IProps) => {
  const { tenant_id } = useParams<{ tenant_id: string }>();

  const AMLCheckURL = generateCompleteURL(
    AMLCheckRoutes.checks.index,
    {
      tenant_id: tenant_id as string,
      assessment_group_id: assessmentGroup.id,
    },
    {},
  );

  return (
    <TableRow>
      <TableData>
        <LinkWithSearchQuery to={AMLCheckURL} className="block">
          {assessmentGroup.name}
        </LinkWithSearchQuery>
      </TableData>
      <TableData>{assessmentGroup.entity_id}</TableData>
      <TableData>
        <LinkWithSearchQuery to={AMLCheckURL} className="block text-right">
          <span className="text-positive-500 hover:text-positive-600 whitespace-nowrap">View AML Checks</span>
        </LinkWithSearchQuery>
      </TableData>
    </TableRow>
  );
};
