import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { useState } from "react";
import { useUpdateCheckService } from "../../services/useUpdateCheckService/useUpdateCheckService";
import { EditableTextField } from "../EditableCheckFields/EditableTextField";

interface IProps {
  readonly content: string;
  readonly name: string;
  readonly keyName: string;
  readonly checkId: string;
}

export const EditableTextCheckRow = ({ content, keyName, name, checkId }: IProps) => {
  const [currentValue, setCurrentValue] = useState(content);

  const { IsLoading, RequestError, updateCheck } = useUpdateCheckService();

  const onUpdate = (newValue: string) => {
    updateCheck(
      {
        checkId,
        keyName,
        value: newValue,
      },
      () => {
        setCurrentValue(newValue);
      },
    );
  };

  return (
    <>
      <TableRow>
        <TableData>{name}:</TableData>
        <TableData>
          <EditableTextField value={currentValue} onConfirm={onUpdate} isLoading={IsLoading} />
          {RequestError && (
            <div className="mt-2">
              <NegativeMessage content={RequestError.message} />
            </div>
          )}
        </TableData>
      </TableRow>
    </>
  );
};
