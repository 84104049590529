/**
 * * Store for tokens required throughout the app
 */
import { AMLEndpointEnvironmentType } from "modules/Projects/AML/interfaces/AMLEndpointEnvironmentType";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { useRequestLogin } from "services/useRequestLogin/useRequestLogin";
import { AMLTokensInterface, InitialAuthState } from "./AuthState";
import * as AUTH_STORE_ACTIONS from "./AuthStoreActions";
import AuthStoreContext, { AuthContextInterface } from "./AuthStoreContext";
import { AuthStoreReducer } from "./AuthStoreReducer";

/**
 * * Token store provider
 */
const { Provider } = AuthStoreContext;

type AuthStoreProviderProps = {
  /**
   * * Child content that Provider wraps around
   */
  children: React.ReactNode;
};

/**
 * * Create Provider component to wrap around content
 */
export const AuthStoreProvider = (props: AuthStoreProviderProps) => {
  /**
   * * Prepare the Reducer to store the auth data
   */
  const [authState, authStateDispatch] = useReducer(AuthStoreReducer, InitialAuthState);

  /**
   * * Get service hook
   */
  const { Tokens, RequestError, IsLoading, postLogin } = useRequestLogin();

  /**
   * * Set the Auth token
   */
  const setAccessTokens = useCallback((access_tokens: { token: string; amltoken: AMLTokensInterface }) => {
    // * Store locally in auth provider
    authStateDispatch({
      type: AUTH_STORE_ACTIONS.SET_ACCESS_TOKENS,
      payload: access_tokens,
    });
  }, []);

  /**
   * * Get the current Auth token
   */
  const getAccessToken = useCallback(() => authState.token, [authState.token]);

  /**
   * * Get the required AML Auth
   */
  const getAMLAccessToken = useCallback(
    (amlEnvName: AMLEndpointEnvironmentType) => {
      return authState.amltoken && amlEnvName in authState.amltoken ? authState.amltoken[amlEnvName] : null;
    },
    [authState.amltoken],
  );

  /**
   * * Set an AML access token
   */
  const setAMLAccessToken = useCallback(
    (amlEnvName: AMLEndpointEnvironmentType, bearer: string) => {
      if (!authState.token || !authState.amltoken) {
        console.warn("[setAMLAccessToken] Attempting to set aml access token when tokens are null");
        return;
      }

      if (!["qa", "staging", "production", "localhost"].includes(amlEnvName)) {
        console.warn("Attempting to set unknown aml token");
        return;
      }

      authStateDispatch({
        type: AUTH_STORE_ACTIONS.SET_ACCESS_TOKENS,
        payload: {
          token: authState.token,
          amltoken: {
            ...authState.amltoken,
            [amlEnvName]: bearer,
          },
        },
      });
    },
    [authState.amltoken, authState.token],
  );

  /**
   * * Clear the Auth token
   */
  const clearAccessTokens = useCallback(() => {
    authStateDispatch({
      type: AUTH_STORE_ACTIONS.CLEAR_ACCESS_TOKENS,
    });
  }, []);

  /**
   * * Clear the Auth token
   */
  const clearAMLAccessToken = useCallback(
    () =>
      authStateDispatch({
        type: AUTH_STORE_ACTIONS.CLEAR_ACCESS_TOKENS,
      }),
    [],
  );

  /**
   * * Check if is authenticated
   */
  const isAuthenticated = useCallback(() => authState.is_authenticated, [authState.is_authenticated]);

  /**
   * * Request Auth Token
   */
  const requestLogin = useCallback(
    (data: { username: string; password: string }) => postLogin({ ...data }),
    [postLogin],
  );

  /**
   * * If Data is changed (and has content) then reset service
   */
  useEffect(() => {
    if (Tokens) {
      // * Store in Auth
      setAccessTokens({
        token: Tokens.token,
        amltoken: {
          ...Tokens.amltoken,
          localhost: process.env.REACT_APP_AML_TOKEN_LOCALHOST ?? "",
        },
      });
    }
  }, [Tokens, setAccessTokens]);

  /**
   * * Prepare memoised context value
   */
  const contextValue: AuthContextInterface = useMemo(() => {
    return {
      getAccessToken,
      getAMLAccessToken,
      setAMLAccessToken,
      clearAccessTokens,
      clearAMLAccessToken,
      isAuthenticated,
      requestLogin,
      IsLoading,
      Error: RequestError,
    };
  }, [
    getAccessToken,
    getAMLAccessToken,
    setAMLAccessToken,
    clearAccessTokens,
    clearAMLAccessToken,
    isAuthenticated,
    requestLogin,
    IsLoading,
    RequestError,
  ]);

  return <Provider value={contextValue}>{props.children}</Provider>;
};
