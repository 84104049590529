import { PlusCircleIcon } from "@heroicons/react/outline";
import { produce } from "immer";
import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";
import { keyify } from "modules/Projects/AML/utilities/keyify/keyify";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useState } from "react";
import ConfigFormTable from "./ConfigFormTable/ConfigFormTable";
import { removeKeyFromRuleset } from "../../utilities/rulesetUtilities";

type OptionableConfigProps = {
  onChange: (answerOption: AMLAnswerOptionInterface) => void;
  answerOption: AMLAnswerOptionInterface;
  content: string;
};

const OptionableConfig = (props: OptionableConfigProps) => {
  const { Theme } = useTheme();

  // * Keep hold of user input for any new items
  const [Entry, setEntry] = useState<string>("");

  // * Destructure answerOption items
  const { options, rules, default_answer } = props.answerOption;

  /**
   * * Handle submitting new Entry
   */
  const handleSubmitEntry = useCallback(() => {
    // * Get user entry
    const value = Entry;
    if (value.length === 0) return;
    // * Clear user input
    setEntry("");

    const key = keyify(value);

    // * don't do anything if already keyed
    if (options && key in options) return;

    // * Convert options back into object with numerical indexing
    props.onChange({
      ...props.answerOption,
      options: { ...options, [key]: value },
    });
  }, [Entry, options, props]);

  /**
   * Handle removing an entry from the options
   * @param {string} id id of item to remove
   * @returns
   */
  const handleRemoveEntry = useCallback(
    (id: string) => {
      if (!options) return;

      // * Update with the options copy
      props.onChange({
        ...props.answerOption,
        rules: removeKeyFromRuleset(rules, id),
        default_answer: default_answer
          ? produce(default_answer, (draft) => {
              return draft.filter((o) => o !== id);
            })
          : [],
        options: produce(options, (draft) => {
          if (draft[id]) {
            delete draft[id];
          }
        }),
      });
    },
    [default_answer, options, props, rules],
  );

  return (
    <div className="p-2">
      <p className="text-sm text-center mb-2">{props.content}</p>
      <div>
        <ConfigFormTable
          answerOption={props.answerOption}
          onChange={props.onChange}
          handleRemoveEntry={handleRemoveEntry}
        />
      </div>
      <div>
        <div className="flex items-center">
          <input
            className={`${Theme["input"]} mr-2 flex-1`}
            value={Entry}
            onChange={(e) => setEntry(e.currentTarget.value)}
          />
          <span onClick={handleSubmitEntry} className="p-4 cursor-pointer hover:opacity-80">
            <PlusCircleIcon className="h-5 w-5 inline-block" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default OptionableConfig;
