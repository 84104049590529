import Table from "components/UI/Table/Table";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import ServiceLogLevelBadge from "../ServiceLogLevelBadge/ServiceLogLevelBadge";

type ServiceLogDetailTableProps = {
  logId: string;
  createdAt: string;
  levelName: string;
  level: number;
  channel: string;
};

const ServiceLogDetailTable = (props: ServiceLogDetailTableProps) => (
  <div className="mt-4 first-of-type:mt-0">
    <h2 className="font-medium text-lg mb-2 text-left">Log Details</h2>
    <Table>
      <tbody>
        <TableRow>
          <TableData>ID</TableData>
          <TableData>{props.logId}</TableData>
        </TableRow>
        <TableRow>
          <TableData>Time</TableData>
          <TableData>{new Date(props.createdAt).toUTCString()}</TableData>
        </TableRow>
        <TableRow>
          <TableData>Level</TableData>
          <TableData>
            <ServiceLogLevelBadge level_name={props.levelName} />
            <span>{props.level}</span>
          </TableData>
        </TableRow>
        <TableRow>
          <TableData>Channel</TableData>
          <TableData>{props.channel}</TableData>
        </TableRow>
      </tbody>
    </Table>
  </div>
);

export default ServiceLogDetailTable;
