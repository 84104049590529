import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import ServicesIndexTable from "../../components/ServicesIndexTable/ServicesIndexTable";
import { useRequestServicesIndex } from "../../services/useRequestServicesIndex/useRequestServicesIndex";

const ServicesLogsIndex = () => {
  const { Services, IsLoading, RequestError, getServicesIndex } =
    useRequestServicesIndex();

  useMountEffect(() => getServicesIndex());

  return (
    <Page
      pageTitle="Service Logs"
      isLoadingContent={IsLoading}
      hasError={RequestError}
    >
      {Services && <ServicesIndexTable services={Services} />}
    </Page>
  );
};

export default ServicesLogsIndex;
