import Page from "components/UI/Page/Page";
import PaginationNav from "components/UI/PaginationNav/PaginationNav";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { useStateWithCallback } from "hooks/useStateWithCallback/useStateWithCallback";
import { produce } from "immer";
import { LOGS_SERVICES_ALL } from "modules/Logs/Services/routes";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import ServiceLogsTable from "../../components/ServiceLogsTable/ServiceLogsTable";
import ServiceLogsTopNav from "../../components/ServiceLogsTopNav/ServiceLogsTopNav";
import {
  GetServiceLogsIndexParamsType,
  useRequestServiceLogsIndex,
} from "../../services/useRequestServiceLogsIndex.ts/useRequestServiceLogsIndex";

const ServiceLogsIndex = () => {
  const [CurrentParams, setCurrentParams] = useStateWithCallback<GetServiceLogsIndexParamsType>({
    page: 1,
    per_page: 25,
  });

  const { ServiceLogs, RequestError, IsLoading, getServiceLogsIndex } = useRequestServiceLogsIndex();

  const { id } = useParams<{ id: string }>();

  const handleGoToPage = useCallback(
    (page: number) => {
      setCurrentParams(
        (params) =>
          produce(params, (draft) => {
            draft.page = page;
          }),
        (update) =>
          getServiceLogsIndex({
            id: id as string,
            params: {
              ...update,
            },
          }),
      );
    },
    [getServiceLogsIndex, id, setCurrentParams],
  );

  useMountEffect(() =>
    getServiceLogsIndex({
      id: id as string,
      params: {
        ...CurrentParams,
      },
    }),
  );

  return (
    <Page
      pageTitle={`Service logs: ${id ? id.replace(/-/g, " ") : "loading..."}`}
      isLoadingContent={IsLoading}
      hasError={RequestError}
      wide
      breadcrumbs={[
        {
          url: LOGS_SERVICES_ALL,
          content: "Back to All Services",
        },
      ]}
    >
      {ServiceLogs && (
        <>
          <ServiceLogsTopNav total={ServiceLogs.total} />
          <ServiceLogsTable logs={ServiceLogs.data} />
          <PaginationNav
            handleGoToPage={handleGoToPage}
            lastPage={ServiceLogs.last_page}
            currentPage={ServiceLogs.current_page}
          />
        </>
      )}
    </Page>
  );
};

export default ServiceLogsIndex;
