import JsonView from "@uiw/react-json-view";
import { darkTheme } from "@uiw/react-json-view/dark";
import { lightTheme } from "@uiw/react-json-view/light";
import { Identifiable } from "interfaces/ResourceInterface";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

interface IProps {
  readonly details: Identifiable;
}

export const VendorDetailsViewer = ({ details }: IProps) => {
  const { Theme, ActiveTheme } = useTheme();

  return (
    <div className={twMerge(Theme["bg-secondary"], "p-2")}>
      <JsonView
        enableClipboard={false}
        displayDataTypes={false}
        value={details}
        style={ActiveTheme === "Light" ? lightTheme : darkTheme}
      />
    </div>
  );
};
