import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { IOptions, SelectField } from "components/UI/Inputs/SelectField";
import Loader from "components/UI/Loader/Loader";
import { useState } from "react";

interface IProps {
  readonly content?: React.ReactNode;
  readonly value: string;
  readonly onConfirm: (value: string) => void;
  readonly isLoading?: boolean;
  readonly options: IOptions;
}

export const EditableSelectField = ({ content, value, onConfirm, isLoading, options }: IProps) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const [currentValue, setCurrentValue] = useState<string>(value);

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const cancelEditing = () => {
    setCurrentValue(value);
    setIsEditing(!isEditing);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { target } = e;
    setCurrentValue(target.value);
  };

  const handleOnConfirm = () => {
    if (value === currentValue) return;
    onConfirm(currentValue.trim());
    setIsEditing(false);
  };

  return (
    <div className="flex items-center w-full justify-between">
      <div className="flex-1">
        {isEditing ? (
          <SelectField value={currentValue} onChange={handleOnChange} disabled={isLoading} options={options} />
        ) : (
          <>{content ?? value}</>
        )}
      </div>
      {isLoading ? (
        <span className="inline-block w-5 h-5">
          <Loader />
        </span>
      ) : (
        <div className="ml-2">
          {isEditing ? (
            <>
              <CheckIcon
                className={`inline-block w-5 h-5 align-text-bottom m-1 text-green-500  ${
                  value !== currentValue ? "hover:text-green-700 cursor-pointer" : "opacity-20"
                }`}
                onClick={handleOnConfirm}
              />
              <XIcon
                className="inline-block w-5 h-5 align-text-bottom cursor-pointer text-red-500 hover:text-red-700 m-1"
                onClick={cancelEditing}
              />
            </>
          ) : (
            <PencilIcon className="inline-block w-5 h-5 align-text-bottom cursor-pointer" onClick={toggleEditing} />
          )}
        </div>
      )}
    </div>
  );
};
