import { produce } from "immer";
import { clearCookie } from "utilities/clearCookie/clearCookie";
import { setCookie } from "utilities/setCookie/setCookie";
import { AuthActionInterface } from "./AuthActionInterface";
import { AMLTOKEN_COOKIE_NAME, AuthStateInterface, TOKEN_COOKIE_NAME } from "./AuthState";
import * as AUTH_STORE_ACTIONS from "./AuthStoreActions";

/**
 * * Auth store reducer
 */
export const AuthStoreReducer = (oldState: AuthStateInterface, action: AuthActionInterface): AuthStateInterface => {
  switch (action.type) {
    /**
     * * Set the Auth Token
     */
    case AUTH_STORE_ACTIONS.SET_ACCESS_TOKENS:
      return produce(oldState, (draft) => {
        if (action.payload) {
          if ("token" in action.payload && "amltoken" in action.payload) {
            const is_authenticated = action.payload.token.length > 0;

            if (is_authenticated) {
              draft.amltoken = action.payload.amltoken;
              draft.token = action.payload.token;
              draft.is_authenticated = is_authenticated;

              // * Set cookies for tokens
              setCookie(TOKEN_COOKIE_NAME, draft.token ?? "", 3600);
              setCookie(AMLTOKEN_COOKIE_NAME, JSON.stringify(draft.amltoken), 3600);
            } else {
              // * clear cookies
              clearCookie(TOKEN_COOKIE_NAME);
              // * return cleared data
              draft.amltoken = null;
              draft.is_authenticated = false;
              draft.token = null;
            }
          } else {
            console.warn(`[AuthStoreReducer:SET_ACCESS_TOKENS] Incorrect payload provided`);
          }
        }
      });
    /**
     * * Clear the Auth Token
     */
    case AUTH_STORE_ACTIONS.CLEAR_ACCESS_TOKENS: {
      // * clear cookies
      clearCookie(TOKEN_COOKIE_NAME);
      clearCookie(AMLTOKEN_COOKIE_NAME);

      // * return cleared values
      return {
        is_authenticated: false,
        token: null,
        amltoken: null,
      };
    }

    default:
      console.warn(`[AuthStoreReducer] Unknown action.type ${action.type} provided`);
      return oldState;
  }
};
