/**
 * * Define the Auth token state
 */

import { getCookie } from "utilities/getCookie/getCookie";

export const TOKEN_COOKIE_NAME = "token";
export const AMLTOKEN_COOKIE_NAME = "amltoken";

export interface AMLTokensResponseInterface {
  qa: string;
  staging: string;
  production: string;
}

export interface AMLTokensInterface {
  qa: string;
  staging: string;
  production: string;
  localhost: string;
}

/**
 * * Auth state interface
 */
export interface AuthStateInterface {
  /**
   * * access (auth) token
   */
  token: null | string;

  /**
   * * AML Access master token
   */
  amltoken: null | AMLTokensInterface;

  /**
   * * Is Authenticated (dependent on having auth_token)
   */
  is_authenticated: boolean;
}

// * Extract any defined cookie settings on initial load

let token = null;
let amltoken = null;

try {
  token = decodeURIComponent(getCookie(TOKEN_COOKIE_NAME)) ?? null;
  amltoken = JSON.parse(decodeURIComponent(getCookie(AMLTOKEN_COOKIE_NAME))) as AMLTokensInterface;
} catch (e) {
  token = null;
  amltoken = null;
}

/**
 * * Define the initial auth state
 */
export const InitialAuthState: AuthStateInterface = {
  token: token,
  amltoken: amltoken,
  is_authenticated: !!(token && amltoken),
};
