import { useContext } from "react";
import SettingsEndpointContext from "./SettingsEnvContext";

const useSettingsEnv = () => {
  const context = useContext(SettingsEndpointContext);

  if (context === undefined) {
    console.error("[useSettingsEnv] Context not provided. Ensure your component is a child of <SettingsEnvProvider />");
  }

  return context;
};

export default useSettingsEnv;
