/* eslint-disable no-loops/no-loops */ // TODO: Refactor this file
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useStateWithCallback } from "hooks/useStateWithCallback/useStateWithCallback";
import { produce } from "immer";
import { ResourceInterface } from "interfaces/ResourceInterface";

const replaceResource = <T extends ResourceInterface>(r: Array<T>, u: T) => {
  // * find matching idx
  const idx = r.findIndex((i) => i.id === u.id);

  if (idx > -1) {
    // * replace if found
    r[idx] = { ...u };
  } else {
    // * else add to array if not
    r.push({ ...u });
  }
};

/**
 * * Set resource with passed data using immer to prevent state mutation
 * * And transform any number id to a string format
 * @param {T | Array<T>} resource
 * @returns {Array<T>}
 */
const createResourceArray = <T extends ResourceInterface>(resource: T | Array<T>): Array<T> =>
  produce([] as Array<T>, (draft: Array<T>) => {
    draft.push(
      ...(Array.isArray(resource) ? resource : [resource]).map((r) => ({
        ...r,
        id: r.id.toString(),
      })),
    );
  });

type useResourceProps<T> = {
  initialData: Array<T>;
};

/**
 * * Use a managed array resource for storing data of a given Type in an Array
 * * Provides handlers for setting, updating and deleting
 * * Accepts an optional initialData: Array<T> to begin with
 */
export const useResource = <T extends ResourceInterface>(props?: useResourceProps<T>) => {
  const [Resource, setResource] = useStateWithCallback<Array<T>>(
    props && props.initialData ? createResourceArray(props.initialData) : [],
  );

  /**
   * * Create a resource array using immer
   * * Also converts all ids to strings to avoid need for typecasting
   * @param {T | Array<T>} resource Seed array to create resource from
   * @param {(resource: Array<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const createResource = (resource: T | Array<T>, onUpdate?: (resource: Array<T>) => void) =>
    setResource(() => createResourceArray(resource), onUpdate);

  /**
   * * Update a typed resource array with a single item of T or array of T.
   * * Overwrites any resource by matching ids, or adds to end of the array if no match
   * @param {T extends ResourceInterface | Array<T extends ResourceInterface>} update The typed update/s
   * @param {(resource: Array<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const updateResource = (update: T | Array<T>, onUpdate?: (resource: Array<T>) => void) =>
    setResource((prev) => {
      return produce(prev, (draft: Array<T>) => {
        // * Check if array
        if (Array.isArray(update)) {
          // * loop over each update
          for (let i = 0; i < update.length; i++) {
            replaceResource(draft, update[i]);
          }
        } else {
          replaceResource(draft, update);
        }
      });
    }, onUpdate);

  /**
   * * Delete a resource by id from a typed resource array
   * @param {T["id"] | Array<T["id"]>} id The ids of the resource(s) to remove
   * @param {(resource: Array<T>) => void} onUpdate Optional callback function when resource is updated - receives updated resource
   */
  const deleteResource = (ids: T["id"] | Array<T["id"]>, onUpdate?: (resource: Array<T>) => void) => {
    setResource((prev) => {
      return produce(prev, (draft: Array<T>) => {
        if (Array.isArray(ids)) {
          return draft.filter((r: T) => !ids.includes(r.id));
        }
        return draft.filter((r: T) => r.id !== ids);
      });
    }, onUpdate);
  };

  return {
    Resource,
    createResource,
    updateResource,
    deleteResource,
  };
};
