import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";

type FreeTypeDetailsType = {
  answerOption: AMLAnswerOptionInterface;
};

/**
 * * Display details for the FreeType Answer Option
 */
const FreeTypeDetails = (props: FreeTypeDetailsType) => {
  const { rules } = props.answerOption;

  return (
    <div>
      <p className="text-center font-medium text-base mt-4 mb-2">Selected Answer Type: Freetype</p>
      {rules?.allow_multiple && (
        <p className="text-center font-medium text-base mb-4">
          This answer option is set to allow the to enter multiple responses.
        </p>
      )}
    </div>
  );
};

export default FreeTypeDetails;
