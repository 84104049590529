import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";
import DetailsTable from "./DetailsTable/DetailsTable";

type OptionableDetailsPropsType = {
  answerOption: AMLAnswerOptionInterface;
  content: string;
};

/**
 * * Display details for the radio type
 */
const OptionableDetails = (props: OptionableDetailsPropsType) => (
  <>
    <p className="text-center font-medium text-base mt-4 mb-2">
      {props.content}
    </p>
    <div>
      <DetailsTable answerOption={props.answerOption} />
    </div>
  </>
);

export default OptionableDetails;
