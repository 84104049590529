import Message from "../Message/Message";

type CautionMessageProps = {
  title?: string;
  content: string;
};

const CautionMessage = (props: CautionMessageProps) => (
  <Message theme="caution" {...props} />
);

export default CautionMessage;
