import { useState } from "react";
import { AMLStatuses, AmlCheckStatusEnum, AmlCheckStatusString } from "../../interfaces/AmlCheckStatusEnum";
import { CheckStatusBadge } from "../Check/CheckStatusBadge";
import { EditableSelectCheckRow } from "../EditableSelectCheckRow/EditableSelectCheckRow";

interface IProps {
  readonly id: string;
  readonly status: AMLStatuses;
}

export const EditableCheckStatusRow = ({ id, status }: IProps) => {
  const [currentStatus, setCurrentStatus] = useState(status);

  const handleOnUpdate = (value: string) => {
    setCurrentStatus(value as AMLStatuses);
  };

  return (
    <EditableSelectCheckRow
      content={<CheckStatusBadge status={currentStatus} />}
      value={currentStatus ?? ""}
      keyName="status"
      name="Status"
      options={Object.values(AmlCheckStatusEnum).map((v) => ({
        key: v,
        value: v,
        displayValue: AmlCheckStatusString[v],
      }))}
      checkId={id}
      handleOnUpdate={handleOnUpdate}
    />
  );
};
