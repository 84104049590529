import Table from "components/UI/Table/Table";
import { IAssessmentGroups } from "../../interfaces/IAssessmentGroup";
import { AssessmentGroupsTableRow } from "../AssessmentGroupsTableRow/AssessmentGroupsTableRow";

interface IProps {
  readonly assessmentGroups: IAssessmentGroups;
}

export const AssessmentGroupsTable = ({ assessmentGroups }: IProps) => (
  <Table>
    <tbody>
      {assessmentGroups.map((assessmentGroup) => (
        <AssessmentGroupsTableRow assessmentGroup={assessmentGroup} key={assessmentGroup.id} />
      ))}
    </tbody>
  </Table>
);
