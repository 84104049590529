import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_SERVICE_LOG_SHOW } from "../../endpoints";
import { ServiceLogShowResponseInterface } from "../../interfaces/ServiceLogShowResponseInterface";

type GetServiceLogShowConfig = {
  id: string;
};

interface useRequestServiceLogShowInterface<T>
  extends useRequestServiceImplementation<T> {
  ServiceLog: T | null;
  getServiceLogShow: (config: GetServiceLogShowConfig) => void;
}

export const useRequestServiceLogShow = <
  T extends ServiceLogShowResponseInterface
>(): useRequestServiceLogShowInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getServiceLogShow = useCallback(
    (config: GetServiceLogShowConfig) =>
      sendGetRequest(
        generateCompleteURL(GET_SERVICE_LOG_SHOW, { id: config.id }, {}),
        (data) => setData(data),
        {
          bearer: getAccessToken() as string,
        }
      ),
    [getAccessToken, sendGetRequest]
  );

  return {
    ServiceLog: Data,
    IsLoading,
    RequestError,
    getServiceLogShow,
  };
};
