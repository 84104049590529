import { ArrowCircleDownIcon, ArrowCircleLeftIcon } from "@heroicons/react/outline";
import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { AMLCheckRoutes } from "../../routes";
import { Check } from "../components/Check/Check";
import { ICheck, IChecks } from "../interfaces/ICheck";
import { useRequestAMLChecks } from "../services/useRequestAMLChecks/useRequestAMLChecks";

export const Checks = () => {
  const [currentCheck, setCurrentCheck] = useState<ICheck>();
  const [pastChecks, setPastChecks] = useState<IChecks>([]);
  const [collapseSubList, setCollapseSubList] = useState<boolean>(true);

  const { getAMLChecks, IsLoading, RequestError } = useRequestAMLChecks();

  const { getAMLEndpointTitle, clearAMLEndpoint } = useAMLEndpoint();

  const { tenant_id, assessment_group_id } = useParams<{
    tenant_id: string;
    assessment_group_id: string;
  }>();

  const onAMLChecksResponse = (current: ICheck, past: IChecks) => {
    setCurrentCheck(current);
    setPastChecks(past);
  };

  useMountEffect(
    () => tenant_id && assessment_group_id && getAMLChecks(tenant_id, assessment_group_id, onAMLChecksResponse),
  );

  return (
    <Page
      pageTitle={
        <span>
          AML Checks{" "}
          <span className="text-xl cursor-pointer align-middle" onClick={clearAMLEndpoint}>
            ({getAMLEndpointTitle()})
          </span>
        </span>
      }
      isLoadingContent={IsLoading}
      hasError={RequestError}
      breadcrumbs={[
        {
          content: "View all tenants",
          url: generateCompleteURL(AMLCheckRoutes.tenants.index, {}, {}),
          preserveQuery: true,
        },
        {
          content: "Assessment Groups",
          url: generateCompleteURL(
            AMLCheckRoutes.assessment_groups.index,
            {
              tenant_id: tenant_id as string,
            },
            {},
          ),
          preserveQuery: true,
        },
      ]}
    >
      {currentCheck && (
        <>
          <h2 className="text-left text-2xl font-medium mb-2 flex justify-between items-center cursor-pointer">
            Current Check
          </h2>
          <Check check={currentCheck} />
        </>
      )}
      {pastChecks.length > 0 && (
        <>
          <h2
            className="text-left text-2xl font-medium mb-2 flex justify-between items-center cursor-pointer mt-2"
            onClick={() => setCollapseSubList(!collapseSubList)}
          >
            Past Checks
            <span>
              {collapseSubList ? (
                <ArrowCircleLeftIcon className="h-5 w-5 inline-block" />
              ) : (
                <ArrowCircleDownIcon className="h-5 w-5 inline-block" />
              )}
            </span>
          </h2>
          <div className={`ml-5 ${collapseSubList ? "hidden" : "block"}`}>
            {pastChecks.map((pastCheck) => (
              <Check check={pastCheck} key={pastCheck.id} />
            ))}
          </div>
        </>
      )}
    </Page>
  );
};
