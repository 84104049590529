import Page from "components/UI/Page/Page";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { WORDPRESS_LOGS_INDEX } from "modules/Tenants/App/routes";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRequestWPAppShow } from "../../services/useRequestWPAppShow/useRequestWPAppShow";

const WPRedirect = () => {
  const { App, IsLoading, RequestError, getWPApp } = useRequestWPAppShow();

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  useMountEffect(() => getWPApp({ params: { wp_id: id as string } }));

  useEffect(() => {
    if (App) {
      navigate(WORDPRESS_LOGS_INDEX.replace(/:id/, App.id), { replace: true });
    }
  }, [App, navigate]);

  return (
    <Page pageTitle="" hasError={RequestError} isLoadingContent={IsLoading} />
  );
};

export default WPRedirect;
