import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import {
  DELETE_ANSWER_OPTION_FIELD_GROUP,
  GET_ANSWER_OPTION_FIELD_GROUPS,
  PATCH_ANSWER_OPTION_FIELD_GROUP,
  POST_ANSWER_OPTION_FIELD_GROUP,
} from "../../endpoints";
import { AMLAnswerOptionFieldGroupInterface } from "../../interfaces/AMLAnswerOptionFieldGroupInterface";

type SubmitAnswerOptionFieldGroupType = {
  name: string;
  description: string;
  question_id: string;
  display_order: number;
};

interface useRequestAMLAnswerOptionFieldGroupInterface<T> extends useRequestServiceImplementation<T> {
  AnswerOptionFieldGroups: Array<T>;
  getAnswerOptionFieldGroupsIndex: (question_id: string) => void;
  postAnswerOptionFieldGroup: (config: SubmitAnswerOptionFieldGroupType) => void;
  patchAnswerOptionFieldGroup: (
    id: string,
    config: { name: string; description: string; display_order: number },
  ) => void;
  deleteAnswerOptionFieldGroup: (id: string) => void;
}

export const useRequestAMLAnswerOptionFieldGroups = <
  T extends AMLAnswerOptionFieldGroupInterface,
>(): useRequestAMLAnswerOptionFieldGroupInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get answerOptionFieldGroups
   */
  const handleOnGetAnswerOptionFieldGroupsResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all answerOptionFieldGroups
   */
  const getAnswerOptionFieldGroupsIndex = useCallback(
    (question_id: string) =>
      sendGetRequest(
        generateCompleteURL(
          GET_ANSWER_OPTION_FIELD_GROUPS,
          {},
          {
            question_id,
          },
        ),
        (response) => handleOnGetAnswerOptionFieldGroupsResponse(response),
        {},
      ),
    [handleOnGetAnswerOptionFieldGroupsResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a answerOptionFieldGroup
   */
  const handleOnPostAnswerOptionFieldGroup = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Post a new answerOptionFieldGroup
   */
  const postAnswerOptionFieldGroup = useCallback(
    (config: SubmitAnswerOptionFieldGroupType) =>
      sendPostRequest(
        generateCompleteURL(POST_ANSWER_OPTION_FIELD_GROUP, {}, {}),
        { ...config },
        (response) => handleOnPostAnswerOptionFieldGroup(response),
        {},
      ),
    [handleOnPostAnswerOptionFieldGroup, sendPostRequest],
  );

  /**
   * * Handle on patching a answerOptionFieldGroup
   */
  const handleOnPatchAnswerOptionFieldGroup = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a answerOptionFieldGroup
   */
  const patchAnswerOptionFieldGroup = useCallback(
    (id: string, config: { name: string; description: string; display_order: number }) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_ANSWER_OPTION_FIELD_GROUP, { id }, {}),
        { ...config },
        (response) => handleOnPatchAnswerOptionFieldGroup(response),
        {},
      ),

    [handleOnPatchAnswerOptionFieldGroup, sendPatchRequest],
  );

  /**
   * * Handle on deleting a answerOptionFieldGroup
   */
  const handleOnDeleteAnswerOptionFieldGroup = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a answerOptionFieldGroup
   */
  const deleteAnswerOptionFieldGroup = useCallback(
    (id: string) =>
      sendDeleteRequest(
        generateCompleteURL(DELETE_ANSWER_OPTION_FIELD_GROUP, { id }, {}),
        () => handleOnDeleteAnswerOptionFieldGroup(id),
        {},
      ),
    [handleOnDeleteAnswerOptionFieldGroup, sendDeleteRequest],
  );

  return {
    AnswerOptionFieldGroups: Resource,
    IsLoading,
    RequestError,
    getAnswerOptionFieldGroupsIndex,
    postAnswerOptionFieldGroup,
    patchAnswerOptionFieldGroup,
    deleteAnswerOptionFieldGroup,
  };
};
