import { AMLTemplateInterface } from "../interfaces/AMLTemplateInterface";

export const findTemplate = (
  entitySubTypeId: string,
  assessmentTypeId: string,
  templates: Array<AMLTemplateInterface>
): AMLTemplateInterface | null => {
  const template = templates.find(
    (t) =>
      t.assessment_type_id === assessmentTypeId &&
      t.entity_subtype_id === entitySubTypeId
  );

  return template ?? null;
};
