import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import { TextField } from "components/UI/Inputs/TextField";
import Modal from "components/UI/Modal/Modal";
import { SettingsEnvType } from "modules/Settings/shared/interfaces/SettingsEnvType";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useState } from "react";
import useSettingsEnv from "../../../../shared/providers/SettingsEndpointProvider/useSettingsEnv";

const SettingsEnvModal = () => {
  const { Theme } = useTheme();

  const { setSettingsEnv, selectedSettingsEnv, setToken } = useSettingsEnv();

  const [currentEnv, setCurrentEnv] = useState(selectedSettingsEnv);
  const [customBearerToken, setCustomBearerToken] = useState<string>();
  const [DisplayModal, setDisplayModal] = useState<boolean>(true);

  const handleChangeSettingsEnv = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    if (!target.value) {
      return;
    }
    setCurrentEnv(target.value as SettingsEnvType);
  };

  const handleConfirmSettingSettingsEnv = useCallback(() => {
    if (currentEnv) {
      setSettingsEnv(currentEnv);
      if (currentEnv !== SettingsEnvType.PRODUCTION && customBearerToken) {
        setToken(customBearerToken);
      } else {
        setToken("");
      }
    }
  }, [currentEnv, customBearerToken, setSettingsEnv, setToken]);

  const handleChangeToken = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCustomBearerToken(target.value);
  };

  const handleToggleModal = useCallback(() => {
    setDisplayModal(!DisplayModal);
    setCurrentEnv(undefined);
    setCustomBearerToken(undefined);
  }, [DisplayModal]);

  const showBearerTokenField = currentEnv && currentEnv !== SettingsEnvType.PRODUCTION;
  const disableConfirm = !currentEnv || (currentEnv !== SettingsEnvType.PRODUCTION && !customBearerToken);

  return (
    <>
      {DisplayModal ? (
        <Modal onClose={handleToggleModal}>
          <div className="p-4">
            <p className="mb-2 text-center">
              Before proceeding, please select the Settings environment you wish to edit:
            </p>
            <select className={Theme.input} onChange={handleChangeSettingsEnv} defaultValue={0}>
              <option value={0} disabled>
                Select an option
              </option>
              {Object.values(SettingsEnvType).map((env) => (
                <option value={env} key={env}>
                  {env.toUpperCase()}
                </option>
              ))}
            </select>
            {showBearerTokenField && (
              <div>
                <p className="my-2 text-center">Enter Bearer Token for this environment</p>
                <TextField onChange={handleChangeToken} value={customBearerToken} />
              </div>
            )}
            <div className="flex justify-between items-center mt-4">
              <TertiaryButton onClick={() => setDisplayModal(!DisplayModal)}>Cancel</TertiaryButton>
              <PrimaryButton type="submit" disabled={disableConfirm} onClick={handleConfirmSettingSettingsEnv}>
                Confirm
              </PrimaryButton>
            </div>
          </div>
        </Modal>
      ) : (
        <div className="p-2">
          <p onClick={handleToggleModal} className={`${Theme["text-color-primary"]} cursor-pointer font-medium`}>
            Click here to select the Settings environment you wish to edit
          </p>
        </div>
      )}
    </>
  );
};

export default SettingsEnvModal;
