import { MinusCircleIcon } from "@heroicons/react/outline";
import { AMLAnswerOptionInterface } from "modules/Projects/AML/Questions/interfaces/AMLAnswerOptionInterface";
import { isLevel1QuestionByQuestion } from "modules/Projects/AML/Questions/utilities/determineQuestionLevel";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useCallback, useContext } from "react";
import { QuestionsContext } from "../../../../QuestionsList/QuestionsList";
import { sortOptionsByRulesDisplayOrder } from "../../../utilities/orderByDisplayOrderRules";
import { updateRuleset } from "../../../utilities/rulesetUtilities";
import SubQuestionSelector from "../SubQuestionSelector/SubQuestionSelector";

type ConfigFormTablePropsType = {
  answerOption: AMLAnswerOptionInterface;
  handleRemoveEntry: (id: string) => void;
  onChange: (answerOption: AMLAnswerOptionInterface) => void;
};

/**
 * * Display table form for the answer type config
 */
const ConfigFormTable = (props: ConfigFormTablePropsType) => {
  const { Theme } = useTheme();

  // * Destructure answer items
  const { options, rules, default_answer } = props.answerOption;

  const { currentQuestion } = useContext(QuestionsContext);

  /**
   * * Handle setting a Leads To Enhanced Option
   * @param {string} id id of item to set as or remove from leads to enhanced
   */
  const handleLeadsToEnhanced = useCallback(
    (id: string) =>
      props.onChange({
        ...props.answerOption,
        rules: updateRuleset(rules, (draft) => {
          // * Check if the id already exists in lte
          const idx = draft.leads_to_enhanced.indexOf(id);

          if (idx < 0) {
            // * if not, we're adding
            draft.leads_to_enhanced.push(id);
          } else {
            // * Otherwise we're removing
            draft.leads_to_enhanced.splice(idx, 1);
          }
        }),
      }),
    [props, rules],
  );

  /**
   * * Set default answer
   * @param {string} id id of item to set as default
   */
  const handleDefaultAnswer = useCallback(
    (id: string) => {
      let defaultAnswerCopy = default_answer ? [...default_answer] : [];

      // * Check if passed id exists already
      const idx = defaultAnswerCopy.findIndex((d) => d === id);

      // * if it doesn't set it to do so
      if (idx < 0) {
        defaultAnswerCopy = [id];
      } else {
        // * else remove it
        defaultAnswerCopy = [];
      }

      props.onChange({
        ...props.answerOption,
        default_answer: [...defaultAnswerCopy],
      });
    },
    [default_answer, props],
  );

  /**
   * * Handle selecting a sub question. Handles adding and removing
   * @param {string} q_id The Question Id to store
   * @param {string} a_id The Answer Id to store the q_id against
   */
  const handleSubQuestionSelect = useCallback(
    (q_id: string, a_id: string) =>
      props.onChange({
        ...props.answerOption,
        rules: updateRuleset(rules, (draft) => {
          if (!("level_2" in draft) || !draft.level_2 || Array.isArray(draft.level_2)) {
            draft.level_2 = {};
          }

          // * check if key in level_2
          const { level_2 } = draft;

          if (a_id in level_2) {
            // * Key exists, so check if q_id is currently stored or not, then remove or add as appropriate
            const idx = level_2[a_id].findIndex((q: string) => q === q_id);
            if (idx < 0) {
              // * add it on
              level_2[a_id].push(q_id);
            } else {
              // * remove it
              level_2[a_id] = level_2[a_id].filter((q: string) => q !== q_id);
            }
          } else {
            // * doesn't exist so add it straight on
            level_2[a_id] = [q_id];
          }
        }),
      }),
    [rules, props],
  );

  /**
   * * Handle display order change
   * @param {string} id the id of the value being ordered
   * @param {string} order the order value the item is being given
   */
  const handleDisplayOrderEntry = useCallback(
    (id: string, order: string) =>
      props.onChange({
        ...props.answerOption,
        rules: updateRuleset(rules, (draft) => {
          // * If display order is missing from new Rules, add it as an empty object
          if (!("display_order" in draft) || !draft.display_order) {
            draft.display_order = {};
          }

          // * Set the order value (set as number, default to 0 if not provided)
          draft.display_order[id] = order !== "" ? parseInt(order) : 0;

          if (draft.display_order[id] < 0) {
            // * enforce entered value to be no less than 0
            draft.display_order[id] = 0;
          }
        }),
      }),
    [props, rules],
  );

  return (
    <>
      {options && Object.keys(options).length ? (
        <table className="table table-auto w-full divide-y mb-4">
          <thead>
            <tr className="divide-x">
              <th className="text-sm font-medium p-1">Pos</th>
              <th className="text-sm font-medium p-1">Value</th>
              <th className="text-sm font-medium p-1">
                Leads To
                <br />
                Enhanced?
              </th>
              <th className="text-sm font-medium p-1">Is Default?</th>
              {currentQuestion && isLevel1QuestionByQuestion(currentQuestion) ? (
                <th className="text-sm font-medium p-1">Show Subquestions</th>
              ) : null}
              <th className="text-sm font-medium p-1"></th>
            </tr>
          </thead>
          <tbody>
            {sortOptionsByRulesDisplayOrder(options, rules).map((obj: [string, string]) => (
              <tr className="py-1 px-2 divide-x" key={obj[0]}>
                <td className="">
                  <input
                    className={`${Theme["input"]} w-16 mx-auto`}
                    type="number"
                    min={0}
                    onChange={(e) => handleDisplayOrderEntry(obj[0], e.currentTarget.value)}
                    value={
                      rules && rules.display_order && obj[0] in rules.display_order ? rules.display_order[obj[0]] : ""
                    }
                  />
                </td>
                <td className="text-sm py-1 px-2 text-center">{obj[1]}</td>
                <td className="py-1 px-2 text-center">
                  <input
                    type="checkbox"
                    onChange={() => handleLeadsToEnhanced(obj[0])}
                    checked={rules && "leads_to_enhanced" in rules ? rules.leads_to_enhanced.includes(obj[0]) : false}
                  />
                </td>

                <td className="py-1 px-2 text-center">
                  <input
                    type="checkbox"
                    onChange={() => handleDefaultAnswer(obj[0])}
                    checked={default_answer ? default_answer.includes(obj[0]) : false}
                  />
                </td>
                {currentQuestion && isLevel1QuestionByQuestion(currentQuestion) ? (
                  <td className="py-1 px-2 text-center">
                    <SubQuestionSelector
                      currentSelections={
                        rules && rules.level_2 && Array.isArray(rules.level_2[obj[0]]) ? rules.level_2[obj[0]] : []
                      }
                      handleSubQuestionSelect={(q_id) => handleSubQuestionSelect(q_id, obj[0])}
                    />
                  </td>
                ) : null}
                <td className="py-1 px-2 text-center">
                  <span className="p-2 hover:opacity-80 cursor-pointer" onClick={() => props.handleRemoveEntry(obj[0])}>
                    <MinusCircleIcon className="h-5 w-5 inline-block" />
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="text-sm text-center mb-2">No Options defined</p>
      )}
    </>
  );
};

export default ConfigFormTable;
