import {twMerge} from "tailwind-merge";
import {EditableAssessmentRow} from "./EditableAssessmentGroupRow/EditableAssessmentRow";
import {EditableTextEntityIdRow} from "./EditableTextEnityIdRow/EditableTextEntityIdRow";
import Table from "../../../../../components/UI/Table/Table";
import TableData from "../../../../../components/UI/Table/TableData/TableData";
import TableRow from "../../../../../components/UI/Table/TableRow/TableRow";
import useTheme from "../../../../../providers/ThemeStoreProvider/useTheme";
import {IAssessmentGroups} from "../../AssessmentGroups/interfaces/IAssessmentGroup";
import {IAssessment} from "../interfaces/IAssessment";

interface IProps {
  readonly handleRestore: () => void,
  readonly assessment: IAssessment,
  readonly assessmentGroups: IAssessmentGroups,
}

export const Assessment = ({handleRestore, assessment, assessmentGroups}: IProps) => {
  const {Theme} = useTheme();

  return (
    <div className={twMerge(Theme["bg-secondary"], "mt-4 first-of-type:mt-0")}>
      <Table>
        <tbody>
        {Object.hasOwn(assessment, "deleted_at") && assessment.deleted_at !== null ? (
          <TableRow className="text-white bg-amber-500 hover:text-white hover:bg-amber-500">
            <TableData>This Assessment must be restored before making edits!</TableData>
            <TableData></TableData>
          </TableRow>
        ) : null}
        <TableRow>
          <TableData>Ref: </TableData>
          <TableData>{assessment.ref}</TableData>
        </TableRow>
        <TableRow>
          <TableData>Status: </TableData>
          <TableData>{assessment.status.name}</TableData>
        </TableRow>
        {assessment.deleted_at !== null ? (
            <TableRow>
              <TableData>Assessment Group ID: </TableData>
              <TableData>{assessment.assessment_group_id}</TableData>
            </TableRow>
          )
          : (
            <EditableAssessmentRow assessment_group_id={assessment.assessment_group_id} id={assessment.id}
                                   assessmentGroups={assessmentGroups}/>
          )}
        {assessment.deleted_at !== null ? (
          <TableRow>
            <TableData>Entity ID: </TableData>
            <TableData>{assessment.entity_id}</TableData>
          </TableRow>
        ) : (
          <EditableTextEntityIdRow
            content={assessment.entity_id}
            name={"Entity ID"}
            keyName={"entity_id"}
            assessmentId={assessment.id}
          />
        )}
        {assessment.deleted_at !== null ? (
          <TableRow className="bg-red-600 text-white hover:bg-white hover:text-black hover:cursor-pointer">
            <TableData><a onClick={handleRestore}></a></TableData>
            <TableData>
              <a onClick={handleRestore}>Restore me!</a>
            </TableData>
          </TableRow>
        ) : null}
        </tbody>
      </Table>
    </div>
  );
}