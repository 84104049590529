import Login from "containers/Login/Login";
import useAuth from "providers/AuthStoreProvider/useAuth";
import Router from "routes/Router";

const App = () => {
  /**
   * * Get Authentication
   */
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated()) {
    // * Return login form if not yet authenticated
    return <Login />;
  }

  // * Else, return App
  return <Router />;
};

export default App;
