import { Identifiable } from "interfaces/ResourceInterface";
import { useState } from "react";
import { EditableSelectCheckRow } from "../EditableSelectCheckRow/EditableSelectCheckRow";

interface IProps extends Identifiable {
  readonly chargeComplete: boolean;
}

const ChargeCompletedOptions = [
  {
    key: "true",
    value: "true",
    displayValue: "true",
  },
  {
    key: "false",
    value: "false",
    displayValue: "false",
  },
];

export const EditableCheckChargeCompleteRow = ({ id, chargeComplete }: IProps) => {
  const [currentChargeCompleted, setCurrentChargeCompleted] = useState<boolean>(chargeComplete);

  const handleOnUpdate = (value: string) => {
    setCurrentChargeCompleted(value === "true");
  };

  return (
    <EditableSelectCheckRow
      value={currentChargeCompleted ? "true" : "false"}
      keyName="charge_complete"
      name="Charge complete"
      options={ChargeCompletedOptions}
      checkId={id}
      handleOnUpdate={handleOnUpdate}
    />
  );
};
