import { MinusCircleIcon, PencilIcon } from "@heroicons/react/outline";
import Table from "components/UI/Table/Table";
import TableHeading from "components/UI/Table/TableHeading/TableHeading";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { AMLAnswerOptionTypeInterface } from "../../interfaces/AMLAnswerOptionTypeInterface";
import AnswerOptionTypesTableDetails from "../AnswerOptionTypesTableDetails/AnswerOptionTypesTableDetails";

type AnswerOptionTypesTableProps = {
  answerOptionType: AMLAnswerOptionTypeInterface;
  handleEdit: (answerOptionTypeId: string) => void;
  handleDelete: (answerOptionTypeId: string) => void;
};

const AnswerOptionTypesTable = (props: AnswerOptionTypesTableProps) => {
  const handleEdit = () => props.handleEdit(props.answerOptionType.id);

  const handleDelete = () => props.handleDelete(props.answerOptionType.id);

  return (
    <div className="mt-4  first-of-type:mt-0" key={props.answerOptionType.id}>
      <Table>
        <thead>
          <TableRow>
            <TableHeading>{props.answerOptionType.name}</TableHeading>
            <TableHeading>
              <div className="flex justify-end">
                <span
                  className="cursor-pointer mr-4 hover:opacity-80"
                  onClick={() => handleEdit()}
                  title="Edit Answer Option type"
                >
                  <PencilIcon className="h-5 w-5 inline-block" />
                </span>
                <span
                  className="cursor-pointer hover:opacity-80"
                  onClick={() => handleDelete()}
                  title="Delete Answer Option type"
                >
                  <MinusCircleIcon className="h-5 w-5 inline-block" />
                </span>
              </div>
            </TableHeading>
          </TableRow>
        </thead>
        <AnswerOptionTypesTableDetails
          answerOptionType={props.answerOptionType}
        />
      </Table>
    </div>
  );
};

export default AnswerOptionTypesTable;
