import Button from "components/UI/Button/Button/Button";
import Loader from "components/UI/Loader/Loader";
import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import Table from "components/UI/Table/Table";
import TableData from "components/UI/Table/TableData/TableData";
import TableRow from "components/UI/Table/TableRow/TableRow";
import { Identifiable } from "interfaces/ResourceInterface";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { useState } from "react";
import { twMerge } from "tailwind-merge";
import { AmlCheckStatusEnum } from "../../interfaces/AmlCheckStatusEnum";
import { AmlCheckTypeEnum } from "../../interfaces/AmlCheckTypeEnum";
import { ICheck } from "../../interfaces/ICheck";
import { IVisualAuthenticity } from "../../interfaces/IVisualAuthenticity";
import { useRequestVendorDetail } from "../../services/useRequestVendorDetail/useRequestVendorDetail";
import { useRequestVisualAuthenticity } from "../../services/useRequestVisualAuthenticity/useRequestVisualAuthenticity";
import { EditableCheckChargeCompleteRow } from "../EditableCheckChargeCompleteRow/EditableCheckChargeCompleteRow";
import { EditableCheckStatusRow } from "../EditableCheckStatusRow/EditableCheckStatusRow";
import { EditableCheckVendorRow } from "../EditableCheckVendorRow/EditableCheckVendorRow";
import { EditableTextCheckRow } from "../EditableTextCheckRow/EditableTextCheckRow";
import { VendorDetailsViewer } from "../VendorDetailsViewer/VendorDetailsViewer";
import { VisualAuthenticity } from "../VisualAuthenticity/VisualAuthenticity";

interface IProps {
  readonly check: ICheck;
}

export const Check = ({ check }: IProps) => {
  const { Theme } = useTheme();

  const [vendorDetails, setVendorDetails] = useState<Identifiable | null>(null);

  const [visualAuthenticity, setVisualAuthenticity] = useState<IVisualAuthenticity | null>(null);

  const { getVendorDetails, IsLoading: isLoadingVendor, RequestError: requestErrorVendor } = useRequestVendorDetail();

  const {
    getVisualAuthenticity,
    IsLoading: isLoadingVA,
    RequestError: requestErrorVA,
  } = useRequestVisualAuthenticity();

  const handleLoadVendorDetails = () => getVendorDetails(check.id, (details) => setVendorDetails(details));

  const handleLoadVisualAuthenticity = () =>
    getVisualAuthenticity(check.id, (details) => setVisualAuthenticity(details));

  const isLoading = isLoadingVendor || isLoadingVA;
  const requestError = requestErrorVendor || requestErrorVA;

  const canRequestVA =
    [AmlCheckTypeEnum.BIOMETRIC, AmlCheckTypeEnum.DOCUMENT].includes(check.check_type) &&
    check.vendor_reference &&
    [
      AmlCheckStatusEnum.DECISION_REQUIRED,
      AmlCheckStatusEnum.COMPLETE,
      AmlCheckStatusEnum.DECLINED,
      AmlCheckStatusEnum.REVIEW,
    ].includes(check.status as AmlCheckStatusEnum);

  return (
    <div className={twMerge(Theme["bg-secondary"], "mt-4 first-of-type:mt-0")}>
      <Table>
        <tbody>
          <TableRow>
            <TableData>Ref:</TableData>
            <TableData>{check.ref}</TableData>
          </TableRow>
          <EditableCheckChargeCompleteRow id={check.id} chargeComplete={check.charge_complete} />
          <EditableCheckVendorRow id={check.id} vendor={check.vendor} />
          {Object.hasOwn(check, "vendor_reference") && check.vendor_reference !== null ? (
            <TableRow>
              <TableData>Vendor Reference:</TableData>
              <TableData>{check.vendor_reference}</TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "check_type") && check.check_type !== null ? (
            <TableRow>
              <TableData>Check Type:</TableData>
              <TableData>{check.check_type}</TableData>
            </TableRow>
          ) : null}
          <EditableCheckStatusRow status={check.status} id={check.id} />
          {Object.hasOwn(check, "completed_at") && check.completed_at !== null ? (
            <TableRow>
              <TableData>Completed At:</TableData>
              <TableData>{check.completed_at}</TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "document_type") && check.document_type !== null ? (
            <TableRow>
              <TableData>Document Type:</TableData>
              <TableData>{check.document_type}</TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "document_expiry") && check.document_expiry !== null ? (
            <TableRow>
              <TableData>Document Expiry:</TableData>
              <TableData>{check.document_expiry}</TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "outcome") && check.outcome !== null ? (
            <TableRow>
              <TableData>Outcome:</TableData>
              <TableData>{check.outcome}</TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "comment") && check.comment !== null ? (
            <TableRow>
              <TableData>Comment:</TableData>
              <TableData>
                <div>
                  <p>{check.comment.body}</p>
                  <p>Commentable type: {check.comment.commentable_type}</p>
                  <p>Created at: {check.comment.created_at}</p>
                  <p>Updated at: {check.comment.updated_at}</p>
                  <p>User name: {check.comment.user_full_name}</p>
                </div>
              </TableData>
            </TableRow>
          ) : null}
          {Object.hasOwn(check, "entity_name") && check.entity_name !== null ? (
            <TableRow>
              <TableData>Entity Name:</TableData>
              <TableData>{check.entity_name}</TableData>
            </TableRow>
          ) : null}
          <EditableTextCheckRow
            content={check.entity_id ?? ""}
            keyName="entity_id"
            name="Entity Id"
            checkId={check.id}
          />
          <EditableTextCheckRow
            content={check.parent_entity_id ?? ""}
            keyName="parent_entity_id"
            name="Parent Entity Id"
            checkId={check.id}
          />
          <EditableTextCheckRow
            content={check.assessment_group_id ?? ""}
            keyName="assessment_group_id"
            name="Assessment Group Id"
            checkId={check.id}
          />
          {Object.hasOwn(check, "tenant_name") && check.tenant_name !== null ? (
            <TableRow>
              <TableData>Tenant Name:</TableData>
              <TableData>{check.tenant_name}</TableData>
            </TableRow>
          ) : null}
        </tbody>
      </Table>
      <div>
        <div className="flex justify-end p-2">
          <Button onClick={handleLoadVendorDetails} disabled={isLoading}>
            Get Vendor Details
          </Button>
          {canRequestVA && (
            <Button onClick={handleLoadVisualAuthenticity} disabled={isLoading} className="ml-2">
              Get Visual Authenticity Report
            </Button>
          )}
        </div>
        {isLoading && (
          <div className="flex justify-center p-2">
            <span className="inline-block w-6 h-6">
              <Loader />
            </span>
          </div>
        )}
        {requestError && (
          <div className="p-2">
            <NegativeMessage content={requestError.message} />
          </div>
        )}
        {visualAuthenticity && <VisualAuthenticity visualAuthenticity={visualAuthenticity} />}
        {vendorDetails && (
          <div>
            <VendorDetailsViewer details={vendorDetails} />
          </div>
        )}
      </div>
    </div>
  );
};
