import Button, { ButtonInterface } from "../Button/Button";

/**
 * * Primary styled button
 * @param {ButtonInterface}
 * @returns
 */
const PrimaryButton = (props: ButtonInterface) => <Button {...props} />;

export default PrimaryButton;
