import useAuth from "providers/AuthStoreProvider/useAuth";
import { useCallback, useState } from "react";
import { useRequestService } from "services/useRequestService/useRequestService";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { GET_WORDPRESS_LOGS_INDEX } from "../../endpoints";
import { AppsIndexResponseInterface } from "../../interfaces/AppsIndexResponseInterface";

export type GetAppsIndexParamsType = {
  page: number;
  per_page: number;
  q?: string;
};

type GetCronLogsIndex = {
  params: GetAppsIndexParamsType;
};

interface useRequestAppsIndexInterface<T> extends useRequestServiceImplementation<T> {
  Apps: T | null;
  getAppsIndex: (config: GetCronLogsIndex) => void;
}

export const useRequestAppsIndex = <T extends AppsIndexResponseInterface>(): useRequestAppsIndexInterface<T> => {
  const [Data, setData] = useState<T | null>(null);

  const { getAccessToken } = useAuth();

  const { IsLoading, RequestError, sendGetRequest } = useRequestService<T>();

  const getAppsIndex = useCallback(
    (config: GetCronLogsIndex) =>
      sendGetRequest(generateCompleteURL(GET_WORDPRESS_LOGS_INDEX, {}, { currentpage: config.params.page, per_page: config.params.per_page, q: config.params.q ?? "" }), (data) => setData(data), {
        bearer: getAccessToken() as string,
      }),
    [getAccessToken, sendGetRequest],
  );

  return {
    Apps: Data,
    IsLoading,
    RequestError,
    getAppsIndex,
  };
};
