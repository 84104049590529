import { AMLAnswerOptionFieldInterface } from "../../../interfaces/AMLAnswerOptionFieldInterface";
import { LocalAMLAnswerOptionFieldInterface } from "../AnswerOptionFieldsForm/AnswerOptionFieldsForm";
import AnswerOptionFieldsListItem from "../AnswerOptionFieldsListItem/AnswerOptionFieldsListItem";

type AnswerOptionFieldsListProps = {
  answerOptionFields: Array<
    AMLAnswerOptionFieldInterface | LocalAMLAnswerOptionFieldInterface
  >;
  handleCreate: (id: string, answer_option_id: string) => void;
  handleDelete: (id: string, local_id?: string) => void;
  handleEdit: (values: {
    id: string;
    local_id?: string;
    name: string;
    description: string;
    display_order: number;
  }) => void;
};

const AnswerOptionFieldsList = (props: AnswerOptionFieldsListProps) => (
  <>
    {props.answerOptionFields.map((answerOptionField) => (
      <AnswerOptionFieldsListItem
        key={answerOptionField.id}
        answerOptionField={answerOptionField}
        handleCreate={props.handleCreate}
        handleDelete={props.handleDelete}
        handleEdit={props.handleEdit}
      />
    ))}
  </>
);

export default AnswerOptionFieldsList;
