import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { DELETE_SECTION, GET_SECTIONS, PATCH_SECTION, POST_SECTION } from "../../endpoints";
import { AMLSectionInterface } from "../../interfaces/AMLSectionInterface";

type SubmitSectionType = {
  name: string;
  description: string;
  template_id: string;
  is_default: number;
  display_order: number;
};

interface useRequestAMLSectionInterface<T> extends useRequestServiceImplementation<T> {
  Sections: Array<T>;
  getSectionsIndex: (template_id: string) => void;
  postSection: (config: SubmitSectionType) => void;
  patchSection: (id: string, config: { name: string; description: string; display_order?: number }) => void;
  deleteSection: (id: string) => void;
}

export const useRequestAMLSections = <T extends AMLSectionInterface>(): useRequestAMLSectionInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get sections
   */
  const handleOnGetSectionsResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all sections
   */
  const getSectionsIndex = useCallback(
    (template_id: string) =>
      sendGetRequest(
        generateCompleteURL(
          GET_SECTIONS,
          {},
          {
            template_id,
          },
        ),
        (response) => handleOnGetSectionsResponse(response),
        {},
      ),
    [handleOnGetSectionsResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a section
   */
  const handleOnPostSection = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Post a new section
   */
  const postSection = useCallback(
    (config: SubmitSectionType) =>
      sendPostRequest(
        generateCompleteURL(POST_SECTION, {}, {}),
        { ...config },
        (response) => handleOnPostSection(response),
        {},
      ),
    [handleOnPostSection, sendPostRequest],
  );

  /**
   * * Handle on patching a section
   */
  const handleOnPatchSection = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a section
   */
  const patchSection = useCallback(
    (id: string, config: { name: string; description: string }) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_SECTION, { id }, {}),
        { ...config },
        (response) => handleOnPatchSection(response),
        {},
      ),

    [handleOnPatchSection, sendPatchRequest],
  );

  /**
   * * Handle on deleting a section
   */
  const handleOnDeleteSection = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a section
   */
  const deleteSection = useCallback(
    (id: string) =>
      sendDeleteRequest(generateCompleteURL(DELETE_SECTION, { id }, {}), () => handleOnDeleteSection(id), {}),
    [handleOnDeleteSection, sendDeleteRequest],
  );

  return {
    Sections: Resource,
    IsLoading,
    RequestError,
    getSectionsIndex,
    postSection,
    patchSection,
    deleteSection,
  };
};
