import { LoginIcon } from "@heroicons/react/outline";
import Logo from "assets/logo.png";
import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import NegativeMessage from "components/UI/Message/NegativeMessage/NegativeMessage";
import { CoreValues } from "data/CoreValues/CoreValues";
import { Formik } from "formik";
import useAuth from "providers/AuthStoreProvider/useAuth";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { LoginFormSchema } from "./LoginFormSchema";

const SelectedCoreValue = CoreValues[Math.floor(Math.random() * CoreValues.length)];

const LoginForm = () => {
  /**
   * * Get Authentication
   */
  const { requestLogin, Error: AuthError } = useAuth();

  const { Theme } = useTheme();

  const handleLoginSubmit = (values: { username: string; password: string }) => {
    requestLogin({ ...values });
  };

  return (
    <div className={Theme["login:container"]}>
      <div className="mb-6">
        <div className={Theme["login:logo:container"]}>
          <img src={Logo} alt="Watcher" className={Theme["login:logo"]} />
        </div>
        <div>
          <p className={Theme["corevalue"]}>{SelectedCoreValue}</p>
        </div>
      </div>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={LoginFormSchema}
        onSubmit={handleLoginSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit} className={Theme["form"]}>
            {AuthError ? (
              <div className="my-2">
                <NegativeMessage title={"Login Error"} content={AuthError.message} />
              </div>
            ) : null}

            <div>
              <FieldSet
                name="username"
                label="Username"
                error={formik.errors.username}
                autoComplete="username"
                placeholder="Username"
              />
            </div>
            <div className="mt-2">
              <FieldSet
                name="password"
                type="password"
                label="Password"
                error={formik.errors.password}
                autoComplete="current-password"
                placeholder="Password"
              />
            </div>
            <div className={`mt-6 ${Theme["flex:centralise"]}`}>
              <PrimaryButton type="submit" icon={<LoginIcon className="text-inherit" />} iconAlign="right">
                Sign In
              </PrimaryButton>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
