export const determineNextAvailableDisplayOrderValue = <T extends { display_order: number | string }>(
  resource: Array<T>,
) => {
  let highestDisplayOrderValue = 0;

  resource.forEach((r) => {
    let v = r.display_order;

    if (typeof v === "string") {
      v = parseInt(v);
    }

    highestDisplayOrderValue = v > highestDisplayOrderValue ? v : highestDisplayOrderValue;
  });

  return highestDisplayOrderValue + 1;
};
