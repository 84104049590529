import useTheme from "providers/ThemeStoreProvider/useTheme";
import { twMerge } from "tailwind-merge";

type MessageProps = {
  title?: string;
  content: string;
  theme?: "negative" | "caution" | "positive";
  icon?: React.ReactNode;
};

const MessageTheme = {
  negative: {
    border: "border-negative-500",
    bg: "bg-negative-500",
    color: "text-negative-500",
  },
  caution: {
    border: "border-caution-500",
    bg: "bg-caution-500",
    color: "text-caution-500",
  },
  positive: {
    border: "border-positive-500",
    bg: "bg-positive-500",
    color: "text-positive-500",
  },
};

const Message = (props: MessageProps) => {
  const { Theme } = useTheme();

  return (
    <div
      role="alert"
      className={twMerge(
        Theme["message:container"],
        props.theme ? MessageTheme[props.theme].border : ""
      )}
    >
      <div
        className={twMerge(
          Theme["message:sidebar"],
          props.theme ? MessageTheme[props.theme].bg : ""
        )}
      >
        {props.icon ? props.icon : null}
      </div>
      <div className="py-200 px-300">
        {props.title && (
          <p
            className={twMerge(
              Theme["message:title"],
              props.theme ? MessageTheme[props.theme].color : ""
            )}
          >
            {props.title}
          </p>
        )}
        <p
          className={twMerge(
            Theme["message:text"],
            props.theme ? MessageTheme[props.theme].color : ""
          )}
        >
          {props.content}
        </p>
      </div>
    </div>
  );
};

export default Message;
