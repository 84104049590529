import PrimaryButton from "components/UI/Button/PrimaryButton/PrimaryButton";
import TertiaryButton from "components/UI/Button/TertiaryButton/TertiaryButton";
import FieldSet from "components/UI/FieldSet/FieldSet";
import Modal from "components/UI/Modal/Modal";
import { Formik } from "formik";
import { useMountEffect } from "hooks/useMountEffect/useMountEffect";
import { AMLAnswerOptionTypeInterface } from "modules/Projects/AML/AnswerOptionTypes/interfaces/AMLAnswerOptionTypeInterface";
import { useRequestAMLAnswerOptionTypes } from "modules/Projects/AML/AnswerOptionTypes/services/useRequestAMLAnswerOptionTypes/useRequestAMLAnswerOptionTypes";
import useAMLEndpoint from "modules/Projects/shared/Providers/AMLEndpointProvider/useAMLEndpointProvider";
import useTheme from "providers/ThemeStoreProvider/useTheme";
import { createContext, useCallback } from "react";
import { AMLQuestionInterface } from "../../interfaces/AMLQuestionInterface";
import AnswerOptionFieldGroupsForm from "../AnswerOptionFieldGroups/AnswerOptionFieldGroupsForm/AnswerOptionFieldGroupsForm";

type EditQuestionModalProps = {
  question: AMLQuestionInterface;
  onSubmit: (values: {
    readonly id: string;
    readonly value: string;
    readonly guidance: string;
    readonly note: string;
    readonly level: 1 | 2;
    readonly allow_multiple: boolean;
    readonly display_order: string | number;
    readonly minor_update: boolean;
  }) => void;
  onClose: () => void;
};

export const AnswerOptionTypesContext = createContext<{
  answerOptionTypes: Array<AMLAnswerOptionTypeInterface>;
}>({
  answerOptionTypes: [],
});

const EditQuestionModal = (props: EditQuestionModalProps) => {
  const { Theme } = useTheme();

  const { AnswerOptionTypes, getAnswerOptionTypesIndex } = useRequestAMLAnswerOptionTypes();

  const { getAMLEndpointTitle } = useAMLEndpoint();

  useMountEffect(() => getAnswerOptionTypesIndex());

  const onSubmit = useCallback(
    (values: {
      readonly value: string;
      readonly guidance: string;
      readonly note: string;
      readonly level: "1" | "2";
      readonly allow_multiple: boolean;
      readonly display_order: string | number;
      readonly minor_update: boolean;
    }) => {
      props.onSubmit({
        id: props.question.id,
        ...values,
        level: parseInt(values.level) as 1 | 2,
      });
    },
    [props],
  );

  return (
    <Modal onClose={props.onClose} wide>
      <div className="flex flex-col">
        <div className={`${Theme["bg-secondary"]} px-4 py-2`}>
          <h2 className="font-medium text-lg">Edit Question ({getAMLEndpointTitle()})</h2>
        </div>
        <div className={`p-4 overflow-auto  max-h-[80vh]`}>
          <Formik
            initialValues={{
              value: props.question.value ?? "",
              guidance: props.question.guidance ?? "",
              note: props.question.note ?? "",
              level: props.question.level.toString() as "1" | "2",
              allow_multiple: props.question.allow_multiple,
              display_order: props.question.display_order,
              // * user must manually select that a question is a minor update
              minor_update: false,
            }}
            onSubmit={onSubmit}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <div>
                    <FieldSet
                      error={formik.errors.value}
                      label="Question:"
                      name="value"
                      placeholder="Question..."
                      layoutHorizontal
                      showLabel
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.guidance}
                      label="Guidance:"
                      name="guidance"
                      placeholder="Question guidance..."
                      layoutHorizontal
                      showLabel
                      additionalInformation="Guidance is shown when the user selects an enhanced risk
                    option"
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.note}
                      label="Note:"
                      name="note"
                      placeholder="Question note..."
                      layoutHorizontal
                      showLabel
                      type="textarea"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.allow_multiple}
                      label={"Allow multiple?"}
                      name={"allow_multiple"}
                      layoutHorizontal
                      showLabel
                      type="checkbox"
                      additionalInformation="Selecting allow multiple will allow the user to enter multiple answer sets"
                      currentValue={formik.values.allow_multiple}
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.level}
                      label={"Level:"}
                      name="level"
                      layoutHorizontal
                      showLabel
                      type="select"
                      additionalInformation="A level 2 is a sub question and must be linked to another level 1 question in order to be displayed. Level 1 questions will always be displayed as part of the assessment flow"
                      options={[
                        {
                          value: "1",
                          content: "1",
                        },
                        {
                          value: "2",
                          content: "2",
                        },
                      ]}
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.display_order}
                      label={"Display order (override):"}
                      name="display_order"
                      layoutHorizontal
                      showLabel
                      type="number"
                      additionalInformation="Set the numerical position the question should appear in the assessment flow"
                      inputConfig={{
                        min: "0",
                        step: "1",
                      }}
                    />
                  </div>
                  <div className="mt-4">
                    <FieldSet
                      error={formik.errors.minor_update}
                      label={"This is a minor update:"}
                      name={"minor_update"}
                      layoutHorizontal
                      showLabel
                      type="checkbox"
                      additionalInformation="Any question updates will be regarded as a major change for the purposes of Renewals unless explicity flagged as being a minor update, in which case the renewer will not see that this question has been updated."
                    />
                  </div>
                </div>
                <div className="my-2 flex items-center justify-end">
                  <TertiaryButton type="button" onClick={() => props.onClose()} className="mr-2">
                    Cancel
                  </TertiaryButton>
                  <PrimaryButton type="submit">Update</PrimaryButton>
                </div>
              </form>
            )}
          </Formik>
          <hr className="mt-4 w-5/6 border-t border-neutral-500 mx-auto" />
          <div className="mt-2">
            <h2 className="font-medium text-2xl mb-2 text-center">Answer Config</h2>
            <p className="font-medium text-sm mb-2 text-center">
              Use the below options to configure answers to this Question.
            </p>
            <AnswerOptionTypesContext.Provider
              value={{
                answerOptionTypes: AnswerOptionTypes,
              }}
            >
              <AnswerOptionFieldGroupsForm question_id={props.question.id} />
            </AnswerOptionTypesContext.Provider>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditQuestionModal;
