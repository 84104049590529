import { useResource } from "hooks/useResource/useResource";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { DELETE_TEMPLATE, GET_TEMPLATE, GET_TEMPLATES, PATCH_TEMPLATE, POST_TEMPLATE } from "../../endpoints";
import { AMLTemplateInterface } from "../../interfaces/AMLTemplateInterface";

type SubmitTemplate = {
  name: string;
  description: string;
  entity_type_id: string;
  entity_subtype_id: string;
  assessment_type_id: string;
  is_default: 1;
};

interface useRequestAMLTemplateInterface<T> extends useRequestServiceImplementation<T> {
  Templates: Array<T>;
  getTemplatesIndex: () => void;
  getTemplate: (id: string) => void;
  postTemplate: (config: SubmitTemplate) => void;
  patchTemplate: (id: string, config: { name: string; description: string }) => void;
  deleteTemplate: (id: string) => void;
}

export const useRequestAMLTemplates = <T extends AMLTemplateInterface>(): useRequestAMLTemplateInterface<T> => {
  const { Resource, createResource, updateResource, deleteResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on get templates
   */
  const handleOnGetTemplatesResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      createResource(data);
    },
    [createResource],
  );

  /**
   * * get all templates
   */
  const getTemplatesIndex = useCallback(
    () =>
      sendGetRequest(
        generateCompleteURL(GET_TEMPLATES, {}, {}),
        (response) => handleOnGetTemplatesResponse(response),
        {},
      ),
    [handleOnGetTemplatesResponse, sendGetRequest],
  );

  /**
   * * Handle on get single template
   */
  const handleOnGetTemplateResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Get the data for a single template
   */
  const getTemplate = useCallback(
    (id: string) =>
      sendGetRequest(
        generateCompleteURL(GET_TEMPLATE, { id }, {}),
        (response) => handleOnGetTemplateResponse(response),
        {},
      ),
    [handleOnGetTemplateResponse, sendGetRequest],
  );

  /**
   * * Handle on posting a template
   */
  const handleOnPostTemplate = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Post a new template
   */
  const postTemplate = useCallback(
    (config: SubmitTemplate) =>
      sendPostRequest(
        generateCompleteURL(POST_TEMPLATE, {}, {}),
        { ...config },
        (response) => handleOnPostTemplate(response),
        {},
      ),
    [handleOnPostTemplate, sendPostRequest],
  );

  /**
   * * Handle on patching a template
   */
  const handleOnPatchTemplate = useCallback(
    (responseData: AMLResponseInterface<T>) => {
      const { data } = responseData;
      updateResource(data);
    },
    [updateResource],
  );

  /**
   * * Patch a template
   */
  const patchTemplate = useCallback(
    (id: string, config: { name: string; description: string }) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_TEMPLATE, { id }, {}),
        { ...config },
        (response) => handleOnPatchTemplate(response),
        {},
      ),

    [handleOnPatchTemplate, sendPatchRequest],
  );

  /**
   * * Handle on deleting a template
   */
  const handleOnDeleteTemplate = useCallback((id: string) => deleteResource(id), [deleteResource]);

  /**
   * * Delete a template
   */
  const deleteTemplate = useCallback(
    (id: string) =>
      sendDeleteRequest(generateCompleteURL(DELETE_TEMPLATE, { id }, {}), () => handleOnDeleteTemplate(id), {}),
    [handleOnDeleteTemplate, sendDeleteRequest],
  );

  return {
    Templates: Resource,
    IsLoading,
    RequestError,
    getTemplatesIndex,
    getTemplate,
    postTemplate,
    patchTemplate,
    deleteTemplate,
  };
};
