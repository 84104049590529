/* eslint-disable no-loops/no-loops */ // TODO: Refactor this
import { useResource } from "hooks/useResource/useResource";
import { Draft, produce } from "immer";
import { AMLResponseInterface } from "modules/Projects/AML/interfaces/AMLResponseInterface";
import { useRequestAMLService } from "modules/Projects/shared/services/useRequestAMLService";
import { useCallback } from "react";
import { useRequestServiceImplementation } from "services/useRequestService/useRequestServiceInterface";
import { generateCompleteURL } from "utilities/generateCompleteURL/generateCompleteURL";
import { DELETE_ENTITY_TYPE, GET_ENTITY_TYPES, PATCH_ENTITY_TYPE, POST_ENTITY_TYPE } from "../../endpoints";
import { AMLEntityTypeInterface } from "../../interfaces/AMLEntityTypeInterface";
import { findEntityType } from "../../utilities/findEntityType";

type SubmitEntityType = {
  name: string;
  description: string;
  entity_type_id: string;
};

interface useRequestAMLEntityTypesInterface<T> extends useRequestServiceImplementation<T> {
  EntityTypes: Array<T>;
  getEntityTypesIndex: () => void;
  postEntityType: (config: SubmitEntityType) => void;
  patchEntityType: (config: SubmitEntityType) => void;
  deleteEntityType: (id: string) => void;
}

/**
 * * Handle looping through entity type date to delete entity type by id
 */
const handleDeleteEntityType = (entityTypes: Array<AMLEntityTypeInterface>, id: string) => {
  for (let e = 0; e < entityTypes.length; e++) {
    const entityType = entityTypes[e];
    if (entityType.entity_sub_types) {
      entityType.entity_sub_types = handleDeleteEntityType(entityType.entity_sub_types, id);
    }
  }

  return entityTypes.filter((entityType) => entityType.id !== id);
};

export const useRequestAMLEntityTypes = <T extends AMLEntityTypeInterface>(): useRequestAMLEntityTypesInterface<T> => {
  const { Resource, createResource, updateResource } = useResource<T>();

  const { IsLoading, RequestError, sendGetRequest, sendPostRequest, sendPatchRequest, sendDeleteRequest } =
    useRequestAMLService<AMLResponseInterface<T>>();

  /**
   * * Handle on successful get response
   */
  const handleOnGetResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;

      // * store as an array
      return createResource(data);
    },
    [createResource],
  );

  const getEntityTypesIndex = useCallback(
    () => sendGetRequest(generateCompleteURL(GET_ENTITY_TYPES, {}, {}), handleOnGetResponse, {}),
    [handleOnGetResponse, sendGetRequest],
  );

  /**
   * * Handle on successful post request
   */

  const handleOnPostResponse = useCallback(
    (response: AMLResponseInterface<T>, parentEntityId: string) => {
      const { data } = response;

      // * Entity Types requires special consideration due to nested entity types
      updateResource(
        produce(Resource, (draft) => {
          const parentEntity = findEntityType(draft, parentEntityId);

          if (parentEntity && parentEntity.entity_sub_types) {
            if (Array.isArray(data)) {
              parentEntity.entity_sub_types.push(...data);
            } else {
              parentEntity.entity_sub_types.push(data);
            }
          } else {
            if (Array.isArray(data)) {
              draft.push(...(data as Array<Draft<T>>));
            } else {
              draft.push(data as Draft<T>);
            }
          }
        }),
      );
    },
    [Resource, updateResource],
  );

  /**
   * * handle requesting post
   */
  const postEntityType = useCallback(
    (config: SubmitEntityType) =>
      sendPostRequest(
        generateCompleteURL(POST_ENTITY_TYPE, {}, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
          key: config.name.toLowerCase().trim().replace(/ /, "-"),
          entity_type_id: config.entity_type_id,
        },
        (response) => handleOnPostResponse(response, config.entity_type_id),
        {},
      ),
    [handleOnPostResponse, sendPostRequest],
  );

  /**
   * * Handle on patch response
   */
  const handleOnPatchResponse = useCallback(
    (response: AMLResponseInterface<T>) => {
      const { data } = response;
      updateResource(
        produce(Resource, (draft) => {
          if (Array.isArray(data)) {
            for (let d = 0; d < data.length; d++) {
              const update = data[d];
              const entityToUpdate = findEntityType(draft, update.id);
              if (entityToUpdate) {
                data.forEach((_, idx) => (entityToUpdate[idx] = data[idx]));
              }
            }
          } else {
            const entityToUpdate = findEntityType(draft, data.id);
            if (entityToUpdate) {
              for (const k in data) {
                entityToUpdate[k] = data[k];
              }
            }
          }
        }),
      );
    },
    [Resource, updateResource],
  );

  /**
   * * handle requesting patch
   */
  const patchEntityType = useCallback(
    (config: SubmitEntityType) =>
      sendPatchRequest(
        generateCompleteURL(PATCH_ENTITY_TYPE, { id: config.entity_type_id }, {}),
        {
          name: config.name.trim(),
          description: config.description.trim(),
        },
        handleOnPatchResponse,
        {},
      ),
    [handleOnPatchResponse, sendPatchRequest],
  );

  /**
   * * Handle on delete response
   */
  const handleOnDeleteResponse = useCallback(
    (id: string) =>
      // * as delete could be top level / nested, use create to replace resource with result
      createResource(
        produce(Resource, (draft) => {
          handleDeleteEntityType(draft, id);
        }),
      ),
    [Resource, createResource],
  );

  /**
   * * handle requesting delete
   */
  const deleteEntityType = useCallback(
    (id: string) =>
      sendDeleteRequest(generateCompleteURL(DELETE_ENTITY_TYPE, { id: id }, {}), () => handleOnDeleteResponse(id), {}),
    [handleOnDeleteResponse, sendDeleteRequest],
  );

  return {
    EntityTypes: Resource,
    IsLoading,
    RequestError,
    getEntityTypesIndex,
    postEntityType,
    patchEntityType,
    deleteEntityType,
  };
};
