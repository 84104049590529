import { createContext } from "react";
import { SettingsEnvType } from "../../interfaces/SettingsEnvType";

export interface SettingsEnvContextInterface {
  readonly selectedSettingsEnv?: SettingsEnvType;
  readonly bearerToken?: string;
  readonly setToken: (token: string) => void;
  readonly clearSettingsEnv: () => void;
  readonly setSettingsEnv: (env: SettingsEnvType) => void;
}

const SettingsEnvContext = createContext<SettingsEnvContextInterface>({} as SettingsEnvContextInterface);

export default SettingsEnvContext;
