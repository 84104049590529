import { Route } from "react-router-dom";

import CronIndex from "./Cron/CronLogs/containers/CronIndex/CronIndex";
import CronShow from "./Cron/CronLogs/containers/CronShow/CronShow";
import { CRON_INDEX, CRON_SHOW } from "./Cron/routes";
import { LOGS } from "./routes";
import { LOGS_SERVICES_ALL, LOGS_SERVICE_INDEX, LOGS_SERVICE_SHOW } from "./Services/routes";
import ServiceLogShow from "./Services/ServiceLogs/containers/ServiceLogShow/ServiceLogShow";
import ServiceLogsIndex from "./Services/ServiceLogs/containers/ServiceLogsIndex/ServiceLogsIndex";
import ServicesLogsIndex from "./Services/ServiceLogs/containers/ServicesLogsIndex/ServicesLogsIndex";

export const LogsRouter = (
  <Route path={LOGS}>
    {/* * Services */}
    <Route path={LOGS_SERVICES_ALL} element={<ServicesLogsIndex />} />
    <Route path={LOGS_SERVICE_INDEX} element={<ServiceLogsIndex />} />
    <Route path={LOGS_SERVICE_SHOW} element={<ServiceLogShow />} />

    {/* * Cron */}
    <Route path={CRON_INDEX} element={<CronIndex />} />
    <Route path={CRON_SHOW} element={<CronShow />} />
  </Route>
);
